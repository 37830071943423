import {
  CalendarMonth,
  Close,
  Create,
  Label,
  Lightbulb,
  Message as MessageIcon,
  ArrowBack,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  DialogActions,
  Chip,
  Avatar as MuiAvatar,
  Tabs,
  Tab,
} from '@mui/material';
import { useContext, useRef, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import {
  PostErrorCode,
  PostLifecycleState,
  PostType,
  Tag,
  UserRole,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostAdvocacyMembersProvider } from '../../context/PostAdvocacyMembersContext';
import { PostContext } from '../../context/PostContext';
import { StatusCode } from '../../errors/constants';
import { dateString, formatDate, sliceText, timeString } from '../../utils';
import Avatar from '../Avatar/Avatar';
import ThumbnailCard from '../Cards/ThumbnailCard';
import DatePicker from '../DatePicker/DatePicker';
import MagnetsSelector from '../SelectorComponents/MagnetsSelector';
import { TextEditor } from '../TextEditor/TextEditor';
import BrandsSelector from './BrandsSelector';
import PostActions from './PostActions';
import PostMediaDisplay from './PostMediaDisplay';
import { PostStateSelector } from './PostStateSelector';
import PostTags from './PostTags';
import TagModal from './TagModal';
import { useTranslation } from 'react-i18next';
import { PostOptions } from './PostOptions';
import Chat from '../ChatPost/Chat';
import Comments from '../ChatPost/Comments';
import { LoadingButton } from '@mui/lab';
import { GET_POSTS_BY_SHELL } from '../../graphql/queries';
import { useMutation } from '@apollo/client';
import { CHAT_MESSAGES_READ } from '../../graphql/mutations';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { SnackbarContext } from '../../context/SnackbarContext';
import EventIcon from '@mui/icons-material/Event';
export interface Message {
  text: string;
  type: 'error' | 'success' | 'info' | 'warning';
  action?: {
    text: string;
    onClick: () => void;
  };
}

interface Props {
  onClose?: (force?: boolean) => void;
  openChat: boolean;
  setOpenChat: (open: boolean) => void;
  isLink: boolean;
  isLinkInbox?: boolean;
  isInsight?: boolean;
  suggestAI?: boolean;
  inputAI?: boolean;
  loadingFromInspiration?: boolean;
}

const PostEditor = ({
  onClose = (force?: boolean) => null,
  openChat,
  setOpenChat,
  isLink,
  isLinkInbox,
  isInsight,
  suggestAI = false,
  inputAI = false,
  loadingFromInspiration = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<Message | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSuccessMessage } = useContext(SnackbarContext);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const commentsRef = useRef<HTMLDivElement | null>(null);
  const [tentativeDateDialog, setTentativeDateDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [hideTentativeWarning, setHideTentativeWarning] = useState(
    localStorage.getItem('hideTentativeDateWarning'),
  );
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const { user, refetchCurrentUser } = useContext(AuthContext);

  const { isMobile } = useContext(DeviceContext);
  const [mobileTabIndex, setMobileTabIndex] = useState(0);
  const { postState } = useContext(PostContext);
  const {
    handleBrandSelected,
    handleRenewAccess,
    loadingButtonRenew,
    setLoadingButtonRenew,
  } = useContext(BrandContext);

  const { post, status, handlers, error, advocacyPostInput, hidePost } = postState ?? {};

  const [chatMessagesRead] = useMutation(CHAT_MESSAGES_READ);
  const handleOpenChat = () => {
    if (user && post?.chat) {
      handlers?.handleUnreadMessagesChange(0);

      chatMessagesRead({
        variables: {
          postId: post?._id,
          userId: user?._id,
        },
        refetchQueries: [GET_POSTS_BY_SHELL],
      });
    }
  };

  useEffect(() => {
    if (post?.chat?.length && !isMobile) {
      handleOpenChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Prevent dialog from opening if hideTentativeDateWarning is true
    if (localStorage.getItem('hideTentativeDateWarning') === 'true') {
      setHideTentativeWarning('true');
    }
  }, []);

  const [title, setTitle] = useState(post?.title || '');
  const schedule = post?.schedule ? new Date(post?.schedule) : null;

  let displayMessage: Message | null = message;

  const isLinkedinTokenError =
    error &&
    [
      StatusCode.LINKEDIN_TOKEN_NOT_FOUND,
      StatusCode.LINKEDIN_TOKEN_ABOUT_TO_EXPIRE,
      StatusCode.LINKEDIN_TOKEN_ERROR,
      StatusCode.LINKEDIN_POST_ERROR,
    ].includes(error.code);

  const handleRenewAccessOnClick = () => {
    setLoadingButtonRenew(true);
    handlers?.handleSaveAsDraft({
      callback: () => {
        handleRenewAccess(
          post!.brand!.account!.type!,
          window.location.pathname,
          post?._id,
          post?.brandId as string,
        );
      },
    });
  };

  if (!post?.brand && post?.type !== PostType.AdvocacyParent) {
    displayMessage = {
      text: 'This post has no brand assigned or it might have been deleted',
      type: 'error',
    };
  } else if (
    (isLinkedinTokenError || post.errorCode === PostErrorCode.LinkedinToken) &&
    post.lifecycleState !== PostLifecycleState.Posted
  ) {
    const validateCondition =
      post?.brand?.account?.exp &&
      new Date(post.brand?.account?.exp).getTime() > Date.now() &&
      post.errorCode === PostErrorCode.LinkedinToken &&
      !status?.errorPostModal;
    displayMessage = {
      text:
        error?.code === StatusCode.LINKEDIN_POST_ERROR
          ? t('Unable to post. Please reconnect your account and try again')
          : validateCondition
          ? t('LinkedIn was inactive when you tried to publish this post.')
          : isLinkedinTokenError
          ? t('Reconecta la brand por marca expirada')
          : t('Unable to post. Please reconnect your account and try again'),
      type: validateCondition ? 'info' : 'error',
      action:
        !validateCondition || error?.code === 0
          ? {
              text: t('Reconnect'),
              onClick: handleRenewAccessOnClick,
            }
          : undefined,
    };
  } else if (post?.type !== PostType.AdvocacyParent && !post?.brand?.account?.type) {
    if (post?.brandId === user?.brandId) {
      displayMessage = {
        text: t(
          "You don't have any LinkedIn accounts connected. You can add one in settings.",
        ),
        type: 'error',
        action: {
          text: t('Save and go to settings 👉'),
          onClick: () => {
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({ callback: () => navigate('/settings') });
            } else {
              navigate('/settings');
            }
          },
        },
      };
    } else {
      displayMessage = {
        text:
          `${post.brand?.name} ` +
          t(
            "doesn't have a LinkedIn account connected. You can connect one for them from Brands",
          ),
        type: 'error',
        action: {
          text: t('Save and go to brands 👉'),
          onClick: () => {
            handleBrandSelected(post.brand!);
            window.scrollTo(0, 0);
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({
                callback: () => {
                  navigate('/brands');
                },
              });
            } else {
              navigate('/brands');
            }
          },
        },
      };
    }
  } else if (error) {
    displayMessage = {
      text: error.message || t('An error occurred'),
      type: 'error',
    };
    // setOpenModalError(true);
  } else if (post?.lifecycleState === PostLifecycleState.Error) {
    displayMessage = {
      text: t('There was an error publishing your post. Please try again.'),
      type: 'error',
    };
  } else if (post?.content?.body?.replace(/<[^>]*>?/gm, '').length! > 3000) {
    displayMessage = {
      text: t('You have exceeded the maximum character limit'),
      type: 'error',
    };
  } else if (schedule && post?.lifecycleState === PostLifecycleState.Scheduled) {
    displayMessage = {
      type: 'success',
      text: t('Your post is scheduled for {{date}} at {{time}}', {
        date: dateString(schedule),
        time: timeString(schedule),
      }),
    };
  } else if (status?.isPosted) {
    displayMessage = {
      text: t(
        'You have already posted this content. (This post may take a hour to appear on LinkedIn)',
      ),
      type: 'info',
    };
  } else if (
    post.lifecycleState === PostLifecycleState.Posting &&
    post.content?.media?.[0]?.type?.includes('video')
  ) {
    displayMessage = {
      text: t(
        'Uploading media... This may take a while. You can close the editor while we finish posting it.',
      ),
      type: 'info',
    };
  } else if (new Date(post.brand?.account?.exp).getTime() < Date.now()) {
    displayMessage = {
      text: t('Your LinkedIn account has expired. Please reconnect your account.'),
      type: 'error',
      action: {
        text: t('Reconnect'),
        onClick: handleRenewAccessOnClick,
      },
    };
  }

  const gridRef = useRef<HTMLDivElement>(null);
  // let content = null;
  // if (!post?.content?.json && !post?.advocacy?.parentPost?.content?.json) {
  //   content = post?.content?.body || post?.advocacy?.parentPost?.content?.body;
  // }

  // if (post?.type === PostType.AdvocacyParent) {
  //   content = post?.content?.body;
  // }

  // if (
  //   !user?.role.includes(UserRole.BrandManager) &&
  //   !user?.role.includes(UserRole.LimitedBrandManager) &&
  //   !user?.role.includes(UserRole.PostChatUser)
  // ) {
  //   content = post?.content?.body || post?.advocacy?.parentPost?.content?.body;
  // }

  const handleOpenDatepicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSubmit = (date: Date) => {
    if (hideTentativeWarning !== 'true') {
      setSelectedDate(date); // Store the selected date
      setTentativeDateDialog(true);
    } else {
      // Directly submit the date if warning is hidden
      handlers?.handleDateChange(date);
      setAnchorEl(null); // Ensures DatePicker Popper closes
      setSuccessMessage(t('Tentative date saved correctly'));
    }
  };

  const handleConfirm = () => {
    if (doNotShowAgain) {
      localStorage.setItem('hideTentativeDateWarning', 'true'); // Save preference
      setHideTentativeWarning('true');
    }
    if (selectedDate) {
      setTentativeDateDialog(false);
      handlers?.handleDateChange(selectedDate);
    }
    setAnchorEl(null); // Ensures DatePicker Popper closes
    setSuccessMessage(t('Tentative date saved correctly'));
  };

  useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes of inactivity
    onIdle: () => {
      onClose();
    },
    debounce: 500,
    disabled: postState?.status.isNew,
  });

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape' || event.key === 'Tab') {
      handlers?.handleTitleChange(title);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    handlers?.handleTitleChange(title);
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
  };

  return (
    <PostAdvocacyMembersProvider data-name="PostAdvocacyMembersProvider">
      {tentativeDateDialog && !hideTentativeWarning && (
        <Dialog
          data-name="ChatDialog"
          open={tentativeDateDialog}
          onClose={() => {
            setTentativeDateDialog(false);
            setAnchorEl(null);
          }}
          fullWidth
          maxWidth="sm"
        >
          {/* Dialog Title with Warning Icon */}
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <NotificationsActiveIcon sx={{ color: '	#ffcc00' }} />
            <Typography fontWeight={600}>
              {t('This post will not be published yet')}
            </Typography>
          </DialogTitle>

          {/* Dialog Content */}
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('An estimated publication date will be added, but the post ')}
              <strong>
                {t('will not be scheduled or published automatically until you do it.')}
              </strong>
            </Typography>

            {/* Checkbox: Do not show again */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={doNotShowAgain}
                  onChange={(e) => setDoNotShowAgain(e.target.checked)}
                />
              }
              label={t('Understood! Do not show again')}
            />
          </DialogContent>

          {/* Dialog Actions (Buttons) */}
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setTentativeDateDialog(false)}
              sx={{
                color: '#30527C', // Dark blue text
                borderColor: '#30527C80', // Semi-transparent blue border
                paddingX: '16px', // Horizontal padding
                paddingY: '8px', // Vertical padding
                fontWeight: 600, // Make text bold
                textTransform: 'none', // Prevent uppercase transformation
                '&:hover': {
                  backgroundColor: '#30527C10', // Light blue background on hover
                  borderColor: '#30527C', // Darker border on hover
                },
              }}
            >
              {t('Cancel')}
            </Button>
            <Button variant="contained" onClick={handleConfirm}>
              {t('Accept')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openChatModal && (
        <Dialog
          data-name="ChatDialog"
          open={openChatModal}
          onClose={() => setOpenChatModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent data-name="ChatDialogContent">
            <Box
              data-name="ChatDialogBox"
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Stack
                data-name="ChatDialogStack"
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <IconButton
                  data-name="ChatDialogBackButton"
                  onClick={() => setOpenChatModal(false)}
                >
                  <ArrowBack />
                </IconButton>
              </Stack>
            </Box>
            <Box data-name="ChatDialogBoxContent" sx={{ mt: 2 }}>
              <Chat
                data-name="ChatComponent"
                messages={post?.chat || []}
                handleSendMessage={(message) =>
                  handlers?.handleSendChatMessage(message, [])
                }
                writing={false}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <Stack
        data-name="MainStack"
        direction={{ xs: 'column', md: 'row' }}
        gap={2}
        sx={{
          width: '100%',
          paddingBottom: { xs: '80px', md: 0 }, // Adds extra space on mobile
        }}
      >
        <DatePicker
          data-name="DatePicker"
          date={post?.schedule}
          open={anchorEl !== null}
          withTimestamp={false}
          maxDate={new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)}
          anchorElement={anchorEl!}
          onClose={() => setAnchorEl(null)}
          onSubmit={handleDateSubmit}
          primaryActionTitle={t('SAVE DATE')}
          transformOrigin="top end"
          isMobile={isMobile}
          tentative
          showWarning={true}
        />
        {isMobile && (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: 'white',
              borderBottom: '1px solid #ddd',
            }}
          >
            {/* First row: Close button aligned to the right */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
              <IconButton data-name="CloseButtonMobile" onClick={() => onClose()}>
                <Close />
              </IconButton>
            </Box>

            {/* Second row: Tabs */}
            <Tabs
              value={mobileTabIndex}
              onChange={(e, newValue) => setMobileTabIndex(newValue)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab icon={<Create />} />
              <Tab icon={<Visibility />} />
              <Tab
                icon={
                  <Box
                    component="img"
                    src="/images/icons/hooks.svg"
                    alt="Hooks"
                    sx={{ width: 18, height: 18 }}
                  />
                }
              />
              <Tab
                icon={
                  <Box
                    component="img"
                    src="/images/icons/endings.svg"
                    alt="Endings"
                    sx={{ width: 18, height: 18 }}
                  />
                }
              />
            </Tabs>
          </Box>
        )}

        {isMobile && mobileTabIndex === 1 && (
          <PostOptions brand={post?.brand} indexTab={0} />
        )}
        {isMobile && mobileTabIndex === 2 && (
          <PostOptions brand={post?.brand} indexTab={1} />
        )}
        {isMobile && mobileTabIndex === 3 && (
          <PostOptions brand={post?.brand} indexTab={2} />
        )}
        {mobileTabIndex === 0 && (
          <Grid
            data-name="MainGrid"
            ref={gridRef}
            container
            rowSpacing={2}
            columnSpacing={1}
            sx={{ width: '100%' }}
          >
            <Grid item xs={12} md={8} data-name="TitleGrid">
              {isMobile && (
                <Chip
                  label={`${t(
                    post?.lifecycleState === PostLifecycleState.Scheduled
                      ? 'Scheduled date'
                      : 'Tentative date',
                  )}: ${schedule ? formatDate(schedule, i18n.language) : ''}`}
                  avatar={
                    <MuiAvatar sx={{ backgroundColor: 'transparent' }}>
                      <EventIcon fontSize="small" />
                    </MuiAvatar>
                  }
                  sx={{
                    width: 'auto',
                    height: '32px',
                    borderRadius: '100px',
                    padding: '4px',
                    backgroundColor: '#CFD8DC', // Default gray background
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'black', // Text color
                    '& .MuiChip-avatar': {
                      width: '24px',
                      height: '24px',
                    },
                  }}
                />
              )}

              <Stack
                data-name="TitleStack"
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  data-name="TitleInnerStack"
                  flexDirection="row"
                  gap={{ xs: 1, md: 2 }}
                  marginBottom={1}
                  alignContent={'center'}
                  alignItems={'center'}
                >
                  <Stack data-name="TitleTextFieldStack">
                    {isEditing ? (
                      <TextField
                        data-name="TitleTextField"
                        required
                        value={title || ''}
                        onChange={(value) => {
                          onChangeTitle(value.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        sx={{ maxWidth: '180px' }}
                        placeholder={t('Untitled post')}
                        autoFocus
                        variant="standard"
                      />
                    ) : (
                      <Stack
                        data-name="TitleTypographyStack"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                      >
                        <Typography
                          data-name="TitleTypography"
                          fontWeight={700}
                          marginLeft={1}
                          maxWidth="180px"
                        >
                          {sliceText(title, 22) ||
                            sliceText(post?.title as string, 22) ||
                            t('Untitled post')}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  {!isEditing && (
                    <IconButton
                      data-name="EditTitleButton"
                      onClick={toggleEditMode}
                      disabled={status?.isEditDisabled || isLink}
                    >
                      <Create />
                    </IconButton>
                  )}

                  {!isMobile &&
                    (user || post?.shareLink) &&
                    post?.type !== PostType.AdvocacyParent &&
                    post?.lifecycleState &&
                    post?.lifecycleStateDisplay &&
                    handlers && (
                      <PostStateSelector
                        data-name="PostStateSelector"
                        lifecycleStateDisplay={post.lifecycleStateDisplay}
                        lifecycleState={post.lifecycleState}
                      />
                    )}
                  {post?.schedule && !isMobile && (
                    <Chip
                      label={`${t(
                        post?.lifecycleState === PostLifecycleState.Scheduled
                          ? 'Scheduled date'
                          : 'Tentative date',
                      )}: ${schedule ? formatDate(schedule, i18n.language) : ''}`}
                      avatar={
                        <MuiAvatar sx={{ backgroundColor: 'transparent' }}>
                          <EventIcon fontSize="small" />
                        </MuiAvatar>
                      }
                      sx={{
                        width: 'auto',
                        height: '32px',
                        borderRadius: '100px',
                        padding: '4px',
                        backgroundColor: '#CFD8DC', // Default gray background
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'black', // Text color
                        '& .MuiChip-avatar': {
                          width: '24px',
                          height: '24px',
                        },
                      }}
                    />
                  )}

                  {post?.lifecycleState === PostLifecycleState.Draft &&
                    (user?.role.includes(UserRole.BrandManager) ||
                      user?.role.includes(UserRole.LimitedBrandManager)) && (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {status?.isNew ? (
                          hidePost ? ( // For new posts, use local hidePost state
                            <>
                              <VisibilityOff />
                              <Typography fontSize={16} fontWeight="bold">
                                {t('Hide')}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Visibility sx={{ color: '#E91E63' }} />
                              <Typography fontSize={16} color="#E91E63" fontWeight="bold">
                                {t('Visible')}
                              </Typography>
                            </>
                          )
                        ) : postState?.post?.hidePost ? ( // For existing posts, use postState?.post?.hidePost
                          <>
                            <VisibilityOff />
                            <Typography fontSize={16} fontWeight="bold">
                              {t('Hide')}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Visibility sx={{ color: '#E91E63' }} />
                            <Typography fontSize={16} color="#E91E63" fontWeight="bold">
                              {t('Visible')}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    )}
                </Stack>
              </Stack>
            </Grid>
            {isMobile && (
              <Grid item xs={12} data-name="MobileGrid">
                <Stack
                  data-name="MobileStack"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {((!user && post?.brandId) ||
                    user?.role.includes(UserRole.BrandManager) ||
                    user?.role.includes(UserRole.LimitedBrandManager)) &&
                    post?.type !== PostType.AdvocacyParent && (
                      <Stack
                        data-name="MobileAvatarStack"
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Avatar
                          data-name="MobileAvatar"
                          size={40}
                          name={post?.brand?.name || ''}
                          avatar={post?.brand?.account?.avatar || ''}
                          backgroundColor={post?.brand?.backgroundColor || ''}
                        />
                        <Typography data-name="MobileBrandName" fontWeight={'bold'}>
                          {post?.brand?.name || 'Unknown'}
                        </Typography>
                      </Stack>
                    )}
                  {(!user ||
                    user?.role.includes(UserRole.PostChatUser) ||
                    user?.role.includes(UserRole.BrandManager) ||
                    user?.role.includes(UserRole.LimitedBrandManager)) &&
                    post?.type !== PostType.AdvocacyParent &&
                    !status?.isNew && (
                      <Stack
                        data-name="MobileCommentsStack"
                        direction={'row'}
                        gap={1}
                        marginBottom={1}
                      >
                        <Badge
                          data-name="MobileCommentsBadge"
                          badgeContent={post?.unreadMessagesCount}
                          color="primary"
                          sx={{
                            width: '100%',
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          max={10}
                          invisible={!post?.unreadMessagesCount || openChat}
                        >
                          <Button
                            data-name="MobileCommentsButton"
                            variant="outlined"
                            fullWidth
                            startIcon={<MessageIcon />}
                            onClick={() => {
                              handlers?.handleUnreadMessagesChange(0);
                              setOpenChat(!openChat);
                            }}
                            sx={{
                              backgroundColor: openChat ? '#FFD8EB' : '#FFF',
                              justifyContent: 'left',
                            }}
                          >
                            {t('Comments')}
                          </Button>
                        </Badge>
                      </Stack>
                    )}
                </Stack>
                <Divider
                  data-name="MobileDivider"
                  sx={{
                    marginY: 1,
                  }}
                />
                <Stack
                  data-name="MobilePostStateStack"
                  flexDirection="row"
                  gap={1}
                  justifyContent="space-between"
                >
                  {(user || post?.shareLink) &&
                    post?.type !== PostType.AdvocacyParent &&
                    post?.lifecycleState &&
                    post?.lifecycleStateDisplay &&
                    handlers &&
                    !isMobile && (
                      <PostStateSelector
                        data-name="MobilePostStateSelector"
                        lifecycleStateDisplay={post.lifecycleStateDisplay}
                        lifecycleState={post.lifecycleState}
                      />
                    )}
                  {isMobile && post?.lifecycleState && post?.lifecycleStateDisplay && (
                    <PostStateSelector
                      data-name="MobilePostStateSelector"
                      lifecycleStateDisplay={post.lifecycleStateDisplay}
                      lifecycleState={post.lifecycleState}
                    />
                  )}
                  <Divider
                    data-name="MobileVerticalDivider"
                    orientation="vertical"
                    flexItem
                  />
                  <Stack
                    data-name="MobileIdeaStack"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <FormControlLabel
                      data-name="MobileIdeaCheckbox"
                      control={
                        <Checkbox
                          checked={!!post?.isIdea}
                          disabled={status?.isEditDisabled || isLink}
                          onChange={(e) => handlers?.handlePostIdea(e.target.checked)}
                        />
                      }
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: { xs: '.8rem', sm: '1rem' },
                          marginLeft: '5px',
                        },
                        '& .MuiCheckbox-root': {
                          padding: 0,
                          marginLeft: '10px',
                        },
                      }}
                      label={t('This is an idea')}
                    />{' '}
                    <Lightbulb
                      data-name="MobileIdeaIcon"
                      sx={{
                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                        color: !!post?.isIdea ? '#ffb906' : '#000',
                        marginLeft: '-10px',
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            )}
            {!isMobile && (
              <Grid item xs={12} sm={6} data-name="DesktopGrid">
                {((!user && post?.brandId) ||
                  user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)) &&
                  post?.type !== PostType.AdvocacyParent && (
                    <Stack
                      data-name="DesktopAvatarStack"
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Avatar
                        data-name="DesktopAvatar"
                        size={40}
                        name={post?.brand?.name || ''}
                        avatar={post?.brand?.account?.avatar || ''}
                        backgroundColor={post?.brand?.backgroundColor || ''}
                      />
                      <Typography data-name="DesktopBrandName" fontWeight={'bold'}>
                        {post?.brand?.name || 'Unknown'}
                      </Typography>
                    </Stack>
                  )}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={10}
              data-name="TextEditorGrid"
              sx={{ zIndex: 0, height: 400, overflow: 'auto' }}
            >
              <TextEditor
                data-name="TextEditor"
                isLink={isLink}
                key={'editor-with-post'}
                postId={post?._id}
                aiAssistant
                status={status}
                handlers={handlers}
                advocacyEditable={!!advocacyPostInput?.editable}
                toggleAdvocacyEditable={() => handlers?.handleToggleAdvocacyEditable()}
                isUndoable
                suggestAI={suggestAI}
                inputAI={inputAI}
                loadingFromInspiration={loadingFromInspiration}
              />
            </Grid>
            <Grid alignSelf={'start'} item xs={2} data-name="BrandsSelectorGrid">
              {post?.type === PostType.AdvocacyParent && handlers && (
                <Stack
                  data-name="BrandsSelectorStack"
                  direction="row"
                  width={'100%'}
                  marginBottom={2}
                >
                  <BrandsSelector
                    data-name="BrandsSelector"
                    onChange={handlers.handleRecipientsChange}
                    selectedIds={
                      advocacyPostInput?.recipients?.map((r) => r.brandId) || []
                    }
                    disabledIds={post?.childrenPosts
                      ?.filter((p) => p.lifecycleState === PostLifecycleState.Posted)
                      .map((p) => p.brandId!)}
                  />
                </Stack>
              )}
              {!isMobile && (
                <Stack data-name="IdeaStack" direction={'row'} gap={1} marginBottom={1}>
                  <FormControlLabel
                    data-name="IdeaCheckbox"
                    control={
                      <Checkbox
                        checked={!!post?.isIdea}
                        disabled={status?.isEditDisabled || isLink}
                        onChange={(e) => handlers?.handlePostIdea(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2">{t('This is an idea')}</Typography>
                    }
                  />{' '}
                  <Lightbulb
                    data-name="IdeaIcon"
                    sx={{
                      color: !!post?.isIdea ? '#ffb906' : '#000',
                      marginLeft: '-10px',
                    }}
                  />
                </Stack>
              )}
              {(!user ||
                user?.role.includes(UserRole.PostChatUser) ||
                user?.role.includes(UserRole.BrandManager) ||
                user?.role.includes(UserRole.LimitedBrandManager)) &&
                post?.type !== PostType.AdvocacyParent &&
                !status?.isNew &&
                !isMobile && (
                  <Stack
                    data-name="MobileCommentsStack"
                    direction={'row'}
                    gap={1}
                    marginBottom={1}
                  >
                    <Badge
                      data-name="MobileCommentsBadge"
                      badgeContent={post?.unreadMessagesCount}
                      color="primary"
                      sx={{
                        width: '100%',
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      max={10}
                      invisible={!post?.unreadMessagesCount || openChat}
                    >
                      <Button
                        data-name="MobileCommentsButton"
                        variant="outlined"
                        fullWidth
                        startIcon={<MessageIcon />}
                        onClick={() => {
                          commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
                        }}
                        sx={{
                          backgroundColor: openChat ? '#FFD8EB' : '#FFF',
                          justifyContent: 'left',
                        }}
                      >
                        {t('Comments')}
                      </Button>
                    </Badge>
                  </Stack>
                )}
              {!isMobile &&
                (user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)) && (
                  <Button
                    data-name="LabelsButton"
                    variant="outlined"
                    fullWidth
                    startIcon={<Label />}
                    onClick={() => {
                      setOpenTagModal(true);
                    }}
                    disabled={
                      (status?.isEditDisabled && !status.isPosted) ||
                      isLink ||
                      isLinkInbox
                    }
                    sx={{
                      backgroundColor: !!post?.tags?.filter((tag) => !tag?.internal)
                        .length
                        ? '#30527C33'
                        : '#FFF',
                      marginBottom: 1,
                      justifyContent: 'flex-start', // Align text to the left
                    }}
                  >
                    {t('Labels')}
                  </Button>
                )}

              {!isMobile && !status?.isAdvocacyParent && (
                <Tooltip
                  title={t(
                    'This date does not schedule the post automatically, but it helps you plan when to publish it.',
                  )}
                  placement="bottom"
                >
                  <Button
                    data-name="DateButton"
                    color="primary"
                    fullWidth
                    startIcon={<CalendarMonth />}
                    endIcon={
                      schedule ? (
                        <Close
                          sx={{
                            width: '15px',
                            height: '15px',
                            '&:hover': { color: '#292929' },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlers?.handleDateChange(null);
                          }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    disabled={
                      [PostLifecycleState.Scheduled, PostLifecycleState.Posted].includes(
                        post?.lifecycleState!,
                      ) || isLinkInbox
                    }
                    variant="outlined"
                    onClick={handleOpenDatepicker}
                    sx={{
                      whiteSpace: 'nowrap',
                      justifyContent: 'flex-start',
                      fontSize: '11px',
                    }}
                  >
                    {schedule ? formatDate(schedule, i18n.language) : t('Tentative Date')}
                  </Button>
                </Tooltip>
              )}
            </Grid>

            {status?.errorThumbnail &&
              status?.showErrorThumbnail &&
              !post?.content?.thumbnail && (
                <Grid item xs={12} data-name="ErrorThumbnailGrid">
                  <Alert
                    data-name="ErrorThumbnailAlert"
                    severity="info"
                    onClose={() => handlers?.handleShowErrorThumbnail(false)}
                  >
                    Cannot display link preview. You can post as is, or try another link.
                  </Alert>
                </Grid>
              )}

            {!status?.errorThumbnail &&
            status?.loadingThumbnail &&
            !post?.content?.media?.length ? (
              <Grid item xs={12} data-name="LoadingThumbnailGrid">
                <Skeleton
                  data-name="LoadingThumbnailSkeleton"
                  variant="rectangular"
                  height={60}
                  sx={{ width: '100%', borderRadius: 1 }}
                />
              </Grid>
            ) : post?.content?.thumbnail?.title &&
              !post?.content?.media?.length &&
              postState?.status.showThumbnail ? (
              <Grid item xs={12} md={10} data-name="ThumbnailCardGrid">
                <ThumbnailCard
                  data-name="ThumbnailCard"
                  thumbnail={post.content.thumbnail}
                  hideThumbnail={postState?.handlers.handleHideThumbnail}
                  status={status}
                />
                {!isMobile &&
                  (!user ||
                    user?.role.includes(UserRole.PostChatUser) ||
                    user?.role.includes(UserRole.BrandManager) ||
                    user?.role.includes(UserRole.LimitedBrandManager)) &&
                  post?.type !== PostType.AdvocacyParent &&
                  !status?.isNew && (
                    <Grid item xs={12} data-name="CommentsSection">
                      <div ref={commentsRef}>
                        <Comments
                          messages={post?.chat || []}
                          writing={false}
                          isLinkInbox={isLinkInbox}
                          isLink={isLink}
                          setFixedWidth={true}
                        />
                      </div>
                    </Grid>
                  )}
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                md={10}
                data-name="PostMediaDisplayGrid"
                marginTop={{
                  xs: -2,
                  md: 0,
                }}
                sx={{
                  zIndex: 0,
                  position: 'relative', // Constrain positioned children within this grid
                  overflow: 'hidden', // Prevent content overflow
                }}
              >
                <Grid item xs={12} style={{ paddingTop: 0 }} data-name="MessageGrid">
                  {!status?.errorPostModal && displayMessage && (
                    <Alert
                      data-name="MessageAlert"
                      sx={{
                        alignItems: 'center',
                        p: 1,
                        width: 'fit-content',
                        backgroundColor: 'inherit',
                        flexDirection: { xs: 'column', sm: 'row' },
                        '& .MuiAlert-action': {
                          margin: 0,
                          padding: 0,
                        },
                      }}
                      severity={displayMessage?.type}
                      {...(displayMessage.action
                        ? {
                            action: (
                              <LoadingButton
                                size="small"
                                loading={loadingButtonRenew}
                                onClick={displayMessage.action.onClick}
                                sx={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                  color: 'text.primary',
                                }}
                              >
                                {displayMessage.action.text}
                              </LoadingButton>
                            ),
                          }
                        : {})}
                    >
                      {displayMessage?.text}
                    </Alert>
                  )}
                </Grid>

                <PostMediaDisplay
                  data-name="PostMediaDisplay"
                  disabled={status?.isEditDisabled}
                  post={post}
                  onUploadedMedia={handlers?.handleUploadMedia}
                  saveDraft={handlers?.handleSaveAsDraft}
                  onDeletedMedia={handlers?.handleDeleteMedia}
                  setMessage={(message) => setMessage(message)}
                  isLinkInbox={isLinkInbox}
                  displayMessage={!!displayMessage}
                  loadingFromInspiration={loadingFromInspiration}
                />
                {!isMobile &&
                  (!user ||
                    user?.role.includes(UserRole.PostChatUser) ||
                    user?.role.includes(UserRole.BrandManager) ||
                    user?.role.includes(UserRole.LimitedBrandManager)) &&
                  post?.type !== PostType.AdvocacyParent &&
                  !status?.isNew && (
                    <Grid item xs={12} data-name="CommentsSection">
                      <div ref={commentsRef}>
                        <Comments
                          messages={post?.chat || []}
                          writing={false}
                          isLinkInbox={isLinkInbox}
                          isLink={isLink}
                          setFixedWidth={true}
                        />
                      </div>
                    </Grid>
                  )}
              </Grid>
            )}

            {(!!post?.tags?.length || isMobile) && (
              <PostTags
                data-name="PostTags"
                tags={post?.tags as Tag[]}
                openModal={setOpenTagModal}
                isMobile={isMobile}
                handleRemoveTag={!isLinkInbox ? handlers?.handleRemoveTag : undefined}
              />
            )}

            {isMobile && !status?.isAdvocacyParent && (
              <Stack
                data-name="MobileDateStack"
                flexDirection="column"
                width="100%"
                paddingX={1}
              >
                <Divider
                  data-name="MobileDateDivider"
                  sx={{
                    marginY: 1,
                  }}
                />

                <Typography data-name="MobileDateTypography" marginBottom={1}>
                  {t('Tentative Date')}
                </Typography>

                <Button
                  data-name="MobileDateButton"
                  color="primary"
                  fullWidth
                  startIcon={<CalendarMonth />}
                  endIcon={
                    schedule ? (
                      <Close
                        sx={{
                          width: '15px',
                          height: '15px',
                          '&:hover': { color: '#292929' },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlers?.handleDateChange(null);
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  disabled={[
                    PostLifecycleState.Scheduled,
                    PostLifecycleState.Posted,
                  ].includes(post?.lifecycleState!)}
                  variant="outlined"
                  onClick={handleOpenDatepicker}
                >
                  {schedule ? formatDate(schedule, i18n.language) : 'Tentative Date'}
                </Button>
              </Stack>
            )}

            {post?.type === PostType.AdvocacyParent && (
              <>
                <Grid item xs={12} data-name="AdvocacyParentGrid">
                  <Typography data-name="AdvocacyParentTypography" marginBottom={1}>
                    {t('Assign a Magnet Qty to track its impact')}
                  </Typography>
                  {handlers && (
                    <MagnetsSelector
                      data-name="MagnetsSelector"
                      magnets={advocacyPostInput?.magnets!}
                      onChange={handlers.handleMagnetsChange}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        )}

        {mobileTabIndex === 0 && !isMobile && (
          <Grid item xs={12} md={7} data-name="PostOptionsGrid">
            <Stack data-name="PostOptionsStack" spacing={2}>
              <Box
                data-name="CloseButtonBox"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <IconButton
                  data-name="CloseButton"
                  onClick={() => {
                    onClose();
                    refetchCurrentUser();
                  }}
                  sx={{
                    backgroundColor: '#fff', // Optional styling
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Box>

              <Box data-name="PostOptionsBox">
                <PostOptions data-name="PostOptions" brand={post?.brand} />
              </Box>
            </Stack>
          </Grid>
        )}
      </Stack>
      <Grid
        item
        xs={12}
        data-name="PostActionsGrid"
        justifyContent="flex-end"
        style={{
          paddingTop: 10,
          position: !isMobile ? 'sticky' : 'relative',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 1,
          borderTop: '1px solid #e0e0e0', // Added border top
        }}
        height={50}
      >
        <PostActions
          data-name="PostActions"
          setError={setMessage}
          onClose={onClose}
          openChat={openChat}
          isLink={isLink}
          isLinkInbox={isLinkInbox}
        />
      </Grid>
      <TagModal
        data-name="TagModal"
        openTagModal={openTagModal}
        setOpenTagModal={setOpenTagModal}
        handleAddTags={handlers!.handleAddTags}
        tags={post?.tags as Tag[]}
        shellId={post?.shellId}
      />
      <Dialog
        data-name="Dialog"
        open={!!status?.errorPostModal}
        onClose={() => handlers?.handleErrorPostModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          data-name="DialogContent"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography>{displayMessage?.text}</Typography>
          <LoadingButton
            variant="contained"
            loading={loadingButtonRenew}
            onClick={handleRenewAccessOnClick}
            sx={{ margin: 'auto', marginTop: 1 }}
          >
            {t('Reconnect')}
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </PostAdvocacyMembersProvider>
  );
};

export default PostEditor;
