import { useMutation } from '@apollo/client';
import { Button, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { User, Language } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UPDATE_USER, UPDATE_USER_AVATAR } from '../../graphql/mutations';
import Avatar from '../Avatar/Avatar';
import UploadAvatarButton from '../Avatar/UploadAvatarButton';
import SettingsSection from '../SettingsSection/SettingsSection';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  width: 'fit-content',
  paddingX: 5,
};

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

const SettingsProfile = () => {
  const { t } = useTranslation();
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const { user, setUser, refetchCurrentUser } = useContext(AuthContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    user?.language || Language.English,
  );

  const [updateUserNameLastNameAndSignature] = useMutation(UPDATE_USER, {
    onError: (err) => {
      setErrorMessage(t(`Name and last name couldn't be updated - ${err.message}`));
    },
  });

  // Store language change
  const handleLanguageChange = (language: Language) => {
    setSelectedLanguage(language); // Just update selected language state
  };

  // Upload avatar logic (unchanged)
  const [uploadAvatar] = useMutation(UPDATE_USER_AVATAR, {
    onCompleted: ({ updateUserAvatar }) => {
      if (updateUserAvatar.success) {
        setSuccessMessage(t('Your avatar has been updated'));

        setUser({
          ...user,
          ...(updateUserAvatar.user as User),
        });
        return;
      }
      setErrorMessage(t('Oops! There was an error updating your avatar'));
    },
    onError: (err) => {
      setErrorMessage(t(`Profile picture couldn't be updated - ${err.message}`));
    },
  });

  const handleUploadAvatar = (avatar: string) => {
    uploadAvatar({ variables: { avatar } });
  };

  const handleRemoveAvatar = () => {
    uploadAvatar({
      variables: {
        avatar: '',
      },
    });
  };

  type FormName = {
    firstName: string;
    lastName: string;
  };

  // Extracted reusable update function
  const handleUpdateUser = (data?: FormName) => {
    const input = {
      email: user?.email || '',
      firstName: data?.firstName || user?.firstName || '',
      lastName: data?.lastName || user?.lastName || '',
      role: user?.role || [],
      plan: user?.plan || [],
      shellId: user?.shellId || '',
      shellName: user?.shellName || '',
      language: selectedLanguage, // The selected language from the LanguageSwitcher
    };

    updateUserNameLastNameAndSignature({
      variables: {
        input,
        userId: user?._id || '',
      },
      onCompleted: ({ updateUser }) => {
        if (updateUser.success) {
          const successMessage =
            selectedLanguage === Language.English
              ? 'Your profile has been updated'
              : 'Tu perfil ha sido actualizado';
          setSuccessMessage(successMessage);
          refetchCurrentUser();
          setUser({
            ...user,
            ...(updateUser.user as User),
          });
        } else {
          setErrorMessage(t('Oops! There was an error updating your profile'));
        }
      },
      onError: (err) => {
        setErrorMessage(t(`Name and last name couldn't be updated - ${err.message}`));
      },
    });
  };

  return (
    <>
      <SettingsSection
        title={t('Language')}
        subtitle={t('Change your Language between English and Spanish')}
      >
        <LanguageSwitcher onChange={handleLanguageChange} />
        <Button variant="contained" onClick={() => handleUpdateUser()} sx={buttonStyle}>
          {t('Save')}
        </Button>
      </SettingsSection>

      <SettingsSection
        title={t('Avatar')}
        subtitle={t(
          'You can change your avatar here or remove the current avatar. This will be your profile picture on magnettu.',
        )}
      >
        <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
          <Avatar avatar={user?.avatar || undefined} size={100} />
          <Stack direction={'column'} spacing={1} sx={{ alignItems: 'start' }}>
            <UploadAvatarButton
              setAvatar={handleUploadAvatar}
              setErrorMessage={setErrorMessage}
              buttonProps={{ variant: 'contained', sx: { buttonStyle } }}
            />
            {user?.avatar && (
              <Button variant="outlined" onClick={handleRemoveAvatar} sx={buttonStyle}>
                {t('Remove avatar')}
              </Button>
            )}
          </Stack>
        </Stack>
      </SettingsSection>

      <SettingsSection title={t('User information')} subtitle="">
        <FormContainer
          defaultValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            language: selectedLanguage,
          }}
          onSuccess={(data) => {
            handleUpdateUser(data); // Reuse the same logic for form save
          }}
        >
          <Stack sx={{ mb: 3, ...inputStyle }} direction="column" spacing={2}>
            <TextFieldElement name="firstName" label={t('First name')} required />
            <TextFieldElement name="lastName" label={t('Last name')} required />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            sx={buttonStyle}
            data-cy="submit-button"
          >
            {t('Save')}
          </Button>
        </FormContainer>
      </SettingsSection>
    </>
  );
};

export default SettingsProfile;
