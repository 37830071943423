import { useMutation } from '@apollo/client';
import { Close, RemoveCircleOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Brand, Chain, CreateIdeaInput } from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { GENERATE_IDEAS } from '../../graphql/mutations';

interface Props {
  open: boolean;
  brands: Brand[];
  chains: Chain[];
  onClose: () => void;
  onCreateIdeas: (ideas: CreateIdeaInput[]) => void;
}

export const BatchCreateIdeas = ({
  open,
  brands,
  chains,
  onClose,
  onCreateIdeas,
}: Props) => {
  const { setErrorMessage } = useContext(SnackbarContext);
  const [step, setStep] = useState(1);
  const [selectedBrandId, setSelectedBrandId] = useState<string>();
  const [selectedChainId, setSelectedChainId] = useState<string>();
  const [topicContext, setTopicContext] = useState<string>();

  const selectedBrand = brands.find((brand) => brand._id === selectedBrandId);

  const { control, handleSubmit, register, reset } = useForm<{
    ideas: CreateIdeaInput[];
  }>({
    defaultValues: {
      ideas: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ideas',
  });

  const [generateIdeas, { loading }] = useMutation(GENERATE_IDEAS);

  const handleBrandSelect = (brandId: string) => {
    setSelectedBrandId(brandId);
  };

  const handleChainSelect = (chainId: string) => {
    setSelectedChainId(chainId);
  };

  const handleClose = () => {
    setSelectedBrandId('');
    setSelectedChainId('');
    setStep(1);
    reset({ ideas: [] });
    onClose();
  };

  const fetchIdeas = async () => {
    if (!selectedBrandId || !selectedChainId) return;

    generateIdeas({
      variables: {
        topicContext: topicContext,
        brandId: selectedBrandId,
        chainId: selectedChainId,
      },
      onCompleted: ({ generateIdeas }) => {
        if (generateIdeas?.success) {
          append(
            (generateIdeas.ideas || []).map((ideaContent) => ({
              content: ideaContent,
              brandId: selectedBrandId,
              brandName: selectedBrand?.name || '',
              chainId: '66deb930ca49ee9acc834bf5',
              chainName: '',
              shellId: selectedBrand?.shellId || '',
              priority: 0,
            })),
          );
          setStep(2);
          return;
        }

        setErrorMessage(generateIdeas?.message || 'There was an error generating ideas');
      },
    });
  };

  const handleCreateIdeas = handleSubmit((data) => {
    onCreateIdeas(data.ideas);
    setSelectedBrandId('');
    setSelectedChainId('');
    reset({ ideas: [] });
    setStep(1);
    onClose();
  });

  const brand = brands.find((brand) => brand._id === selectedBrandId);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={step === 1 ? 'sm' : 'md'}
      onClose={() => {}}
      sx={{ p: 2 }}
    >
      <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleClose}>
        <Close />
      </IconButton>
      <DialogTitle>
        {step === 1 ? 'Select a Brand' : `Generated Ideas for ${brand?.name}`}
      </DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <Stack gap={1} pt={1}>
            <Autocomplete
              options={brands}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => handleBrandSelect(value?._id!)}
              renderInput={(params) => (
                <TextField {...params} label="Brand" variant="outlined" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  {option.name}
                </li>
              )}
            />
            <Autocomplete
              options={chains}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => handleChainSelect(value?._id!)}
              renderInput={(params) => (
                <TextField {...params} label="Ideation Chain" variant="outlined" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  {option.name}
                </li>
              )}
            />
            <TextField
              onChange={(e) => setTopicContext(e.target.value)}
              label="Topic Context"
              variant="outlined"
              multiline
              minRows={3}
            />
            <LoadingButton variant="contained" loading={loading} onClick={fetchIdeas}>
              Generate ideas
            </LoadingButton>
          </Stack>
        ) : (
          <form onSubmit={handleCreateIdeas}>
            <Stack direction={'column'} gap={2} mt={1}>
              {fields.map((field, index) => (
                <Stack key={field.id} direction="row" spacing={2} alignItems="start">
                  <TextField
                    {...register(`ideas.${index}.content`)}
                    label="Content"
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    sx={{ flex: 4 }}
                  />
                  <Controller
                    name={`ideas.${index}.chainId`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={chains}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) => field.onChange(value?._id)}
                        value={chains.find((chain) => chain._id === field.value) || null}
                        style={{ flex: 2 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chain"
                            variant="outlined"
                            fullWidth
                            multiline
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name}
                          </li>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name={`ideas.${index}.priority`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={[0, 1, 2]} // Use numbers directly as options
                        getOptionLabel={(option) => ['Low', 'Medium', 'High'][option]} // Map number to label
                        onChange={(_, value) => field.onChange(value)} // Directly use the number value
                        value={field.value}
                        style={{ flex: 1 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Priority"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    )}
                  />
                  <IconButton onClick={() => remove(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            <DialogActions sx={{ p: 0, mt: 1 }}>
              <LoadingButton type="submit" color="primary" variant="contained">
                Create Ideas
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};
