import { useQuery } from '@apollo/client';
import DoneIcon from '@mui/icons-material/Done';
import Search from '@mui/icons-material/Search';
import {
  ClickAwayListener,
  InputAdornment,
  Stack,
  TextField,
  Divider,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Brand, Group } from '../../__generated__/graphql';
import { GET_BRANDS, GET_GROUPS_BY_USER } from '../../graphql/queries';
import Avatar from '../Avatar/Avatar';
import { useTranslation } from 'react-i18next';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    marginTop: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 320,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  padding: 20,
}));

interface Props {
  selectedIds: string[];
  disabledIds?: string[];
  onChange: (brands: Brand[]) => void;
}

const BrandsSelector = ({ selectedIds, disabledIds, onChange }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'brands-select' : undefined;

  const { data: brandsData } = useQuery(GET_BRANDS);
  const brands = brandsData?.getBrands || [];

  const { data: groupsData } = useQuery(GET_GROUPS_BY_USER);
  const groups = groupsData?.shellGroups || [];
  const brandsByGroup: Record<string, Group & { brands: Brand[] }> = groups.reduce(
    (acc, group) => ({
      ...acc,
      [group._id]: {
        ...group,
        brands: brands.filter((brand) => brand?.groupsIds?.includes(group._id)),
      },
    }),
    {},
  );

  const nonEmptyGroups = Object.values(brandsByGroup).filter(
    (group) => group.brands.length,
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const selectAll = {
    _id: 'select-all',
    name: t('Select allv2'),
    __typename: 'SelectAll',
  };

  const StyledListItem = styled('li')(({ theme }) => ({
    background: 'rgba(236, 236, 236, 1)',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }));

  return (
    <Stack display={'flex'} justifyContent={'flex-end'} width={'100%'}>
      <Stack display={'flex'}>
        <Button
          color={selectedIds.length ? 'primary' : 'inherit'}
          variant={'outlined'}
          sx={
            selectedIds.length
              ? { backgroundColor: '#F9E7F0' }
              : { borderColor: '#BBBBBB' }
          }
          onClick={handleClick}
        >
          {t('Brands')}
        </Button>
      </Stack>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Stack direction={'column'} gap={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {t('Brands')}
              </Typography>
              <Typography>{`${selectedIds.length} ${t('selected')}`}</Typography>
            </Stack>
            <Autocomplete
              open
              forcePopupIcon={false}
              multiple
              value={brands.filter((brand) => selectedIds.includes(brand._id))}
              groupBy={(option) => {
                // Return a unique identifier for the 'Select All' option to avoid grouping it
                if (option.__typename === 'SelectAll') {
                  return '';
                }
                if (option.__typename === 'Brand') {
                  return t('Brands'); // Ensure this key exists in your translation files
                }
                if (option.__typename === 'Group') {
                  return t('Groups'); // Ensure this key exists in your translation files
                }
                return t('Unknown'); // Fallback translation
              }}
              onChange={(_, newState) => {
                // newState only includes selected brands and may have a group but
                // it doesn't know the state of the groups, we need to manually create the logic to know
                // if the group was selected or unselected

                if (newState.some((option) => option.__typename === 'SelectAll')) {
                  // Check if all selectable brands are already selected
                  const allSelectableBrands = brands.filter(
                    (brand) => !disabledIds?.includes(brand._id),
                  );
                  const allSelected = allSelectableBrands.every((brand) =>
                    selectedIds.includes(brand._id),
                  );

                  if (allSelected) {
                    onChange(
                      (disabledIds || [])
                        .map((id) => brands.find((brand) => brand._id === id))
                        .filter((brand): brand is Brand => brand !== undefined),
                    );
                  } else {
                    // If not all selectable brands are selected, select all
                    onChange(allSelectableBrands);
                  }
                  return;
                }

                let groupDeselectedId: string | null = null;
                const brandsAndBrandsFromGroups = newState.flatMap((brandOrGroup) => {
                  if (brandOrGroup.__typename === 'Brand') {
                    return [brandOrGroup];
                  }

                  const isGroupCurrentlySelected = brandsByGroup[
                    brandOrGroup._id
                  ].brands.every((brand) => selectedIds.includes(brand._id));

                  if (isGroupCurrentlySelected) {
                    groupDeselectedId = brandOrGroup._id;
                    return [];
                  }

                  return brands.filter((brand) =>
                    brand.groupsIds?.includes(brandOrGroup._id),
                  );
                });

                const selectedBrands = brandsAndBrandsFromGroups.filter(
                  (brand) =>
                    !groupDeselectedId ||
                    !brandsByGroup[groupDeselectedId].brands.some(
                      (groupBrand) => groupBrand._id === brand._id,
                    ),
                );

                const uniqueSelectedBrands = [
                  ...(disabledIds || []).map((id) =>
                    brands.find((brand) => brand._id === id),
                  ),
                  ...selectedBrands,
                ].filter(
                  (value, index, self) =>
                    value !== undefined &&
                    self.findIndex((v) => v?._id === value._id) === index,
                );

                onChange(
                  uniqueSelectedBrands.filter(
                    (brand): brand is Brand => brand !== undefined,
                  ),
                );
              }}
              onClose={(_, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              getOptionDisabled={(option) => !!disabledIds?.includes(option?._id)}
              noOptionsText="No brands were found"
              disableClearable
              renderOption={(props, option) => {
                if (option.__typename === 'SelectAll') {
                  // Custom rendering for the Select All option
                  return (
                    <StyledListItem {...props}>
                      <Typography style={{ margin: '10px' }}>{option.name}</Typography>
                    </StyledListItem>
                  );
                }

                let selected = false;
                if (option.__typename === 'Brand') {
                  selected = selectedIds.includes(option._id);
                } else {
                  selected = brandsByGroup[option._id].brands.every((brand) =>
                    selectedIds.includes(brand._id),
                  );
                }

                const brandOption = option as Brand;

                return (
                  <li {...props} onClick={props.onClick}>
                    <Box
                      width={'98%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Avatar
                          name={option?.name || 'Unkown'}
                          avatar={
                            option?.__typename === 'Brand'
                              ? brandOption.account?.avatar || ''
                              : ''
                          }
                          backgroundColor={brandOption?.backgroundColor || '#BBB'}
                        />
                        <Typography>{option?.name}</Typography>
                      </Stack>

                      <Box
                        component={DoneIcon}
                        sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                        style={{
                          visibility: selected ? 'visible' : 'hidden',
                        }}
                      />
                    </Box>
                  </li>
                );
              }}
              options={[selectAll, ...nonEmptyGroups, ...brands]}
              getOptionLabel={(option) => option?.name || 'Unknown'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('Filter brands')}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search
                          sx={{
                            marginLeft: '5px',
                            marginBottom: '5px',
                            color: '#BBBBBB',
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Divider />
            <Stack direction={'row'} justifyContent={'flex-end'} gap={1}>
              <Button variant="outlined" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button variant="contained" onClick={handleApply}>
                {t('Confirm')}
              </Button>
            </Stack>
          </Stack>
        </ClickAwayListener>
      </StyledPopper>
    </Stack>
  );
};

export default BrandsSelector;
