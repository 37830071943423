import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  BrandUser,
  PostContentMedia,
  Shell,
  User,
  UserPlan,
  Tag,
} from './__generated__/graphql';
import { firebaseApp, storage } from './index';
import {
  EditorState,
  ContentState,
  convertToRaw,
  Modifier,
  SelectionState,
} from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import * as cheerio from 'cheerio';
import MentionDecorator from './components/TextEditor/MentionDecorator';
import { decode } from 'html-entities';

export const getFullName = (
  user: Pick<User | BrandUser, 'firstName' | 'lastName' | 'email'> | null,
) => {
  if (!user?.firstName && !user?.lastName) return user?.email || '';
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const getDrawerWidth = (
  isMobile: boolean,
  isSmallDevice: boolean,
  navbar?: boolean,
) => (isMobile ? '75%' : isSmallDevice ? 65 : navbar ? 260 : 100);

export const timeAgo = (date: Date, t: (key: string) => string) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 0) {
    return interval + ` ${t('year')}${interval > 1 ? 's' : ''}`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 0) {
    return interval + ` ${t('month')}${interval > 1 ? t('s') : ''}`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 0) {
    return interval + ` ${t('day')}${interval > 1 ? 's' : ''}`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 0) {
    return interval + ` ${t('hour')}${interval > 1 ? 's' : ''}`;
  }

  interval = Math.floor(seconds / 60);
  if (interval > 0) {
    return interval + ` ${t('minute')}${interval > 1 ? 's' : ''}`;
  }

  return Math.floor(seconds) + ` ${t('second')}${seconds > 1 ? 's' : ''}`;
};

export const timeAgoWithDate = (
  date: Date | string,
  t: (key: string) => string,
  lang: string,
) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date passed to timeAgoWithDate:', date);
    return { formattedTime: '', isOldDate: false, isJustNow: false };
  }

  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - parsedDate.getTime()) / 1000);
  const diffInDays = Math.floor(diffInSeconds / 86400);

  // If just now (less than 5 seconds), return "Just now" without "ago"
  if (diffInSeconds < 5) {
    return {
      formattedTime: t('Just now'), // Localized "Just now"
      isOldDate: false,
      isJustNow: true, // Flag to indicate it's "Just now"
    };
  }

  // If more than 2 days have passed, return formatted date based on language
  if (diffInDays >= 2) {
    return {
      formattedTime: parsedDate.toLocaleDateString(lang, {
        month: 'short',
        day: 'numeric',
      }),
      isOldDate: true,
      isJustNow: false,
    };
  }

  // Otherwise, use timeAgo format
  return {
    formattedTime: timeAgo(parsedDate, t),
    isOldDate: false,
    isJustNow: false,
  };
};

export const formatDate = (date: Date, language?: string): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  const currentDate = new Date();
  const year = date.getFullYear();

  if (year === currentDate.getFullYear()) {
    options.year = undefined;
  } else {
    options.year = 'numeric';
  }

  const localeMap: Record<string, string> = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    de: 'de-DE',
  };

  const locale = localeMap[language ?? ''] || 'en-US';

  return date.toLocaleDateString(locale, options);
};

export const uploadMedia = async (
  file: File,
  type: 'media' | 'avatar' | 'document' | 'companyLogo' | 'companyIcon',
  ownerId?: string,
  setProgress?: (progress: number) => void,
  isLinkInbox?: boolean,
): Promise<PostContentMedia> => {
  let path = '';
  switch (type) {
    case 'media':
      if (!ownerId && !isLinkInbox) {
        throw new Error('Owner id is required for media upload');
      }
      path = `media/${ownerId}/${Date.now()}_${file.name}`;
      break;
    case 'avatar':
      path = `avatars/${Date.now()}_${file.name}`;
      break;
    case 'document':
      path = `documents/${Date.now()}_${file.name}`;
      break;
    case 'companyLogo':
      path = `companyLogos/${Date.now()}_${file.name}`;
      break;
    case 'companyIcon':
      path = `companyIcons/${Date.now()}_${file.name}`;
      break;
  }

  const storageRef = ref(storage, path);
  return new Promise((resolve, reject) => {
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (setProgress) setProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve({
            storagePath: uploadTask.snapshot.ref.fullPath,
            url,
            type: file.type,
            alt: file.name.replace(/\.[^/.]+$/, '').replace(/[_-]/g, ' '),
          });
        });
      },
    );
  });
};

export const uploadThumbnailMedia = async (imageBlobBase64: string) => {
  const tokenRandom = Math.random().toString(36).substring(7);
  try {
    const byteCharacters = atob(imageBlobBase64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: 'image/png' });

    if (!fileBlob.size) {
      throw new Error('La imagen descargada está vacía o es inválida.');
    }

    const fileImage = new File([fileBlob], `thumbnail-${tokenRandom}.png`, {
      type: 'image/png',
    });

    // Opcional: Verificar que el archivo tiene el tipo MIME correcto
    if (fileImage.type !== 'image/png' && fileImage.type !== 'image/jpeg') {
      throw new Error('La imagen descargada no es un PNG o JPEG válido.');
    }

    const thumbnailMedia = await uploadMedia(fileImage, 'media', tokenRandom);

    return thumbnailMedia;
  } catch (error) {
    console.error('Error uploading thumbnail media:', error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};

export const fetchImageAsBlob = async (url: string) => {
  try {
    const response = await fetch(url);

    // Verifica que la respuesta sea exitosa
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    // Verifica que el tipo MIME de la respuesta sea una imagen
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.startsWith('image/')) {
      throw new Error('URL does not point to an image.');
    }

    return await response.blob();
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};

export const deleteMedia = async (storagePath: string) => {
  const storage = getStorage(firebaseApp);
  const storageRef = ref(storage, storagePath);
  return deleteObject(storageRef);
};

export const dateString = (date: Date) =>
  date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const timeString = (date: Date) =>
  date.toLocaleTimeString('en-US', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getNextMonday9amMadrid = () => {
  const today = new Date();
  const daysUntilMonday = (8 - today.getDay()) % 7 || 7;
  const nextMonday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysUntilMonday,
  );

  nextMonday.setHours(9, 0, 0, 0); // Set to 9am local time

  const localTime = new Date(nextMonday.getTime());
  const madridTime = new Date(
    localTime.toLocaleString('en-US', { timeZone: 'Europe/Madrid' }),
  );

  const hourDifference = Math.round(
    (localTime.getTime() - madridTime.getTime()) / (1000 * 60 * 60),
  );

  nextMonday.setHours(nextMonday.getHours() + hourDifference);
  return nextMonday;
};

export const onlyNumbersInput = (e: React.KeyboardEvent) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const nameById = (items: { id: string; name: string }[], id: string) => {
  const item = items.find((item) => item.id === id);
  return item?.name;
};

export const getShellNameById = (shell: Shell[], shellId: string) => {
  const shellName = shell.find((shell) => shell._id === shellId);
  return shellName?.name || '';
};

export const getUserNameById = (users: User[], userId: string) => {
  const user = users.find((user) => user._id === userId);
  return getFullName(user!);
};

export const toTitleCase = (input: string): string => {
  if (!input) return input;

  return input
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const calculatePostsCountDifference = (currentCount: number, previousCount: number) => {
  if (currentCount !== undefined && previousCount !== undefined) {
    const difference = currentCount - previousCount;
    const percentage = ((difference / previousCount) * 100).toFixed(2);

    return {
      countDifference: difference,
      percentageDifference: percentage,
    };
  }

  return {
    countDifference: 0,
    percentageDifference: 0,
  };
};

export const formatPostsCountDifference = (
  currentCount: number,
  previousCount: number,
) => {
  const { countDifference, percentageDifference } = calculatePostsCountDifference(
    currentCount,
    previousCount,
  );

  if (!isFinite(Number(percentageDifference))) {
    return 'N/A';
  }

  const countDifferenceFixed = Number.isInteger(countDifference)
    ? countDifference
    : countDifference.toFixed(2);

  return `${
    countDifference > 0 ? '+' : ''
  }${countDifferenceFixed} (${percentageDifference}%)`;
};

export enum TYPES_OPTIONS {
  ALL_MEMBERS = 'All Members',
  COMPANIES = 'Companies',
  GROUPS = 'Groups',
}

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
};

export const calculateScale = (value: number) => {
  const digitCount = Math.floor(Math.log10(value)) + 1;

  if (digitCount <= 2) {
    return value / 10;
  }
  if (digitCount === 3) {
    return value / 100;
  } else {
    const divisor = Math.pow(10, digitCount - 2);
    let scale = value / divisor;
    return scale;
  }
};

export function formatDateMonths(date: string) {
  const parts = date.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDate = `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;

  return formattedDate;
}

export function convertUnixTimeToDate(unixTime: number) {
  const date = new Date(unixTime);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function formatFullDate(dateISO: Date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(dateISO);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${month} ${day}, ${year} at ${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export function formatFullDateShort(dateInput: Date) {
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${day}/${month}/${year}`;
}

export function capitalize(text: string): string {
  if (text.length === 0) {
    return text; // Return the original string if it's empty
  }

  const firstLetterInUpperCase = text.charAt(0).toUpperCase();
  const restOfText = text.slice(1);

  return firstLetterInUpperCase + restOfText;
}

export const isValidContent = (file: File) => {
  const maxSizeInBytes = 200000; // 200KB
  return file.size <= maxSizeInBytes;
};

export function displayUserPlan(userPlan: UserPlan[]): string {
  const planMapping: { [key in UserPlan]?: string } = {
    [UserPlan.Advanced]: 'Opinion Leader',
    [UserPlan.Advocacy]: 'Employee Creator',
    [UserPlan.Corporate]: 'Corporate Brand on LinkedIn',
    [UserPlan.Clevel]: 'C-Level',
    [UserPlan.Inactive]: ' ',
  };

  return userPlan.map((p) => planMapping[p] || toTitleCase(p)).join(', ');
}

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue !== undefined) {
      return cookieValue.split(';').shift() || null;
    }
  }
  return null;
}

export const convertToBold = (str: string) => {
  const boldOffsetLowercase = 0x1d5ee - 0x61; // Offset for lowercase Latin characters
  const boldOffsetUppercase = 0x1d5d4 - 0x41; // Offset for uppercase Latin characters

  const specialChars: Record<string, string> = {
    à: '𝗮̀',
    á: '𝗮́',
    â: '𝗮̂',
    ã: '𝗮̃',
    ä: '𝗮̈',
    å: '𝗮̊',
    æ: 'æ',
    ç: '𝗰̧',
    è: '𝗲̀',
    é: '𝗲́',
    ê: '𝗲̂',
    ë: '𝗲̈',
    ì: '𝗶̀',
    í: '𝗶́',
    î: '𝗶̂',
    ï: '𝗶̈',
    ð: 'ð',
    ñ: '𝗻̃',
    ò: '𝗼̀',
    ó: '𝗼́',
    ô: '𝗼̂',
    õ: '𝗼̃',
    ö: '𝗼̈',
    ø: 'ø',
    ù: '𝘂̀',
    ú: '𝘂́',
    û: '𝘂̂',
    ü: '𝘂̈',
    ý: '𝐲́',
    ÿ: '𝘆̈',
    À: '𝗔̀',
    Á: '𝗔́',
    Â: '𝗔̂',
    Ã: '𝗔̃',
    Ä: '𝗔̈',
    Å: '𝗔̊',
    Æ: 'Æ',
    Ç: '𝗖̧',
    È: '𝗘̀',
    É: '𝗘́',
    Ê: '𝗘̂',
    Ë: '𝗘̈',
    Ì: '𝗜̀',
    Í: '𝗜́',
    Î: '𝗜̂',
    Ï: '𝗜̈',
    Ð: 'Ð',
    Ñ: '𝗡̃',
    Ò: '𝗢̀',
    Ó: '𝗢́',
    Ô: '𝗢̂',
    Õ: '𝗢̃',
    Ö: '𝗢̈',
    Ø: 'Ø',
    Ù: '𝗨̀',
    Ú: '𝗨́',
    Û: '𝗨̂',
    Ü: '𝗨̈',
    Ý: '𝐘́',
  };

  const boldNumbers: Record<string, string> = {
    '0': '𝟬',
    '1': '𝟭',
    '2': '𝟮',
    '3': '𝟯',
    '4': '𝟰',
    '5': '𝟱',
    '6': '𝟲',
    '7': '𝟳',
    '8': '𝟴',
    '9': '𝟵',
  };

  let boldStr = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = char.codePointAt(0)!;

    if (code >= 0x61 && code <= 0x7a) {
      // Lowercase a-z
      boldStr += String.fromCodePoint(code + boldOffsetLowercase);
    } else if (code >= 0x41 && code <= 0x5a) {
      // Uppercase A-Z
      boldStr += String.fromCodePoint(code + boldOffsetUppercase);
    } else if (boldNumbers[char]) {
      // Numbers 0-9
      boldStr += boldNumbers[char];
    } else if (specialChars[char]) {
      // Special characters
      boldStr += specialChars[char];
    } else if (char === '\u00A0' || char === ' ') {
      // Convert non-breaking space (`\u00A0`) to regular space (`' '`)
      boldStr += ' ';
    } else {
      boldStr += char;
    }
  }

  return boldStr;
};

export const convertToItalic = (str: string) => {
  const italicOffsetLowercase = 0x1d622 - 0x61; // Offset for lowercase Latin characters
  const italicOffsetUppercase = 0x1d608 - 0x41; // Offset for uppercase Latin characters

  // Add specific offsets for special characters
  const specialChars: Record<string, string> = {
    // Lowercase
    à: '𝘢̀',
    á: '𝘢́',
    â: '𝘢̂',
    ã: '𝘢̃',
    ä: '𝘢̈',
    å: '𝘢̊',
    æ: 'æ',
    ç: '𝘤̧',
    è: '𝘦̀',
    é: '𝘦́',
    ê: '𝘦̂',
    ë: '𝘦̈',
    ì: '𝘪̀',
    í: '𝘪́',
    î: '𝘪̂',
    ï: '𝘪̈',
    ð: 'ð',
    ñ: '𝘯̃',
    ò: '𝘰̀',
    ó: '𝘰́',
    ô: '𝘰̂',
    õ: '𝘰̃',
    ö: '𝘰̈',
    ø: 'ø',
    ù: '𝘶̀',
    ú: '𝘶́',
    û: '𝘶̂',
    ü: '𝘶̈',
    ý: '𝘺́',
    ÿ: '𝘺̈',

    // Uppercase
    À: '𝘈̀',
    Á: '𝘈́',
    Â: '𝘈̂',
    Ã: '𝘈̃',
    Ä: '𝘈̈',
    Å: '𝘈̊',
    Æ: 'Æ',
    Ç: '𝘊̧',
    È: '𝘌̀',
    É: '𝘌́',
    Ê: '𝘌̂',
    Ë: '𝘌̈',
    Ì: '𝘐̀',
    Í: '𝘐́',
    Î: '𝘐̂',
    Ï: '𝘐̈',
    Ð: 'Ð',
    Ñ: '𝘕̃',
    Ò: '𝘖̀',
    Ó: '𝘖́',
    Ô: '𝘖̂',
    Õ: '𝘖̃',
    Ö: '𝘖̈',
    Ø: 'Ø',
    Ù: '𝘜̀',
    Ú: '𝘜́',
    Û: '𝘜̂',
    Ü: '𝘜̈',
    Ý: '𝘠́',
  };

  let italicStr = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = char.codePointAt(0)!;

    // For lowercase a-z
    if (code >= 0x61 && code <= 0x7a) {
      italicStr += String.fromCodePoint(code + italicOffsetLowercase);
    }
    // For uppercase A-Z
    else if (code >= 0x41 && code <= 0x5a) {
      italicStr += String.fromCodePoint(code + italicOffsetUppercase);
    }
    // For special characters
    else if (specialChars[char]) {
      italicStr += specialChars[char];
    } else {
      italicStr += char;
    }
  }

  return italicStr;
};

export const convertToBoldItalic = (str: string) => {
  const specialChars: Record<string, string> = {
    à: '𝒂̀',
    á: '𝒂́',
    â: '𝒂̂',
    ã: '𝒂̃',
    ä: '𝒂̈',
    å: '𝒂̊',
    æ: '𝒂𝒆',
    ç: '𝒄̧',
    è: '𝒆̀',
    é: '𝒆́',
    ê: '𝒆̂',
    ë: '𝒆̈',
    ì: '𝒊̀',
    í: '𝒊́',
    î: '𝒊̂',
    ï: '𝒊̈',
    ð: '𝒅',
    ñ: '𝒏̃',
    ò: '𝒐̀',
    ó: '𝒐́',
    ô: '𝒐̂',
    õ: '𝒐̃',
    ö: '𝒐̈',
    ø: '𝒐̷',
    ù: '𝒖̀',
    ú: '𝒖́',
    û: '𝒖̂',
    ü: '𝒖̈',
    ý: '𝒚́',
    ÿ: '𝒚̈',
    À: '𝑨̀',
    Á: '𝑨́',
    Â: '𝑨̂',
    Ã: '𝑨̃',
    Ä: '𝑨̈',
    Å: '𝑨̊',
    Æ: '𝑨𝑬',
    Ç: '𝑪̧',
    È: '𝑬̀',
    É: '𝑬́',
    Ê: '𝑬̂',
    Ë: '𝑬̈',
    Ì: '𝑰̀',
    Í: '𝑰́',
    Î: '𝑰̂',
    Ï: '𝑰̈',
    Ð: '𝑫',
    Ñ: '𝑵̃',
    Ò: '𝑶̀',
    Ó: '𝑶́',
    Ô: '𝑶̂',
    Õ: '𝑶̃',
    Ö: '𝑶̈',
    Ø: '𝑶̷',
    Ù: '𝑼̀',
    Ú: '𝑼́',
    Û: '𝑼̂',
    Ü: '𝑼̈',
    Ý: '𝒀́',
  };

  const boldItalicNumbers: Record<string, string> = {
    '0': '𝟎',
    '1': '𝟏',
    '2': '𝟐',
    '3': '𝟑',
    '4': '𝟒',
    '5': '𝟓',
    '6': '𝟔',
    '7': '𝟕',
    '8': '𝟖',
    '9': '𝟗',
  };

  let boldItalicStr = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = char.codePointAt(0)!;

    if (code >= 0x61 && code <= 0x7a) {
      // Convert to bold italic lowercase
      boldItalicStr += String.fromCodePoint(code + (0x1d656 - 0x61));
    } else if (code >= 0x41 && code <= 0x5a) {
      // Convert to bold italic uppercase
      boldItalicStr += String.fromCodePoint(code + (0x1d63c - 0x41));
    } else if (boldItalicNumbers[char]) {
      boldItalicStr += boldItalicNumbers[char];
    } else if (specialChars[char]) {
      boldItalicStr += specialChars[char];
    } else {
      boldItalicStr += char;
    }
  }
  return boldItalicStr;
};

export function formatDateToCustomFormat(dateString: string): string {
  const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('es-ES', options);
}

export function getMimeType(url: string): string {
  const basePath = url.split('?')[0]; // Split the URL at the '?' and take the first part
  const extension = basePath.split('.').pop()?.toLowerCase();

  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    webp: 'image/webp',
    pdf: 'application/pdf',
    html: 'text/html',
    txt: 'text/plain',
    // Add more mappings as needed
  };

  return mimeTypes[extension || ''] || 'application/octet-stream';
}

export const past30Days = (dateString: string): boolean => {
  if (!dateString) {
    return false;
  }
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return false;
  }

  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  return date >= thirtyDaysAgo;
};

export const getRandomHexColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const sliceText = (text: string, maxLength: number) => {
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + '...';
  }
  return text;
};

export const cleanAndSliceText = (input: string, sliceLength?: number): string => {
  const cleanedText = input.replace(/<\/?[^>]+(>|$)/g, '');

  if (sliceLength !== undefined && cleanedText.length > sliceLength) {
    return cleanedText.slice(0, sliceLength) + '...';
  }

  return cleanedText;
};

export function formatDateToLongString(dateInput: Date, language = 'en') {
  // Crear un objeto Date a partir de la entrada
  const date = new Date(dateInput);

  // Configurar opciones para formatear la fecha
  const options: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // Formatear la fecha en base al idioma especificado
  return capitalize(date.toLocaleDateString(language, options));
}

export const extractInnerText = (html: string): string => {
  // Crear un elemento temporal para parsear el HTML
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Buscar el primer elemento con la clase "tiptap-p"
  const paragraphElement = tempElement.querySelector('p');

  // Devolver el texto interno del elemento encontrado, o una cadena vacía si no existe
  return paragraphElement ? paragraphElement.textContent || '' : '';
};

export function extractUrlsFromText(text: string, paste: boolean = false, user: User) {
  // Expresión regular para encontrar URLs
  const urlRegex =
    /(?<![@\w])((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/a-zA-Z0-9#?&=._%-]*)?)/g;

  if (paste) {
    // Si es un pegado, devolver todas las URLs encontradas
    const urls = text.match(urlRegex) || [];
    if (user?.shell?.utm) {
      return urls.map((url) => `${url}?${user?.shell?.utm}`);
    }
    return urls;
  } else {
    // Si no es un pegado, solo devolver las URLs que tienen un espacio, salto de línea o <br> después
    const matches = [];
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      let url = match[0];
      const endIndex = match.index + url.length;
      const restOfText = text.slice(endIndex);

      // Verificar si el carácter después de la URL es un espacio, salto de línea o <br>
      const nextChars = restOfText.slice(0, 4); // Tomamos los siguientes 4 caracteres para verificar '<br>'
      if (
        nextChars.startsWith(' ') ||
        nextChars.startsWith('\n') ||
        nextChars.startsWith('\r') ||
        nextChars.startsWith('<br') || // Puede ser <br> o <br/>
        restOfText === '' // Si es el final del texto
      ) {
        if (user?.shell?.utm) {
          url = `${url}?${user.shell.utm}`;
        }
        matches.push(url);
      }
    }

    return matches;
  }
}

export const cleanHtmlHandler = (html: string): string => {
  const elementHtml = document.createElement('br');
  elementHtml.innerHTML = html;
  let modifiedHtml = html.replaceAll('\n', '<br>');
  modifiedHtml = modifiedHtml.replaceAll('</p>', '<br><br></p>');
  const lastBrIndex = modifiedHtml.lastIndexOf('<br><br>');
  if (lastBrIndex !== -1) {
    modifiedHtml =
      modifiedHtml.substring(0, lastBrIndex) + modifiedHtml.substring(lastBrIndex + 4);
  }

  return modifiedHtml;
};

export const lightenColor = (color: string, amount: number = 0.5): string => {
  // Convierte el color hexadecimal a RGB
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  // Aclara el color
  r = Math.min(255, r + 255 * amount);
  g = Math.min(255, g + 255 * amount);
  b = Math.min(255, b + 255 * amount);

  // Convierte el RGB de nuevo a hexadecimal
  return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g)
    .toString(16)
    .padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;
};

export function parsePostContentToLinkedinCommentary(html: string): {
  text: string;
  totalStrongAndEmCount: number;
} {
  const $ = cheerio.load(html);
  let strongCharacterCount = 0;
  let emCharacterCount = 0;

  // Function to count only letters and numbers in a string
  const countLettersAndNumbers = (text: string): number => {
    const match = text.match(/[a-zA-Z0-9]/g); // Matches only letters and numbers
    return match ? match.length : 0;
  };

  // Process mentions
  $('[data-type="mention"]').each((i, elem) => {
    const label = $(elem).attr('data-label');
    const dataId = $(elem).attr('data-id');
    const id = dataId ? dataId.replace(/_/g, '\\_') : '';
    $(elem).replaceWith(`@[${label}](${id})`);
  });

  // Count letters and numbers inside <strong> and <em> tags
  $('strong').each((i, elem) => {
    strongCharacterCount += countLettersAndNumbers($(elem).text());
  });

  $('em').each((i, elem) => {
    emCharacterCount += countLettersAndNumbers($(elem).text());
  });

  // Replace <br> tags with newline characters
  $('br').replaceWith('\n');

  // Handle paragraphs: add a newline after each paragraph's content
  $('p').each((i, elem) => {
    $(elem).append('\n');
    let text = $(elem).text();

    // Regular expression to detect URLs in plain text
    const urlRegex =
      /((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/a-zA-Z0-9#?&=._-]*)?)/g;

    // Replace plain text URLs with appended "?hola" if not present
    text = text.replace(urlRegex, (url) => url);

    $(elem).text(text); // Update the paragraph content with modified URLs
  });

  return {
    text: $('body').text().trim(),
    totalStrongAndEmCount: strongCharacterCount + emCharacterCount,
  };
}

export function linkedinEscapeChars(text: string): string {
  // Temporarily replace mentions with a unique placeholder that doesn't contain special characters
  const mentions: string[] = [];
  text = text.replace(/@\[[^\]]+\]\([^)]+\)/g, (match) => {
    const placeholder = `LINKEDINMENTIONPLACEHOLDER${mentions.length}`;
    mentions.push(match);
    return placeholder;
  });

  // Escape special characters
  text = text.replace(/[|{}@[\]()<>*_~]/g, '\\$&');

  // Restore the mentions from placeholders
  mentions.forEach((mention, index) => {
    text = text.replace(`LINKEDINMENTIONPLACEHOLDER${index}`, mention);
  });

  return text;
}
export const handleURLParameters = (
  setLoadingUpdateBrandAccount: (value: boolean) => void,
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const postId = searchParams.get('postId') || undefined;
  const error = searchParams.get('error');

  if (postId) {
    // Elimina solo el parámetro postId
    searchParams.delete('postId');
    const newUrl = searchParams.toString()
      ? `${window.location.pathname}?${searchParams.toString()}`
      : window.location.pathname;

    if (error !== 'true') {
      setLoadingUpdateBrandAccount(true);
    } else {
      searchParams.delete('error');
    }

    // Actualiza la URL sin el parámetro postId
    window.history.replaceState({}, '', newUrl);
  }
};

export const insertAIStreamingContent = (
  editorState?: EditorState,
  setEditorState?: (state: EditorState) => void,
  newText?: string,
  accumulatedTextRef?: React.MutableRefObject<string>,
  handleChange?: (editorState: EditorState) => void,
  onChange?: (html: string, json: any, action?: Tag) => void,
  action?: Tag,
) => {
  if (!editorState) return;

  let accumulatedText = accumulatedTextRef?.current || '';
  accumulatedText += newText;

  if (accumulatedTextRef) {
    accumulatedTextRef.current = accumulatedText;
  }

  // Determine final text placement
  let finalText;

  finalText = accumulatedText; // Default behavior (overwrite)

  const newContentState = ContentState.createFromText(finalText);
  let newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-characters',
  );
  newEditorState = EditorState.moveSelectionToEnd(newEditorState);

  if (setEditorState && handleChange) {
    setEditorState(newEditorState);
    handleChange(newEditorState);
  }

  const rawContent = convertToRaw(newContentState);
  if (onChange) {
    onChange(
      finalText,
      rawContent,
      action || { name: 'AI_STREAMING', color: '#FFFFFF', internal: true },
    );
  }
  return newEditorState;
};

export function updateEditorStateWithMarkdownFormatting(
  editorState: EditorState,
): EditorState {
  // Convert the current ContentState to HTML
  let html = stateToHTML(editorState.getCurrentContent());

  // Replace markdown markers with HTML tags:
  // Bold: **text** becomes <strong>text</strong>
  // Italic: *text* becomes <em>text</em>
  html = html
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');

  //for debugging
  //console.log('html', html);

  return createEditorStateWithMentions(html);
}

export function editorStateToMarkdown(html?: string): string {
  const $ = cheerio.load(html || '');
  // Then process <strong> / <b>
  $('strong, b').each((_, elem) => {
    const elementText = normalizeText($(elem).text());
    $(elem).replaceWith(`**${elementText}**`);
  });

  // Then process <em> / <i>
  $('em, i').each((_, elem) => {
    const elementText = normalizeText($(elem).text());
    $(elem).replaceWith(`*${elementText}*`);
  });

  const placeholders: { placeholder: string; mentionHtml: string }[] = [];
  $('span[data-type="mention"]').each((i, elem) => {
    const mentionHtml = $.html(elem); // get the full HTML for the mention
    const placeholder = `__MENTION_PLACEHOLDER_${i}__`;
    placeholders.push({ placeholder, mentionHtml });
    $(elem).replaceWith(placeholder);
  });

  let text = $.text();
  placeholders.forEach(({ placeholder, mentionHtml }) => {
    text = text.replace(placeholder, mentionHtml);
  });
  //for debugging
  //console.log('htmlfeoaofaoomfa', text);
  return text;
}

export const convertEditorStateToHTML = (contentState: ContentState): string => {
  return stateToHTML(contentState, {
    entityStyleFn: (entity) => {
      if (entity.getType() === 'SIGNATURE') {
        return {
          element: 'span',
          attributes: { class: 'signature' },
        };
      }
      if (entity.getType() === 'MENTION') {
        const { tag, tagId } = entity.getData();
        return {
          element: 'span',
          attributes: {
            'data-type': 'mention',
            class: 'mention',
            'data-label': tag,
            'data-id': tagId,
            contenteditable: 'false',
          },
          style: { color: 'blue', fontWeight: 'bold' },
        };
      }
    },
    inlineStyles: {
      BOLD: { element: 'strong' },
      ITALIC: { element: 'em' },
    },
  });
};

export const createEditorStateWithMentions = (html: string): EditorState => {
  let contentState = stateFromHTML(html);

  const mentionRegex =
    /<span[^>]*data-type=["']mention["'][^>]*class=["']mention["'][^>]*data-label=["'](.*?)["'][^>]*data-id=["'](.*?)["'][^>]*>(.*?)<\/span>/gi;

  let match;
  const blockArray = contentState.getBlocksAsArray();

  blockArray.forEach((block) => {
    let text = block
      .getText()
      .replace(/&nbsp;/g, ' ')
      .replace(/\u00a0/g, ' ');

    if (!text) return;

    const blockKey = block.getKey();
    let newContentState = contentState;

    while ((match = mentionRegex.exec(html)) !== null) {
      const [, tag, tagId, mentionText] = match;

      // Clean up the mention text
      const normalizedMentionText = mentionText
        .replace(/&nbsp;/g, ' ')
        .replace(/\u00a0/g, ' ')
        .trim();

      let mentionStart = text.indexOf(normalizedMentionText);

      while (mentionStart !== -1) {
        const mentionEnd = mentionStart + normalizedMentionText.length;

        newContentState = newContentState.createEntity('MENTION', 'IMMUTABLE', {
          tag,
          tagId,
          mentionText: normalizedMentionText,
        });

        const entityKey = newContentState.getLastCreatedEntityKey();
        const selection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: mentionStart,
          focusOffset: mentionEnd,
        });

        newContentState = Modifier.applyEntity(newContentState, selection, entityKey);

        mentionStart = text.indexOf(normalizedMentionText, mentionEnd);
      }
    }

    contentState = newContentState;
  });

  return EditorState.createWithContent(contentState, MentionDecorator);
};

export const normalizeHtmlContent = (htmlContent: string): string => {
  const $ = cheerio.load(htmlContent);
  let normalizedContent = '';

  $('body')
    .children()
    .each((_, elem) => {
      const tag = elem.tagName.toLowerCase();
      let content = $(elem).html()?.trim() || '';

      // Remove heading tags but preserve inner content
      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tag)) {
        normalizedContent += (normalizedContent ? '<br>' : '') + content;
      } else if (tag === 'p') {
        if (content === '<br>') {
          normalizedContent += '<br>'; // Convert empty <p> with <br> to a single break
        } else {
          normalizedContent += (normalizedContent ? '<br>' : '') + content;
        }
      } else if (tag === 'ul' || tag === 'ol') {
        // 🔹 Extract the inner content of the list items and preserve it
        $(elem)
          .find('li')
          .each((_, li) => {
            const listItemContent = $(li).html()?.trim();
            if (listItemContent) {
              normalizedContent += (normalizedContent ? '<br>' : '') + listItemContent;
            }
          });
      } else {
        normalizedContent += (normalizedContent ? '<br>' : '') + content;
      }
    });

  return `<p>${normalizedContent}</p>`;
};

export const normalizeHtmlOnLoad = (htmlContent: string): string => {
  return htmlContent
    .replace(/\n/g, '') // Remove all newlines
    .replace(/<br>\s*/g, '<br>') // Normalize `<br>` spacing
    .replace(/&nbsp;/g, ' ') // Convert non-breaking spaces
    .trim();
};

const normalizeText = (text: string) =>
  decode(text.normalize('NFKC'))
    .replace(/\u00a0/g, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/\s+/g, ' ');

export const parsePostContentForPreview = (
  plainText: string,
  html: string,
  saveToDB: boolean,
) => {
  // If the HTML includes a known Tiptap wrapper, just return the plain text
  if (html.includes('<p class="tiptap-p">')) return plainText;

  const $ = cheerio.load(html);

  // Process nested <em><strong> or <i><strong> first
  $('em strong, i strong, em b, i b').each((_, elem) => {
    const elementText = normalizeText($(elem).text());
    $(elem).replaceWith(convertToBoldItalic(elementText));
  });

  // Then process <strong> / <b>
  $('strong, b').each((_, elem) => {
    const elementText = normalizeText($(elem).text());
    $(elem).replaceWith(convertToBold(elementText));
  });

  // Then process <em> / <i>
  $('em, i').each((_, elem) => {
    const elementText = normalizeText($(elem).text());
    $(elem).replaceWith(convertToItalic(elementText));
  });

  // If we are eventually going to do the mention replacements (i.e. not saveToDB),
  // replace each mention <span> with a placeholder so we can do a one-to-one swap
  // in the final text.
  interface Placeholder {
    placeholder: string;
    mentionId: string;
    mentionLabel: string;
  }

  let placeholders: Placeholder[] = [];
  if (!saveToDB) {
    // Find all mention spans
    $('span[data-type="mention"]').each((i, elem) => {
      const mentionId = $(elem).attr('data-id') || '';
      const rawLabel = $(elem).attr('data-label') || '';

      // Convert HTML entities & remove &nbsp;, but do NOT collapse multiple spaces
      // so that it remains as typed.
      const decodedLabel = decode(rawLabel.normalize('NFKC'))
        .replace(/\u00a0/g, ' ')
        .replace(/&nbsp;/g, ' ');

      const mentionLabel = decodedLabel.replace(/^@/, '');

      // Generate a unique placeholder, e.g. "MENTION_0"
      const placeholder = `MENTION_${i}`;

      // Store how we want to replace it later
      placeholders.push({
        placeholder,
        mentionId,
        mentionLabel,
      });

      // Replace the actual <span> in the DOM with the placeholder text
      // so that $.text() will yield that placeholder in exactly the right spot
      $(elem).replaceWith(placeholder);
    });
  }

  // Now get the plain text from the DOM (which includes placeholders if !saveToDB)
  let returnPlainText = $.text();

  returnPlainText = hideDataIdInPlainText(returnPlainText);

  // After we have the raw text, swap the placeholders back to <span> if not saving to DB
  if (!saveToDB) {
    for (const { placeholder, mentionId, mentionLabel } of placeholders) {
      const mentionSpan = `<span style="color:blue; font-weight:bold;" data-id="${mentionId}" data-type="mention">${mentionLabel}</span>`;
      // Replace each occurrence of the placeholder with the final mentionSpan
      returnPlainText = returnPlainText.replace(
        new RegExp(placeholder, 'g'),
        mentionSpan,
      );
    }
  }

  return returnPlainText;
};

const hideDataIdInPlainText = (text: string) => {
  const regex = /data-id(?:\s*=\s*"?[^"]*"?){0,1}/g;
  return text.replace(regex, (match) => {
    // Replace each character in the match with a zero-width space.
    return match.replace(/./g, '\u200B');
  });
};

export const removeInlineStyleFromMentions = (
  editorState: EditorState,
  style: string,
): EditorState => {
  let contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  blockMap.forEach((block) => {
    const blockKey = block?.getKey();
    block?.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          return entity.getType() === 'MENTION';
        }
        return false;
      },
      (start, end) => {
        // Create a selection for this range
        if (blockKey) {
          const selection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: end,
          });
          // Remove the specified style from this selection
          contentState = Modifier.removeInlineStyle(contentState, selection, style);
        }
      },
    );
  });

  return EditorState.push(editorState, contentState, 'change-inline-style');
};
