import {
  Stack,
  Divider,
  Button,
  IconButton,
  Paper,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Badge,
} from '@mui/material';
import { useContext, useState, useRef, useEffect } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { MessagesTeam, Mention, UserRole } from '../../__generated__/graphql';
import { getFullName } from '../../utils';
import CommentItem from './CommentItem';
import { useTranslation } from 'react-i18next';
import EmojiSelector from '../SelectorComponents/EmojiSelector';
import { InsertEmoticon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GET_USERS_TO_MENTION } from '../../graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import ReactDOM from 'react-dom';
import {
  Editor,
  EditorState,
  Modifier,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  SelectionState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { DELETE_COMMENT } from '../../graphql/mutations';
import { PostContext } from '../../context/PostContext';
import { AuthContext } from '../../context/AuthContext';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { SnackbarContext } from '../../context/SnackbarContext';

// Helper to find mention entities in a Draft.js ContentBlock
function findMentionEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION'
    );
  }, callback);
}

const MentionSpan = (props: any) => (
  <span style={{ color: 'blue', fontWeight: 'bold' }}>{props.children}</span>
);

interface CommentsProps {
  messages: MessagesTeam[];
  writing: boolean;
  isLink?: boolean;
  isLinkInbox?: boolean;
  setFixedWidth?: boolean;
}

const Comments: React.FC<CommentsProps> = ({
  messages,
  writing,
  isLink = false,
  isLinkInbox = false,
  setFixedWidth = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(
      new CompositeDecorator([{ strategy: findMentionEntities, component: MentionSpan }]),
    ),
  );
  const editorRef = useRef<Editor | null>(null);

  // --- Mention Autocomplete States ---
  const [mentionOpen, setMentionOpen] = useState(false);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const mentionListRef = useRef<HTMLDivElement>(null);

  // --- Emoji Selector States ---
  const anchorRef = useRef(null);
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);

  // --- Delete All Comments Dialog State ---
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteAllComments] = useMutation(DELETE_COMMENT);

  const { data: getUsersByShell } = useQuery(GET_USERS_TO_MENTION, {
    variables: {
      shellId:
        user?.shellId ||
        postState?.post?.shellId ||
        postState?.postRef?.current?.shellId ||
        '',
      postId: postState?.post?._id || postState?.postRef?.current?._id || '',
      isLink: isLink || isLinkInbox,
    },
  });
  const usersList =
    getUsersByShell?.getUsersToMention?.map((user: any) => ({
      id: user._id,
      display: user.firstName
        ? `${user.firstName} ${user.lastName || ''}`
        : user.email || user._id,
    })) || [];

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleEmojiInputChange = (emoji: string) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    // Insert the emoji at the current cursor position
    const newContentState = Modifier.insertText(contentState, selection, emoji);
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    setEditorState(newEditorState);
    setEmojiComponentOpen(false);
    setTimeout(() => {
      editorRef.current?.focus();
    }, 0);
  };

  const handleInputChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const text = newEditorState.getCurrentContent().getPlainText();
    const match = /@(\S*)$/.exec(text);
    if (match) {
      const searchText = match[1].toLowerCase();
      const newFiltered = usersList.filter((u) =>
        u.display.toLowerCase().includes(searchText),
      );
      setFilteredUsers(newFiltered);
      setVisibleCount(10);
      setMentionOpen(true);
    } else {
      setMentionOpen(false);
    }
  };

  useEffect(() => {
    if (!mentionOpen) return;
    const sel = window.getSelection();
    if (!sel || sel.rangeCount === 0) return;
    const range = sel.getRangeAt(0);
    const selectionRect = range.getBoundingClientRect();
    if (selectionRect) {
      const mentionHeight = mentionListRef.current?.offsetHeight || 200;
      setMentionPosition({
        top: selectionRect.top + window.scrollY - mentionHeight - 8,
        left: selectionRect.left + window.scrollX,
      });
    }
  }, [mentionOpen, filteredUsers]);

  // Inserts a mention and creates a mention entity using a flat structure.
  const handleInsertMention = (user: { id: string; display: string }) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const blockKey = selection.getStartKey();
    const blockText = content.getBlockForKey(blockKey).getText();
    const atIndex = blockText.lastIndexOf('@', selection.getStartOffset());
    if (atIndex === -1) return;

    const mentionText = `@${user.display}`;
    const mentionRange = selection.merge({ anchorOffset: atIndex }) as SelectionState;

    let newContent = Modifier.replaceText(content, mentionRange, mentionText);
    // Create entity with flat mention data: display and id.
    newContent = newContent.createEntity('MENTION', 'IMMUTABLE', {
      user: { display: user.display, id: user.id },
    });
    const entityKey = newContent.getLastCreatedEntityKey();
    const updatedRange = mentionRange.merge({
      focusOffset: atIndex + mentionText.length,
    }) as SelectionState;
    newContent = Modifier.applyEntity(newContent, updatedRange, entityKey);

    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(newState, newContent.getSelectionAfter());
    setEditorState(EditorState.moveFocusToEnd(newState));
    setMentionOpen(false);

    // Refocus the editor so the user can continue typing.
    setTimeout(() => {
      editorRef.current?.focus();
    }, 0);
  };

  const handleMentionScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setVisibleCount((prev) => prev + 10);
    }
  };

  const handleMentionButtonClick = () => {
    if (mentionOpen) return;
    // Focus the editor immediately so window.getSelection() is available
    editorRef.current?.focus();

    // Get current content and selection
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Insert "@" at the current cursor position
    const newContentState = Modifier.insertText(contentState, selection, '@');
    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    newEditorState = EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter(),
    );

    // Instead of just updating state, pass the new state to your onChange handler
    handleInputChange(newEditorState);
  };

  // Extracts mentions from the content. It returns only flat mention objects.
  const extractMentions = (content: ContentState): Mention[] => {
    const mentionsMap = new Map<string, Mention>();
    content.getBlockMap().forEach((block) => {
      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey !== null) {
            const entity = content.getEntity(entityKey);
            return entity.getType() === 'MENTION';
          }
          return false;
        },
        (start, end) => {
          const entityKey = block.getEntityAt(start);
          if (entityKey) {
            const entity = content.getEntity(entityKey);
            const data = entity.getData();
            if (data && data.user && data.user.display && data.user.id) {
              mentionsMap.set(data.user.id, {
                name: data.user.display,
                userId: data.user.id,
              });
            }
          }
        },
      );
    });
    return Array.from(mentionsMap.values());
  };

  // Handle the deletion of all comments after confirmation
  const handleDeleteAll = async () => {
    try {
      await deleteAllComments({
        variables: {
          postId: postState?.post?._id || postState?.postRef?.current?._id || '',
          allComments: true,
        },
      });
      postState?.refetch();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting all comments:', error);
    }
  };

  return (
    <>
      <Divider sx={{ my: 1 }} />
      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>{t('Confirm Deletion')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Are you sure you want to delete all comments? This action cannot be undone.',
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>{t('Cancel')}</Button>
          <Button onClick={handleDeleteAll} color="error">
            {t('Delete All')}
          </Button>
        </DialogActions>
      </Dialog>

      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          {/* Messages container with calculated height */}
          <Box
            sx={{
              flex: 1,
            }}
          >
            {/* Optional "Delete All" button */}
            {messages.length !== 0 &&
              (user?.role.includes(UserRole.LimitedBrandManager) ||
                user?.role.includes(UserRole.BrandManager)) && (
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ mb: 2 }}
                >
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => setOpenDeleteDialog(true)}
                    sx={{
                      fontSize: '0.875rem',
                      color: 'red',
                      borderColor: 'red',
                      padding: '4px 8px',
                      minWidth: 'auto',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.04)',
                        borderColor: 'darkred',
                      },
                    }}
                  >
                    {t('Delete All')}
                  </Button>
                </Stack>
              )}

            {/* Render messages */}
            <Stack spacing={1}>
              {[...messages].reverse().map((msg, index) => {
                let avatar = null;
                let name = '';
                if (msg.userOrBrand?.__typename === 'User') {
                  avatar = msg.userOrBrand.avatar;
                  name = getFullName(msg.userOrBrand);
                } else if (msg.userOrBrand?.__typename === 'Brand') {
                  avatar = msg.userOrBrand.account?.avatar;
                  name = msg.userOrBrand.name;
                }
                return (
                  <CommentItem
                    key={'comment-' + index}
                    userOrBrand={msg.userOrBrand}
                    text={msg.content}
                    avatar={avatar || ''}
                    time={msg.time}
                    name={name || 'Unknown'}
                    mentions={(msg.mentions || []).filter(
                      (mention): mention is Mention => mention !== null,
                    )}
                    commentId={msg.id || ''}
                    isLink={isLink}
                    isLinkInbox={isLinkInbox}
                    usersList={usersList}
                  />
                );
              })}
            </Stack>
          </Box>

          {/* Fixed Editor at the bottom */}
          <Paper
            elevation={0}
            sx={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              gap: 0.5,
              p: 1,
              borderRadius: 2,
              border: '1px solid #C1C1C1',
              alignItems: 'center',
              backgroundColor: 'white',
              zIndex: 1200,
            }}
          >
            {/* Editor container */}
            <div
              style={{
                flex: 1,
                minHeight: '40px',
                border: '1px solid gray',
                padding: '4px',
                width: '40px',
              }}
              onClick={() => editorRef.current?.focus()}
            >
              <Editor
                ref={editorRef}
                editorState={editorState}
                onChange={handleInputChange}
              />
            </div>

            <IconButton onClick={handleEmojiComponentOpen} ref={anchorRef}>
              <InsertEmoticon />
            </IconButton>

            <Badge
              badgeContent="New"
              color="primary"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <IconButton onClick={handleMentionButtonClick}>
                <AlternateEmailIcon />
              </IconButton>
            </Badge>

            <EmojiSelector
              open={emojiComponentOpen}
              anchorElement={anchorRef.current}
              onClose={() => setEmojiComponentOpen(false)}
              onChange={handleEmojiInputChange}
            />

            <Button
              disabled={writing || !editorState.getCurrentContent().hasText()}
              onClick={async () => {
                const content = editorState.getCurrentContent();
                const mentions = extractMentions(content);
                const success = await postState?.handlers?.handleSendChatMessage(
                  content.getPlainText(),
                  mentions,
                );
                if (success) {
                  setEditorState(
                    EditorState.createEmpty(
                      new CompositeDecorator([
                        { strategy: findMentionEntities, component: MentionSpan },
                      ]),
                    ),
                  );
                } else {
                  setErrorMessage(t('Failed to send message. Please try again.'));
                }
              }}
              sx={{
                backgroundColor: '#6E7794',
                color: 'white',
                marginLeft: 1,
                '&:hover': { backgroundColor: '#5F687F' },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  color: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              {t('Save')}
            </Button>
          </Paper>
        </Box>
      ) : (
        <Stack direction="column" spacing={0.5}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              gap: 0.5, // reduced gap between items
              padding: '10px',
              width: '100%',
              borderRadius: 2,
              border: '1px solid #C1C1C1',
              alignItems: 'center', // vertical alignment for all children
            }}
          >
            <div
              style={{
                width: setFixedWidth ? '570px' : '720px', // Set a fixed width for the editor
                minHeight: '40px',
                border: '1px solid gray',
              }}
              onClick={() => editorRef.current?.focus()}
            >
              <Editor
                ref={editorRef}
                editorState={editorState}
                onChange={handleInputChange}
              />
            </div>

            <IconButton onClick={handleEmojiComponentOpen} ref={anchorRef}>
              <InsertEmoticon />
            </IconButton>
            <Badge
              badgeContent="New"
              color="primary"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <IconButton onClick={handleMentionButtonClick}>
                <AlternateEmailIcon />
              </IconButton>
            </Badge>

            <EmojiSelector
              open={emojiComponentOpen}
              anchorElement={anchorRef.current}
              onClose={() => setEmojiComponentOpen(false)}
              onChange={handleEmojiInputChange}
            />

            <Button
              disabled={writing || !editorState.getCurrentContent().hasText()}
              onClick={async () => {
                const content = editorState.getCurrentContent();
                const mentions = extractMentions(content);
                const success = await postState?.handlers?.handleSendChatMessage(
                  content.getPlainText(),
                  mentions,
                );
                console.log('Success:', success);
                if (success) {
                  setEditorState(
                    EditorState.createEmpty(
                      new CompositeDecorator([
                        { strategy: findMentionEntities, component: MentionSpan },
                      ]),
                    ),
                  );
                } else {
                  setErrorMessage(t('Failed to send message. Please try again.'));
                }
              }}
              sx={{
                backgroundColor: '#6E7794',
                color: 'white',
                marginLeft: 1,
                '&:hover': { backgroundColor: '#5F687F' },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  color: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              {t('Save')}
            </Button>
          </Paper>
          {messages.length !== 0 &&
            (user?.role.includes(UserRole.LimitedBrandManager) ||
              user?.role.includes(UserRole.BrandManager)) && (
              <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mb: 2 }}>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenDeleteDialog(true)}
                  sx={{
                    fontSize: '0.875rem', // Make the button text smaller
                    color: 'red', // Set text color to red
                    borderColor: 'red', // Make the border red
                    padding: '4px 8px', // Adjust padding for a smaller button
                    minWidth: 'auto', // Adjust width to content
                    '&:hover': {
                      backgroundColor: 'rgba(255, 0, 0, 0.04)', // Light red hover effect
                      borderColor: 'darkred', // Darken border on hover
                    },
                  }}
                >
                  {t('Delete All')}
                </Button>
              </Stack>
            )}

          {[...messages].reverse().map((msg, index) => {
            let avatar = null;
            let name = '';
            if (msg.userOrBrand?.__typename === 'User') {
              avatar = msg.userOrBrand.avatar;
              name = getFullName(msg.userOrBrand);
            } else if (msg.userOrBrand?.__typename === 'Brand') {
              avatar = msg.userOrBrand.account?.avatar;
              name = msg.userOrBrand.name;
            }
            return (
              <CommentItem
                key={'comment-' + index}
                userOrBrand={msg.userOrBrand}
                text={msg.content}
                avatar={avatar || ''}
                time={msg.time}
                name={name || 'Unknown'}
                mentions={(msg.mentions || []).filter(
                  (mention): mention is Mention => mention !== null,
                )}
                commentId={msg.id || ''}
                isLink={isLink}
                isLinkInbox={isLinkInbox}
                usersList={usersList}
                setFixedWidth={setFixedWidth}
              />
            );
          })}
        </Stack>
      )}

      {mentionOpen &&
        ReactDOM.createPortal(
          <Paper
            ref={mentionListRef}
            style={{
              position: 'absolute',
              top: mentionPosition.top,
              left: mentionPosition.left,
              zIndex: 9999,
              maxHeight: 200,
              width: 200,
              overflowY: 'auto',
              border: '1px solid #ccc',
            }}
            onScroll={handleMentionScroll}
            onWheel={(e) => e.stopPropagation()} // Prevent desktop scroll bubbling
            onTouchMove={(e) => e.stopPropagation()} // Prevent mobile scroll bubbling
          >
            {filteredUsers.slice(0, visibleCount).map((user) => (
              <MenuItem
                key={user.id}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleInsertMention(user);
                }}
              >
                {user.display}
              </MenuItem>
            ))}
          </Paper>,
          document.body,
        )}
    </>
  );
};

export default Comments;
