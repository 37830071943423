import { getAuth } from 'firebase/auth';
import { useState } from 'react';
import { Post, SocialMediaAccount } from '../__generated__/graphql';
import { DefaultErrorMessage, StatusCode } from '../errors/constants';

const useLinkedin = () => {
  const [error, setError] = useState<{ code: StatusCode; message?: string } | null>(null);

  const isTokenAboutToExpire = (account: SocialMediaAccount | undefined | null, date: Date | null) => {
    if (!account) {
        throw new Error("Account doesn't exist");
    }

    // Obtener la fecha de expiración del token
    const linkedinTokenExpires = new Date(account.exp);

    // Usar la fecha proporcionada o la fecha actual si date es null
    const comparisonDate = date || new Date();

    // Comparar las fechas
    if (linkedinTokenExpires < comparisonDate) {
        return true; // El token ha expirado o está a punto de expirar
    }

    return false; // El token no ha expirado
};

  const postOnLinkedin = async (
    post: Post,
    account: SocialMediaAccount,
    onComplete: (postUrn: string, postId: string) => void,
    onError: () => void,
  ) => {
    const token = await getAuth().currentUser?.getIdToken();

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/linkedin/post`, {
      method: 'POST',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ postId: post?._id, accountId: account?.id }),
    })
      .then((res) => res.json())
      .then(({ data, success, message, code }) => {
        if (!success) onError();

        if (!success && code === 'LINKEDIN_TOKEN') {
          setError({ code: StatusCode.LINKEDIN_TOKEN_ERROR });
          return;
        }

        if (!success) throw new Error(message);
        if (code === 'PROCESSING') return;

        onComplete(data.postUrn, post?._id);
      })
      .catch((err) => {
        const message = err.message
          ? `${DefaultErrorMessage.LINKEDIN_POST_ERROR}: ${err.message}`
          : DefaultErrorMessage.LINKEDIN_POST_ERROR;
        setError({ code: StatusCode.LINKEDIN_POST_ERROR, message });
      });
  };

  const unpublishLinkedinPost = async (
    post: Post,
    account: SocialMediaAccount,
    onComplete: () => void,
    onError: () => void,
  ) => {
    const token = await getAuth().currentUser?.getIdToken();

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/linkedin/delete`, {
      method: 'POST',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ postId: post?._id, accountId: account?.id }),
    })
      .then((res) => res.json())
      .then(({ success }) => {
        if (!success) {
          const message = `${DefaultErrorMessage.LINKEDIN_UNPUBLISH_ERROR}`;
          setError({ code: StatusCode.LINKEDIN_UNPUBLISH_ERROR, message });
          return;
        }
        onComplete();
      })
      .catch((err) => {
        const message = `${DefaultErrorMessage.LINKEDIN_UNPUBLISH_ERROR}`;
        setError({ code: StatusCode.LINKEDIN_UNPUBLISH_ERROR, message });
        onError();
      });
  };

  return {
    postOnLinkedin,
    isTokenAboutToExpire,
    error,
    deleteLinkedinPost: unpublishLinkedinPost,
  };
};

export default useLinkedin;
