import { useQuery } from '@apollo/client';
import { FilterAlt } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderByDirection, OrderByField } from '../../__generated__/graphql';
import { PostsFilter } from '../../components/Filters/PostsFilter';
import PostsTable from '../../components/Tables/PostsTable';
import { PostsFilterAdminContext } from '../../context/PostsFilterAdminContext';
import { GET_POSTS } from '../../graphql/queries';
import { OrderBy } from '../../types';
import { useTranslation } from 'react-i18next';

const PostsTab = () => {
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const { filter, isFilterActive } = useContext(PostsFilterAdminContext);
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });

  const onPageChange = (page: number) => {
    setPage(() => page);
  };

  const { data } = useQuery(GET_POSTS, {
    fetchPolicy: 'network-only',
    variables: {
      filter,
      orderBy,
      page,
      limit,
    },
  });

  const posts = data?.posts.posts || [];
  const total = data?.posts.total || 0;

  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing={1}>
      {/* <LanguageSwitcher /> */}
      <Stack direction="row" width="100%" textAlign="center">
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          flex={1}
          gap={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setFiltersOpen(true)}
            data-testid="filter-button"
            sx={{
              backgroundColor: isFilterActive ? '#FFD8EB' : 'white',
            }}
          >
            <FilterAlt sx={{ marginRight: '10px' }} />
            <Typography fontWeight="bold">{t('Filter')}</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('new-post')}
            data-testid="new-post-button"
            startIcon={<AddIcon />}
          >
            <Typography fontWeight="bold" noWrap>
              {t('New Post')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <PostsFilter
        setPage={setPage}
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        advancedFilters
      />
      <PostsTable
        posts={posts}
        total={total}
        limit={limit}
        setLimit={setLimit}
        page={page}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        onPageChange={onPageChange}
        fromContentAdmin={true}
      />
    </Stack>
  );
};

export default PostsTab;
