import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';

const mentionStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey !== null) {
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === 'MENTION') {
        return true;
      }
    }
    return false;
  }, callback);
};

// Component to render mentions correctly
const MentionComponent = (props: any) => {
  const { tag, tagId } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <span
      data-type="mention"
      className="mention"
      data-label={tag}
      data-id={tagId}
      contentEditable={false} // Prevents user from modifying mention text
      style={{ color: 'blue', fontWeight: 'bold' }}
    >
      {props.children}
    </span>
  );
};

// New strategy to detect the literal "data-id" attribute text in the content.
const hiddenDataIdStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  const text = contentBlock.getText();
  // This regex matches:
  // - "data-id" alone,
  // - "data-id=" with or without spaces,
  // - and continues with an optional opening quote and any characters until an optional closing quote.
  const regex = /data-id(?:\s*=\s*"?[^"]*"?){0,1}/g;
  let match;
  while ((match = regex.exec(text)) !== null) {
    callback(match.index, match.index + match[0].length);
  }
};

// Component that renders the sensitive text invisibly.
const HiddenDataIdComponent = (props: any) => {
  // Using transparent color hides the text but preserves its space.
  return <span style={{ color: 'transparent' }}>{props.children}</span>;
};

// Export the Composite Decorator
const MentionDecorator = new CompositeDecorator([
  {
    strategy: mentionStrategy,
    component: MentionComponent,
  },
  {
    strategy: hiddenDataIdStrategy,
    component: HiddenDataIdComponent,
  },
]);

export default MentionDecorator;
