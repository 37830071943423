import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { PostLifecycleState, UserPlan } from '../../__generated__/graphql';
import MyCalendar from '../../components/Calendar/Calendar';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import { PostProvider } from '../../context/PostContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { GET_CURRENT_BRAND_POSTS } from '../../graphql/queries';
import { CalendarEvent, ContentBoardTabs, getLifecycleStatesFromTab } from '../../types';
import { DiscardPost } from '../../components/PostComponents/DiscardPost';
import { PostsFilterContext } from '../../context/PostsFilterContext';
import { DeviceContext } from '../../context/DeviceContext';
import { useTranslation } from 'react-i18next';

const today = new Date();
const weekDay = today.toLocaleDateString('en-US', { weekday: 'long' });
const dateString = today.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const Calendar = () => {
  const { t } = useTranslation();
  const [openedPostId, setOpenedPost] = useState<string | null>(null);
  const [openNewPost, setOpenNewPost] = useState<boolean>(false);
  const [newPostSchedule, setNewPostSchedule] = useState<Date | null>(null);
  const [deletePostId, setDeletePostId] = useState<string | null>();

  const defaultDateTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  defaultDateTo.setDate(defaultDateTo.getDate() + 7);

  const defaultDateFromTimestamp = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
  ).setDate(-6);
  const defaultDateFrom = new Date(defaultDateFromTimestamp);

  const [dateTo, setDateTo] = useState<Date>(defaultDateTo);
  const [dateFrom, setDateFrom] = useState<Date>(defaultDateFrom);

  const { currentTabs, filter, setFilter } = useContext(PostsFilterContext);
  const { isMobile } = useContext(DeviceContext);

  const { user } = useContext(AuthContext);
  const brandSelected = user?.brandSelected;

  const handleChangeTab = (tabs: ContentBoardTabs[]) => {
    setFilter({
      ...filter,
      lifecycleStates: getLifecycleStatesFromTab(tabs),
    });
  };

  const { data, loading, refetch } = useQuery(GET_CURRENT_BRAND_POSTS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...filter,
        dateFrom,
        dateTo,
      },
    },
  });

  const handlePostEditorClose = () => {
    refetch();
    setOpenNewPost(false);
    setOpenedPost(null);
  };

  const events: CalendarEvent[] = (data?.currentBrandPosts.posts || [])
    .filter((post) => post.postedAt || post.schedule)
    .reduce((uniquePosts, post) => {
      if (!uniquePosts.some((p) => p.id === post._id)) {
        uniquePosts.push({
          id: post._id,
          date:
            post.lifecycleState === PostLifecycleState.Posted
              ? post.postedAt
              : post.schedule,
          lifecycleState: post.lifecycleState!,
          lifecycleStateDisplay: post.lifecycleStateDisplay!,
          postingAccountName: post.brandName || '',
          avatar: post.brand?.account?.avatar || '',
          backgroundColor: post.brand?.backgroundColor || '',
          brandId: post.brandId || '',
          title: post?.title || '',
          isIdea: post.isIdea || false,
          preview: post.content?.preview || '',
        });
      }
      return uniquePosts;
    }, [] as CalendarEvent[]);

  return (
    <>
      {loading ? <LinearProgress /> : <Box height={4} />}
      <Stack
        direction="column"
        p={isMobile ? '15px 25px' : '40px 25px'}
        gap={1}
        maxWidth={'1800px'}
        alignSelf={'center'}
        marginX={'auto'}
      >
        <div>
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {t('Calendar')}
          </Typography>
          <Typography variant="subtitle1">{`${weekDay} - ${dateString}`}</Typography>
        </div>
        <Tabs
          selectedTabs={currentTabs}
          setSelectedTabs={handleChangeTab}
          showTabApproved={!!data?.currentBrandPosts.havePostsApproved}
          showTabInbox={!user?.plan?.includes(UserPlan.Starter)}
        />
      </Stack>
      <Box
        marginBottom={{
          xs: 10,
          sm: 0,
        }}
        maxWidth={'1800px'}
        alignSelf={'center'}
        marginX={'auto'}
      >
        <MyCalendar
          events={events || []}
          handleEditPost={setOpenedPost}
          handleOpenNewPost={(date) => {
            setNewPostSchedule(date);
            setOpenNewPost(true);
          }}
          handleDiscard={(postId) => {
            setDeletePostId(postId);
          }}
          handleDateRangeChange={(from, to) => {
            setDateFrom(from);
            setDateTo(to);
          }}
          draggable={true}
        />
      </Box>

      {deletePostId && (
        <DiscardPost postId={deletePostId} onCloseModal={() => setDeletePostId(null)} />
      )}
      {(!!openedPostId || openNewPost) && (
        <WebSocketProvider postId={openedPostId}>
          <PostProvider
            postId={openedPostId}
            newPostOptions={{
              brandId: brandSelected?._id,
              brand: brandSelected,
              schedule: newPostSchedule,
            }}
          >
            <PostEditorModal onClose={handlePostEditorClose} />
          </PostProvider>
        </WebSocketProvider>
      )}
    </>
  );
};

export default Calendar;
