import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  TextField,
  Typography,
  Dialog,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import enLocale from 'dayjs/locale/en';
import esLocale from 'dayjs/locale/es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../languages/init';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface Props {
  date?: Date;
  maxDate?: Date;
  withTimestamp?: boolean;
  open: boolean;
  anchorElement?: HTMLElement | null;
  onClose?: () => void;
  onSubmit?: (date: Date) => void;
  disablePast?: boolean;
  primaryActionTitle: string;
  transformOrigin?: string;
  isMobile?: boolean;
  tentative?: boolean;
  showOnlyDateSelected?: boolean;
  showWarning?: boolean;
}

const DatePicker = (props: Props) => {
  const today = new Date();
  today.setHours(today.getHours() + 1);
  const {
    date: defaultDate,
    open,
    anchorElement,
    onClose,
    onSubmit,
    disablePast = true,
    transformOrigin = 'center bottom',
    isMobile,
    withTimestamp = true,
    tentative = false,
    showOnlyDateSelected = false,
    showWarning = false,
  } = props;
  let currentDate = dayjs(defaultDate || today);

  const [date, setDate] = useState<Dayjs>(currentDate);
  const [hour, setHour] = useState<string>(currentDate.hour().toString());
  const [minute, setMinute] = useState<string>(currentDate.minute().toString());

  const disabled = disablePast
    ? dayjs(date.toDate().setHours(parseInt(hour), parseInt(minute))).isBefore(dayjs())
    : false;

  const disabledMaxDate =
    !tentative &&
    (props.maxDate
      ? dayjs(date.toDate().setHours(parseInt(hour), parseInt(minute))).isAfter(
          dayjs(props.maxDate),
        )
      : false);

  function onHourChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value);

    if (isNaN(value)) {
      setHour('0');
      return;
    }

    if (value > 23) {
      setHour('23');
      return;
    }

    if (value < 0) {
      setHour('0');
      return;
    }

    setHour(value.toString());
  }

  function onMinuteChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value);

    if (isNaN(value)) {
      setMinute('0');
      return;
    }

    if (value > 59) {
      setMinute('59');
      return;
    }

    if (value < 0) {
      setMinute('0');
      return;
    }

    setMinute(value.toString());
  }

  function handleSubmit() {
    const datetime = date!.toDate().setHours(parseInt(hour), parseInt(minute));
    onSubmit?.(new Date(datetime));
  }

  const timeInputContainerStyles = {
    marginBottom: 3,
    marginLeft: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  const actionButtonsContainerStyles = {
    textAlign: 'right',
    paddingBottom: '10px',
    paddingRight: '10px',
  };

  const customLocale =
    i18n.language === 'en'
      ? { ...enLocale, weekStart: 1 }
      : { ...esLocale, weekStart: 1 };

  dayjs.locale('en-custom', customLocale);

  const { t } = useTranslation();

  return showOnlyDateSelected ? (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-paper': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '320px',
          padding: 2,
        },
      }}
    >
      <ClickAwayListener onClickAway={() => onClose?.()}>
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-custom'}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={date}
              onChange={(value) => setDate(value!)}
              minDate={date}
              maxDate={date}
              disablePast={disablePast}
              showDaysOutsideCurrentMonth
              renderInput={(params: any) => <TextField {...params} />}
              views={['day']}
            />
          </LocalizationProvider>
          {withTimestamp && (
            <Box sx={timeInputContainerStyles}>
              <TextField
                value={hour}
                onChange={onHourChange}
                sx={{ width: '75px' }}
                size="small"
                label={t('Hour')}
              ></TextField>
              <Typography sx={{ paddingX: '2px' }}> : </Typography>
              <TextField
                value={minute}
                onChange={onMinuteChange}
                sx={{ width: '75px' }}
                size="small"
                label={t('Min')}
              ></TextField>
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Typography variant="caption" sx={{ color: 'warning.main', marginRight: 1 }}>
              {t('You are about to change the schedule date of this post')}
            </Typography>
            <WarningIcon sx={{ color: 'warning.main' }} />
          </Box>
          <Box sx={actionButtonsContainerStyles}>
            <Button sx={{ color: 'text.primary' }} onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button disabled={disabled || disabledMaxDate} onClick={handleSubmit}>
              {props.primaryActionTitle}
            </Button>

            {disabledMaxDate && (
              <Typography
                paddingX={2}
                fontSize={12}
                maxWidth={'300px'}
                textAlign="center"
              >
                {t('The maximum range to schedule is 30 days')}
              </Typography>
            )}
          </Box>
        </>
      </ClickAwayListener>
    </Dialog>
  ) : (
    <Popper
      open={open}
      anchorEl={anchorElement}
      placement={isMobile ? 'top' : 'left-end'}
      role={undefined}
      transition
      disablePortal
      sx={{ zIndex: 100 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin }}>
          <Paper
            sx={{
              my: 1,
              borderRadius: '8px',
              boxShadow:
                '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
            }}
          >
            <ClickAwayListener onClickAway={() => onClose?.()}>
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'en-custom'}
                >
                  <Box>
                    {/* Warning Message */}
                    {showWarning && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          color: '#D9822B', // Warning color
                          fontFamily: 'Raleway, sans-serif',
                          fontWeight: 500,
                          fontSize: '0.85rem',
                          lineHeight: '150%',
                          letterSpacing: '0.04em',
                          borderRadius: '4px',
                          padding: '8px',
                          marginBottom: 1, // Space before calendar
                          width: '320px',
                        }}
                      >
                        <WarningAmberOutlinedIcon sx={{ fontSize: 20 }} />
                        <Typography>
                          {t(
                            'This date does not schedule the post automatically, but it helps you plan when to publish it.',
                          )}
                        </Typography>
                      </Box>
                    )}

                    {/* Date Picker */}
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      value={date}
                      onChange={(value) => setDate(value!)}
                      maxDate={props.maxDate ? dayjs(props.maxDate) : null}
                      disablePast={disablePast}
                      showDaysOutsideCurrentMonth
                      renderInput={(params: any) => <TextField {...params} />}
                      views={['day']}
                    />
                  </Box>
                </LocalizationProvider>
                {withTimestamp && (
                  <Box sx={timeInputContainerStyles}>
                    <TextField
                      value={hour}
                      onChange={onHourChange}
                      sx={{ width: '75px' }}
                      size="small"
                      label={t('Hour')}
                    ></TextField>
                    <Typography sx={{ paddingX: '2px' }}> : </Typography>
                    <TextField
                      value={minute}
                      onChange={onMinuteChange}
                      sx={{ width: '75px' }}
                      size="small"
                      label={t('Min')}
                    ></TextField>
                  </Box>
                )}

                <Box sx={actionButtonsContainerStyles}>
                  <Button
                    sx={{ color: 'text.primary', fontWeight: 400 }}
                    onClick={onClose}
                  >
                    {showWarning ? t('CANCEL DATE') : t('Cancel')}
                  </Button>
                  <Button disabled={disabled || disabledMaxDate} onClick={handleSubmit}>
                    {props.primaryActionTitle}
                  </Button>

                  {disabledMaxDate && (
                    <Typography
                      paddingX={2}
                      fontSize={12}
                      maxWidth={'300px'}
                      textAlign="center"
                    >
                      {t('The maximum range to schedule is 30 days')}
                    </Typography>
                  )}
                </Box>
              </>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default DatePicker;
