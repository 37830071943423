import {
  Stack,
  Typography,
  Paper,
  IconButton,
  Button,
  MenuItem,
  Badge,
} from '@mui/material';
import {
  Mention,
  MentionInput,
  UserOrBrand,
  UserRole,
} from '../../__generated__/graphql';
import Avatar from '../Avatar/Avatar';
import { timeAgoWithDate } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef, useContext } from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  Modifier,
  CompositeDecorator,
  SelectionState,
  ContentBlock,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from '@apollo/client';
import ReactDOM from 'react-dom';
import EmojiSelector from '../SelectorComponents/EmojiSelector';
import { UPDATE_COMMENT, DELETE_COMMENT } from '../../graphql/mutations';
import { PostContext } from '../../context/PostContext';
import { AuthContext } from '../../context/AuthContext';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

// Helper to find mention entities
function findMentionEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION'
    );
  }, callback);
}

const MentionSpan = (props: any) => (
  <span style={{ color: 'blue', fontWeight: 'bold' }}>{props.children}</span>
);

interface CommentItemProps {
  userOrBrand?: UserOrBrand | null;
  avatar: string | null;
  text: string;
  time: Date;
  name: string | null;
  mentions: Mention[];
  commentId: string;
  isLinkInbox?: boolean;
  isLink?: boolean;
  usersList?: any[];
  setFixedWidth?: boolean;
}

const CommentItem = ({
  userOrBrand,
  avatar,
  text,
  time,
  name,
  mentions,
  commentId,
  isLinkInbox = false,
  isLink = false,
  usersList,
  setFixedWidth = false,
}: CommentItemProps) => {
  const { t, i18n } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef<Editor | null>(null);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);
  const [updatedText, setUpdatedText] = useState(text);
  const [updatedMentions, setUpdatedMentions] = useState<MentionInput[]>(mentions);

  // --- Mention Autocomplete States ---
  const [mentionOpen, setMentionOpen] = useState(false);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const mentionListRef = useRef<HTMLDivElement>(null);

  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  // --- Emoji Selector States ---
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const emojiAnchorRef = useRef<HTMLButtonElement>(null);

  // Create an EditorState from the provided text and mentions.
  const createEditorStateFromProps = (
    text: string,
    mentions: MentionInput[],
  ): EditorState => {
    let contentState = ContentState.createFromText(text);
    contentState.getBlockMap().forEach((block) => {
      const blockKey = block?.getKey();
      const blockText = block?.getText();
      mentions.forEach((mention) => {
        const mentionText = `@${mention.name}`;
        let startIndex = 0;
        while (true) {
          const index = blockText?.indexOf(mentionText, startIndex);
          if (index === -1) break;
          // Create a mention entity with a flat structure
          contentState = contentState.createEntity('MENTION', 'IMMUTABLE', {
            user: { display: mention.name, id: mention.userId },
          });
          const entityKey = contentState.getLastCreatedEntityKey();
          if (blockKey !== undefined && index !== undefined) {
            const selection = SelectionState.createEmpty(blockKey).merge({
              anchorOffset: index,
              focusOffset: index + mentionText.length,
            }) as SelectionState;
            contentState = Modifier.applyEntity(contentState, selection, entityKey);
            startIndex = index + mentionText.length;
          }
        }
      });
    });
    const decorator = new CompositeDecorator([
      { strategy: findMentionEntities, component: MentionSpan },
    ]);
    return EditorState.createWithContent(contentState, decorator);
  };

  const [editorState, setEditorState] = useState<EditorState>(() =>
    createEditorStateFromProps(updatedText, mentions),
  );

  useEffect(() => {
    setUpdatedText(text);
    setUpdatedMentions(
      mentions.map((m: any) => ({
        name: typeof m.name === 'object' ? m.name.name : m.name,
        userId: m.userId,
      })),
    );
  }, [text, mentions]);

  useEffect(() => {
    if (!isEditing) {
      setEditorState(createEditorStateFromProps(updatedText, updatedMentions));
    }
  }, [updatedMentions, isEditing, updatedText]);

  const handleEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const currentText = newEditorState.getCurrentContent().getPlainText();
    const match = /@(\S*)$/.exec(currentText);
    if (match) {
      const searchText = match[1].toLowerCase();
      const newFiltered = usersList?.filter((u: any) =>
        u.display.toLowerCase().includes(searchText),
      );
      setFilteredUsers(newFiltered || []);
      setVisibleCount(10);
      setMentionOpen(true);
    } else {
      setMentionOpen(false);
    }
  };

  useEffect(() => {
    if (!mentionOpen) return;
    const sel = window.getSelection();
    if (!sel || sel.rangeCount === 0) return;
    const range = sel.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    if (rect) {
      const mentionHeight = mentionListRef.current?.offsetHeight || 200;
      setMentionPosition({
        top: rect.top + window.scrollY - mentionHeight - 8,
        left: rect.left + window.scrollX,
      });
    }
  }, [mentionOpen, filteredUsers]);

  // Inserts a mention entity using the flat structure.
  const handleInsertMention = (user: { id: string; display: string }) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const blockKey = selection.getStartKey();
    const blockText = content.getBlockForKey(blockKey).getText();
    const atIndex = blockText.lastIndexOf('@', selection.getStartOffset());
    if (atIndex === -1) return;

    const mentionText = `@${user.display}`;
    const mentionRange = selection.merge({ anchorOffset: atIndex }) as SelectionState;

    let newContent = Modifier.replaceText(content, mentionRange, mentionText);
    newContent = newContent.createEntity('MENTION', 'IMMUTABLE', {
      user: { display: user.display, id: user.id },
    });
    const entityKey = newContent.getLastCreatedEntityKey();
    const updatedRange = mentionRange.merge({
      focusOffset: atIndex + mentionText.length,
    }) as SelectionState;
    newContent = Modifier.applyEntity(newContent, updatedRange, entityKey);

    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(newState, newContent.getSelectionAfter());
    setEditorState(EditorState.moveFocusToEnd(newState));
    setMentionOpen(false);
  };

  const handleMentionButtonClick = () => {
    if (mentionOpen) return;
    // Focus the editor so that selection is available.
    editorRef.current?.focus();
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Insert "@" at the current cursor position.
    const newContentState = Modifier.insertText(contentState, selection, '@');
    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    newEditorState = EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter(),
    );

    // Trigger the editor change which will open the mention portal if needed.
    handleEditorChange(newEditorState);
  };

  const handleMentionScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setVisibleCount((prev) => prev + 10);
    }
  };

  const escapeRegex = (str: string) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const renderDecoratedText = (input: string, mentions: MentionInput[]) => {
    if (!mentions || mentions.length === 0) return input;
    const escapedMentions = mentions.map((m) => escapeRegex(m.name || ''));
    const pattern = '@(?:' + escapedMentions.join('|') + ')';
    const regex = new RegExp(`(${pattern})`, 'g');
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(input)) !== null) {
      if (match.index > lastIndex) {
        parts.push(input.substring(lastIndex, match.index));
      }
      parts.push(<MentionSpan key={match.index}>{match[1]}</MentionSpan>);
      lastIndex = regex.lastIndex;
    }
    if (lastIndex < input.length) {
      parts.push(input.substring(lastIndex));
    }
    return parts;
  };

  const handleEmojiInputChange = (emoji: string) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContentState = Modifier.insertText(contentState, selection, emoji);
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    setEditorState(newEditorState);
    setEmojiComponentOpen(false);
    setTimeout(() => {
      // Optionally, refocus the editor after inserting the emoji.
    }, 0);
  };

  const handleSave = () => {
    const content = editorState.getCurrentContent();
    const extractedMentions = extractMentions(content);
    const cleanMentions = extractedMentions.map((m) => ({
      name: typeof m.name === 'object' && m.name !== null ? (m.name as any).name : m.name,
      userId: m.userId,
    }));

    try {
      updateComment({
        variables: {
          postId: postState?.post?._id || postState?.postRef?.current?._id || '',
          commentId,
          content: content.getPlainText(),
          mentions: cleanMentions,
        },
      }).finally(() => {
        postState?.refetch();
      });

      setUpdatedText(content.getPlainText());
      setUpdatedMentions(cleanMentions);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleCancel = () => {
    setEditorState(createEditorStateFromProps(text, mentions));
    setIsEditing(false);
  };

  // Handle deletion of a comment using the mutation.
  const handleDeleteComment = async () => {
    try {
      await deleteComment({
        variables: {
          postId: postState?.post?._id || postState?.postRef?.current?._id || '',
          commentId,
          allComments: false,
        },
      });
      postState?.refetch();
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  // Extracts mentions from the content and returns flat mention objects.
  const extractMentions = (content: ContentState): MentionInput[] => {
    const mentionsSet = new Map<string, MentionInput>();
    content.getBlockMap().forEach((block) => {
      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey !== null) {
            const entity = content.getEntity(entityKey);
            return entity.getType() === 'MENTION';
          }
          return false;
        },
        (start, end) => {
          const entityKey = block.getEntityAt(start);
          if (entityKey) {
            const entity = content.getEntity(entityKey);
            const data = entity.getData();
            if (data && data.user && data.user.display && data.user.id) {
              mentionsSet.set(data.user.id, {
                name: data.user.display,
                userId: data.user.id,
              });
            }
          }
        },
      );
    });
    return Array.from(mentionsSet.values());
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 2,
        width: '100%',
        borderRadius: 2,
        border: '1px solid #C1C1C1',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Avatar name={name || ''} avatar={avatar || undefined} />
        <Stack direction="row" alignItems="center" spacing={1} flex={1}>
          <Typography variant="body2" fontWeight="bold">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {(() => {
              const { formattedTime, isOldDate, isJustNow } = timeAgoWithDate(
                time,
                t,
                i18n.language,
              );
              if (isOldDate || isJustNow) return formattedTime;
              return i18n.language === 'en'
                ? `${formattedTime} ${t('ago')}`
                : `${t('ago')} ${formattedTime}`;
            })()}
          </Typography>
        </Stack>
        {!isEditing &&
          (user?.role.includes(UserRole.LimitedBrandManager) ||
            user?.role.includes(UserRole.BrandManager)) &&
          commentId && (
            <>
              <IconButton onClick={() => setIsEditing(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={handleDeleteComment}>
                <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
              </IconButton>
            </>
          )}
      </Stack>

      {isEditing ? (
        <>
          <Paper sx={{ p: 1, mt: 1 }} variant="outlined">
            <Stack direction="row" alignItems="center">
              <div style={{ flex: 1, width: '400px' }}>
                <Editor
                  editorState={editorState}
                  onChange={handleEditorChange}
                  ref={editorRef}
                />
              </div>
              <IconButton
                onClick={() => setEmojiComponentOpen(true)}
                ref={emojiAnchorRef}
              >
                <InsertEmoticon />
              </IconButton>
              <Badge
                badgeContent="New"
                color="primary"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <IconButton onClick={handleMentionButtonClick}>
                  <AlternateEmailIcon />
                </IconButton>
              </Badge>
            </Stack>
          </Paper>
          <Stack direction="row" spacing={1} mt={1}>
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              {t('Save')}
            </Button>
            <Button onClick={handleCancel} startIcon={<CancelIcon />}>
              {t('Cancel')}
            </Button>
          </Stack>
          {emojiComponentOpen && (
            <EmojiSelector
              open={emojiComponentOpen}
              anchorElement={emojiAnchorRef.current}
              onClose={() => setEmojiComponentOpen(false)}
              onChange={handleEmojiInputChange}
            />
          )}
          {mentionOpen &&
            ReactDOM.createPortal(
              <Paper
                ref={mentionListRef}
                style={{
                  position: 'absolute',
                  top: mentionPosition.top,
                  left: mentionPosition.left,
                  zIndex: 9999,
                  maxHeight: 200,
                  width: 200,
                  overflowY: 'auto',
                  border: '1px solid #ccc',
                }}
                onScroll={handleMentionScroll}
                onWheel={(e) => e.stopPropagation()} // Prevent desktop scroll bubbling
                onTouchMove={(e) => e.stopPropagation()} // Prevent mobile scroll bubbling
              >
                {filteredUsers.slice(0, visibleCount).map((user: any) => (
                  <MenuItem
                    key={user.id}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleInsertMention(user);
                    }}
                  >
                    {user.display}
                  </MenuItem>
                ))}
              </Paper>,
              document.body,
            )}
        </>
      ) : (
        <Typography
          variant="body2"
          sx={{
            ml: '0px',
            mt: 0,
            wordBreak: 'break-word',
          }}
        >
          {renderDecoratedText(updatedText, updatedMentions)}
        </Typography>
      )}
    </Paper>
  );
};

export default CommentItem;
