import { useMutation } from '@apollo/client';
import { ReactNode, createContext, useContext, useState } from 'react';
import {
  ExtractThumbnailDataMutation,
  Post,
  PostContentMedia,
  Thumbnail,
} from '../__generated__/graphql';
import { EXTRACT_THUMBNAIL_DATA } from '../graphql/mutations';
import usePost, { UsePostReturnType } from '../hooks/usePost';
import { extractUrlsFromText, uploadThumbnailMedia } from '../utils';
import { AuthContext } from './AuthContext';
import { usePostEditor } from '../hooks/usePostEditor';
import { EditorState } from 'draft-js';
interface PostProviderProps {
  children: ReactNode;
  postId: string | null;
  newPostOptions?: Partial<Post>;
  insightContent?: string;
  offline?: boolean;
  fetchThumbnail?: (thumbnail: Thumbnail) => void;
  thumbnailData?: Thumbnail | null;
  setLoadingThumbnail?: (loading: boolean) => void;
  setErrorThumbnail?: (error: boolean) => void;
  loadingThumbnail?: boolean;
  isLink?: boolean;
  isLinkInbox?: boolean;
  alreadyFetchedThumbnail?: boolean;
}

interface PostContextType {
  postState?: UsePostReturnType;
  editorState?: EditorState;
  setEditorState?: (editorState: EditorState) => void;
  handleChange?: (
    editorState: EditorState,
    pastedText?: boolean,
    bold?: boolean,
  ) => void | string;
  handlePastedText?: (
    text: string,
    html: string,
    editorState: EditorState,
  ) => 'handled' | 'not-handled';
}

export const PostContext = createContext<PostContextType>({});

export const PostProvider = ({
  children,
  postId,
  insightContent,
  fetchThumbnail,
  newPostOptions = {},
  thumbnailData,
  setErrorThumbnail,
  setLoadingThumbnail,
  loadingThumbnail: loadingThumbnailProp,
  isLink = false,
  isLinkInbox = false,
  alreadyFetchedThumbnail = false,
}: PostProviderProps) => {
  const { user } = useContext(AuthContext);
  const postState = usePost(postId, newPostOptions, isLink, isLinkInbox);
  const [extractMetaTags] = useMutation(EXTRACT_THUMBNAIL_DATA);
  const [thumbnails, setThumbnails] = useState<Map<string, ExtractThumbnailDataMutation>>(
    new Map(),
  );
  const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState<string>();

  const handleLoadingThumbnail =
    setLoadingThumbnail || postState.handlers.handleLoadingThumbnail;
  const handleErrorThumbnail =
    setErrorThumbnail || postState.handlers.handleErrorThumbnail;

  async function fetchMetaTags(url: string) {
    if (thumbnails.has(url)) {
      return thumbnails.get(url)?.extractThumbnailData;
    }

    handleLoadingThumbnail(true);

    const response = await extractMetaTags({
      variables: { url },
      onCompleted: (data) => {
        setThumbnails(new Map(thumbnails).set(url, data));
        if (!data?.extractThumbnailData) {
          handleErrorThumbnail(true);
          postState.handlers.handleShowErrorThumbnail(true);
        }

        handleLoadingThumbnail(false);
        return data;
      },
      onError: () => {
        handleErrorThumbnail(true);
        postState.handlers.handleShowErrorThumbnail(true);
        handleLoadingThumbnail(false);
      },
    });

    return response.data?.extractThumbnailData || null;
  }

  async function handleThumbnail(textContent: string, isPaste: boolean = false) {
    if (!!postState.post.content?.media?.length || alreadyFetchedThumbnail) return;

    if (
      postState.post.content?.thumbnail?.title &&
      postState.status.showThumbnail &&
      isPaste
    ) {
      return;
    }

    if (!user) return;
    const urls = extractUrlsFromText(textContent, isPaste, user);
    const url = urls[0] as string;
    setCurrentThumbnailUrl(url);

    await new Promise((resolve) => setTimeout(resolve, 1500));
    if (!url || urls.length === 0 || (url !== currentThumbnailUrl && currentThumbnailUrl))
      return;

    if (!postState.post.content?.thumbnail?.title && !thumbnailData?.title) {
      if (postState.status.thumbnailData?.source === url) {
        const { __typename: _, media, ...thumbnailData } = postState.status.thumbnailData;
        const { __typename: __, ...thumbnailMedia } = media as PostContentMedia;
        postState.handlers.handleUploadThumbnail({
          media: thumbnailMedia,
          ...thumbnailData,
        });
        return;
      }

      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');
        const thumnailData = {
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        };

        if (fetchThumbnail) fetchThumbnail(thumnailData);
        else postState.handlers.handleUploadThumbnail(thumnailData);
      }
      return;
    }

    if (
      !postState.status.showThumbnail &&
      url !== postState.post.content?.thumbnail?.source
    ) {
      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');
        postState.handlers.handleUploadThumbnail({
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        });
      }
      return;
    }

    if (postState.status.showThumbnail && postState.post.content?.thumbnail?.title) {
      return;
    }

    if (
      typeof url === 'string' &&
      typeof postState.post.content?.thumbnail?.source === 'string' &&
      url === postState.post.content?.thumbnail?.source
    ) {
      postState.handlers.handleShowThumbnail();
      return;
    }
    handleChange(editorState, false);
  }

  const { editorState, setEditorState, handleChange, handlePastedText } = usePostEditor({
    postState,
    insightContent: insightContent || '',
    postRef: postState?.postRef,
    handleThumbnail,
  });

  if (!postState || !editorState)
    return <PostContext.Provider value={{}}>{children}</PostContext.Provider>;

  return (
    <PostContext.Provider
      value={{
        postState,
        editorState,
        setEditorState,
        handleChange,
        handlePastedText,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
