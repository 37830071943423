import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { PostContext } from '../../context/PostContext';
import { linkedinEscapeChars, parsePostContentToLinkedinCommentary } from '../../utils';
import { useTranslation } from 'react-i18next';

const CharCounter = () => {
  const { postState } = useContext(PostContext);
  const theme = useTheme();
  const postRef = postState?.postRef;

  const content = postRef?.current?.content?.body || postState?.post.content?.body || '';
  const { text, totalStrongAndEmCount } = parsePostContentToLinkedinCommentary(content);
  const charsCount = linkedinEscapeChars(text).length + totalStrongAndEmCount;
  //const charsCount = totalCharacters;

  let charsCountColor = theme.palette.text.secondary;
  if ((charsCount || 0) > 2500 && (charsCount || 0) < 3000)
    charsCountColor = theme.palette.warning.main;
  else if ((charsCount || 0) > 3000) charsCountColor = theme.palette.error.main;
  const { t } = useTranslation();

  const progress = Math.round(((charsCount || 0) / 3000) * 100);

  if ((charsCount || 0) > 2750) {
    return (
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 1 }}>
        {charsCount! > 3000 && (
          <Typography color={theme.palette.error.main}>
            {t('You have exceeded the character limit')}
          </Typography>
        )}
        <Typography fontWeight="700" color={charsCountColor}>
          {3000 - charsCount!}
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={`${charsCount || 0} / 3000`} enterTouchDelay={0}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) => theme.palette.grey[200],
            }}
            size={15}
            thickness={10}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
              color: charsCountColor,
            }}
            size={15}
            thickness={10}
            value={progress}
          />
        </>
      </Box>
    </Tooltip>
  );
};

export default CharCounter;
