import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popper,
  Stack,
  TextField,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TagsType } from '../../__generated__/graphql';
import { PostContext } from '../../context/PostContext';
import { GET_ALL_BRANDS_TO_TAG } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import { UPDATE_BRAND_FREQUENT_TAGS } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';

interface Props {
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  onChange: (tag: string, tagId: string) => void;
}

const TaggerSelector = ({ open, anchorElement, onClose, onChange }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [isValidProfile, setIsValidProfile] = useState(false);
  const { setErrorMessage } = useContext(SnackbarContext);
  const [isVerifying, setIsVerifying] = useState(false);
  const [invalidUrl, setIsInValidUrl] = useState(false);
  const [foundProfile, setFoundProfile] = useState<{
    name: string;
    avatar?: string;
    tagId?: string;
    type?: TagsType;
  } | null>(null);
  const { postState } = useContext(PostContext);
  const { t } = useTranslation();

  const { data: usersToTagData, loading } = useQuery(GET_ALL_BRANDS_TO_TAG, {
    variables: {
      search: debouncedSearchTerm,
      postId: postState?.post._id || null,
      brandId: postState?.post.brand?._id || postState?.postRef?.current?.brandId || null,
    },
    fetchPolicy: 'network-only',
    skip: !open,
  });

  const [fetchProfile] = useLazyQuery(GET_ALL_BRANDS_TO_TAG);
  const [updateBrandFrequentTags] = useMutation(UPDATE_BRAND_FREQUENT_TAGS);

  const usersToTag = usersToTagData?.getAllBrandsToTag;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setIsVerifying(false);
    setFoundProfile(null);
    setShowUrlInput(false);
    setIsInValidUrl(false);
    setLinkedinUrl('');
  };

  const handleTagClick = (tag: string, tagId: string) => {
    onChange(tag, tagId);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const users = (usersToTag || []).filter(
    (user) => user && user.type === TagsType.LinkedinUser,
  );

  const companies = (usersToTag || []).filter(
    (user) => user && user.type === TagsType.LinkedinCompany,
  );
  const visibleUsers = showAllUsers ? users : users.slice(0, 3);
  const visibleCompanies = showAllCompanies ? companies : companies.slice(0, 3);

  const handleUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value.trim();
    setLinkedinUrl(url);

    // 1) Basic validation – check if it includes "company/" or "school/"
    //    If neither is found, it's invalid for your use case.
    const hasCompany = url.includes('linkedin.com/company/');
    const hasSchool = url.includes('linkedin.com/school/');

    if (!hasCompany && !hasSchool) {
      setFoundProfile(null);
      setIsInValidUrl(true);
      return;
    }

    setIsVerifying(true);
    setIsInValidUrl(false);
    setFoundProfile(null);
    setIsValidProfile(false);

    // 2) Extract the portion after "company/" or "school/" but before the next slash
    let handlePart = '';
    if (hasCompany) {
      // Example: "https://www.linkedin.com/company/amazon-web-services/posts/?feedView=all"
      //          split('linkedin.com/company/') => ["https://www.", "amazon-web-services/posts/?feedView=all"]
      handlePart = url.split('linkedin.com/company/')[1] ?? '';
    } else {
      handlePart = url.split('linkedin.com/school/')[1] ?? '';
    }

    // 3) Now split again to stop at the next slash (if it exists)
    //    e.g. "amazon-web-services/posts/?feedView=all" => ["amazon-web-services", "posts/?feedView=all"]
    //    We'll take index [0] => "amazon-web-services"
    handlePart = handlePart.split('/')[0];

    // If there's nothing after the slash, user typed something weird, so bail out
    if (!handlePart) {
      setIsVerifying(false);
      setIsInValidUrl(true);
      return;
    }

    // 4) Call your fetchProfile with the extracted handle
    try {
      const { data } = await fetchProfile({
        variables: {
          search: handlePart,
          postId: postState?.post._id || null,
        },
        fetchPolicy: 'network-only',
      });

      if (data?.getAllBrandsToTag && data.getAllBrandsToTag.length > 0) {
        const profile = data.getAllBrandsToTag[0]; // assume first
        setFoundProfile({
          name: profile?.name ?? '',
          avatar: profile?.avatar ?? '',
          tagId: profile?.linkedinUrn ?? '',
          type: profile?.type ?? TagsType.LinkedinUser,
        });
        setIsValidProfile(true);
      }
    } finally {
      setIsVerifying(false);
    }
  };

  const handleAddUrl = async () => {
    if (isValidProfile) {
      handleTagClick(foundProfile?.name!, foundProfile?.tagId!);
      const response = await updateBrandFrequentTags({
        variables: {
          brandId: postState?.post.brand?._id || '',
          tag: {
            addedOn: new Date(),
            name: foundProfile?.name!,
            type: foundProfile?.type!,
            linkedinUrn: foundProfile?.tagId!,
            avatar: foundProfile?.avatar!,
          },
        },
      });
      if (!response.data?.updateBrandFrequentTags?.success) {
        setErrorMessage('Failed to add profile to frequent tags');
        return;
      }
      setShowUrlInput(false);
      setLinkedinUrl('');
      setFoundProfile(null);
      setIsValidProfile(false);
      setSearchTerm('');
      setDebouncedSearchTerm('');
      onClose();
    }
  };

  const noResultsFound = (
    <Stack alignItems="center" spacing={2}>
      {!isVerifying && !foundProfile && !showUrlInput && (
        <Typography
          sx={{
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '143%',
            textAlign: 'center',
            color: '#808080',
          }}
        >
          {t('Profile not found')}
        </Typography>
      )}

      {!showUrlInput ? (
        <Box
          sx={{
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
            padding: '8px 16px',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'center',
          }}
          onClick={() => setShowUrlInput(true)}
        >
          <Typography
            sx={{
              fontFamily: 'Raleway, sans-serif',
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '143%',
              color: '#1A73E8',
            }}
          >
            {t('Search with the URL')}
          </Typography>
        </Box>
      ) : (
        <>
          <Stack direction="row" spacing={1} alignItems="center" width="100%">
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('URL LinkedIn profile')}
              value={linkedinUrl}
              onChange={handleUrlChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '36px',
                  borderRadius: '8px',
                },
              }}
            />
          </Stack>

          {/* Show loader when verifying */}
          {isVerifying && (
            <Stack alignItems="center" spacing={1} mt={2}>
              <CircularProgress size={24} />
              <Typography
                sx={{
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '143%',
                  color: '#808080',
                }}
              >
                {t('Verifying profile...')}
              </Typography>
            </Stack>
          )}

          {/* Show found profile */}
          {!isVerifying && foundProfile && (
            <Stack spacing={1} mt={2}>
              <Box
                sx={{
                  cursor: 'pointer', // Make the whole row clickable
                  '&:hover': { backgroundColor: '#f5f5f5' }, // Add hover effect
                  borderRadius: '8px', // Add rounded edges for better UX
                  padding: '8px', // Add padding to make the hover area more visible
                  transition: 'background-color 0.2s ease-in-out', // Smooth transition effect
                }}
                onClick={handleAddUrl}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar src={foundProfile.avatar} sx={{ width: 32, height: 32 }} />
                  <Typography
                    sx={{
                      fontFamily: 'Raleway, sans-serif',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '143%',
                      color: '#000',
                    }}
                  >
                    @{foundProfile.name}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          )}

          {!isVerifying && !foundProfile && linkedinUrl && !invalidUrl && (
            <Stack direction="row" alignItems="center" spacing={1} mt={2}>
              <Typography
                sx={{
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#D32F2F',
                }}
              >
                {t('Profile not validated. Check the URL or try again.')}
              </Typography>
            </Stack>
          )}
          {invalidUrl && linkedinUrl !== '' && (
            <Stack direction="row" alignItems="center" spacing={1} mt={2}>
              <Typography
                sx={{
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#D32F2F',
                }}
              >
                {t('Insert a valid company or school LinkedInUrl')}
              </Typography>
            </Stack>
          )}
          {!isVerifying && linkedinUrl === '' && !foundProfile && (
            <Stack alignItems="center" spacing={1} mt={2} textAlign="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  sx={{
                    fontFamily: 'Raleway, sans-serif',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#808080',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {t(
                    '⚠️ You can only add companies and universities using their LinkedIn URL.',
                  )}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 700, // Bold
                  fontSize: '12px',
                  color: '#505050',
                }}
              >
                {t('Personal profiles are not supported yet.')}
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorElement}
      sx={{ zIndex: 'modal', maxHeight: '450px' }}
      placement="bottom"
    >
      <ClickAwayListener
        mouseEvent="onMouseDown" // or "onClick"
        touchEvent="onTouchStart" // or "onTouchEnd"
        onClickAway={(event) => {
          if (window.getSelection()?.type === 'Range') {
            return;
          }
          onClose();
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: 1,
            width: '350px',
            height: '450px',
            overflowY: 'auto',
            direction: 'column',
            gap: 1,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pb={1}
          >
            <Typography fontWeight="bold" fontSize={14}>
              {t('Tag a user')}
            </Typography>
            {showUrlInput && (
              <Button onClick={() => setShowUrlInput(false)}>
                <SearchIcon />
              </Button>
            )}
          </Stack>

          {!showUrlInput && (
            <TextField
              type="text"
              autoFocus
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={t('Search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '100%',
                '.MuiInputBase-root': {
                  height: '40px',
                },
              }}
            />
          )}

          {loading && (
            <CircularProgress
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginX: 'auto',
                height: '100%',
                width: '100%',
              }}
            />
          )}
          {/* {!loading && !searchTerm && (
            <Alert severity={'info'} icon={false}>
              {t(
                'You can tag brands connected to magnettu and some public LinkedIn pages',
              )}
            </Alert>
          )} */}
          {!loading && !companies.length && !users.length && noResultsFound}
          {!loading && (!!users.length || !!companies.length) && (
            <Box>
              {!!users.length && !companies.length}
              <Typography variant="subtitle1" fontWeight={700} mt={1}>
                {t('Profiles')}
              </Typography>

              <List>
                {visibleUsers.map((user, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleTagClick(user?.name!, user?.linkedinUrn!)}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: '#f1f1f1',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // badgeContent={getIconByType(account.type)}
                      >
                        <Avatar alt={user?.name!!} src={user?.avatar || ''} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography>{user?.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {users.length > 3 && !showAllUsers && (
                <Typography
                  sx={{
                    fontFamily: 'Raleway, sans-serif',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '143%',
                    textIndent: '0', // Assuming "paragraphIndent/none" means no indent
                    letterSpacing: '0.05em', // Assuming "letterSpacing/5" means 5% spacing
                    cursor: 'pointer',
                    color: '#30527C',
                    mt: 1,
                  }}
                  onClick={() => setShowAllUsers(true)}
                >
                  {t('View all profiles')}
                </Typography>
              )}
              <Divider sx={{ my: 1 }} />
              <Typography variant="subtitle1" fontWeight={700}>
                {t('Companies')}
              </Typography>
              <List>
                {visibleCompanies.map((user, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleTagClick(user?.name!, user?.linkedinUrn!)}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: '#f1f1f1',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // badgeContent={getIconByType(account.type)}
                      >
                        <Avatar alt={user?.name!!} src={user?.avatar || ''} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography>{user?.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {companies.length > 3 && !showAllCompanies && (
                <Typography
                  sx={{
                    fontFamily: 'Raleway, sans-serif',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '143%',
                    textIndent: '0', // Assuming "paragraphIndent/none" means no indent
                    letterSpacing: '0.05em', // Assuming "letterSpacing/5" means 5% spacing
                    cursor: 'pointer',
                    color: '#30527C',
                    mt: 1,
                  }}
                  onClick={() => setShowAllCompanies(true)}
                >
                  {t('View all companies')}
                </Typography>
              )}
            </Box>
          )}
        </Stack>
      </ClickAwayListener>
    </Popper>
  );
};

export default TaggerSelector;
