import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { PerformingContent, Tag } from '../../__generated__/graphql';
import {
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextField,
  IconButton,
  Stack,
  Chip,
  Typography,
  Menu,
  Button,
  Checkbox,
  MenuItem,
  Menu as OriginMenu,
  MenuItem as OriginMenuItem,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { formatNumber, lightenColor } from '../../utils';
import {
  Add,
  Close,
  Comment,
  DateRange,
  Label,
  LinkedIn,
  MoreHoriz,
  PersonAdd,
  PlayArrow,
  PostAdd,
  RemoveRedEye,
  Share,
  Star,
  ThumbUp,
  TrendingUp,
} from '@mui/icons-material';
import TagModal from '../PostComponents/TagModal';
import { UPDATE_TAGS } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useTranslation } from 'react-i18next';
import DatePickerRange from '../DatePicker/DatePickerRange';
import Avatar from '../Avatar/Avatar';

interface Data {
  post: string;
  date: string;
  link: string;
  profile: string;
  interactions: number;
  reactions: number;
  comments: number;
  shares: number;
  followers: number;
  numImpressions: number;
  engagementRate: number;
  tags: Tag[];
  postId: string;
  shellId: string;
  postType: string;
  avatar: string;
  reproductions: string;
}

const styleDivider = {
  // padding: '0 8px',
  color: 'black',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '20%',
    height: '60%',
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
};

type Order = 'asc' | 'desc';

enum ContentFilter {
  Images = 'Images',
  Videos = 'Videos',
  Thumbnail = 'Thumbnail',
  PDF = 'PDF',
}

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (a: Data, b: Data) => number {
  return (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    const isNumericValue = typeof aValue === 'number' && typeof bValue === 'number';
    const isArrayOfTags = Array.isArray(aValue) && Array.isArray(bValue);

    if (isArrayOfTags) {
      return order === 'desc'
        ? bValue.length - aValue.length
        : aValue.length - bValue.length;
    }

    if (aValue === '-' && bValue === '-') {
      return 0;
    }

    if (aValue === '-') {
      return 1;
    }

    if (bValue === '-') {
      return -1;
    }

    if (isNumericValue) {
      return order === 'desc' ? bValue - aValue : aValue - bValue;
    }

    // @ts-ignore
    return order === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
  };
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const hasNA = (value: any) => value === '-';

  if (!array) return [];
  const sortedArray = [...array];
  sortedArray?.sort((a, b) => {
    const aIsNA = hasNA(a);
    const bIsNA = hasNA(b);

    if (aIsNA && bIsNA) {
      return 0;
    }

    if (aIsNA) {
      return 1;
    }

    if (bIsNA) {
      return -1;
    }

    return comparator(a, b);
  });

  return sortedArray;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  hide?: boolean;
  icon: ReactElement;
  tooltip?: string;
  sortable?: boolean;
}

interface AnalitysTableProps {
  data: PerformingContent[] | [];
  dateFilter: (dateInit: string, dateEnd: string) => void;
  loadingButton: boolean;
  userOrShell: 'user' | 'shell';
  reactionsRange?: number;
  commentsRange?: number;
  sharesRange?: number;
  impressionsRange?: number;
  isBrandCompany?: boolean;
  dateInit?: Date;
  dateEnd?: Date;
  updateMetricsBrandWithTags: (tags: Tag[], postId: string) => void;
}

const PerformingContentTable: FC<AnalitysTableProps> = ({
  data,
  dateFilter,
  loadingButton,
  userOrShell,
  reactionsRange = 0,
  commentsRange = 0,
  sharesRange = 0,
  impressionsRange = 0,
  isBrandCompany = false,
  dateInit = new Date(),
  dateEnd = new Date(),
  updateMetricsBrandWithTags,
}) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'profile',
      numeric: true,
      disablePadding: false,
      label: t('Profile'),
      hide: userOrShell === 'user',
      icon: <LinkedIn />,
      sortable: true
    },
    {
      id: 'post',
      numeric: false,
      disablePadding: true,
      label: t('Post'),
      icon: <PostAdd />,
      sortable: false
    },
    {
      id: 'tags',
      numeric: false,
      disablePadding: true,
      label: t('Labels'),
      icon: <Label />,
      sortable: false,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: t('Date'),
      icon: <DateRange />,
      sortable: true
    },

    {
      id: 'numImpressions',
      numeric: true,
      disablePadding: false,
      label: `${t('Impressions')}*`,
      icon: <RemoveRedEye />,
      tooltip: t('Personas unicas que vieron tu post'),
      sortable: true
    },
    {
      id: 'interactions',
      numeric: true,
      disablePadding: false,
      label: `${t('Interactions')}*`,
      icon: <Star />,
      tooltip: t('Reacciones, comentarios y compartidos'),
      sortable: true
    },
    {
      id: 'reactions',
      numeric: true,
      disablePadding: false,
      label: `${t('Reactions')}*`,
      icon: <ThumbUp />,
      tooltip: t('Otras reacciones'),
      sortable: true
    },
    {
      id: 'reproductions',
      numeric: true,
      disablePadding: false,
      label: `${t('Reproductions')}*`,
      icon: <PlayArrow />,
      sortable: true
    },
    {
      id: 'comments',
      numeric: true,
      disablePadding: false,
      label: `${t('Comments')}*`,
      icon: <Comment />,
      sortable: true
    },
    {
      id: 'shares',
      numeric: true,
      disablePadding: false,
      label: `${t('Shares')}*`,
      icon: <Share />,
      tooltip: t('Veces que tu post fue compartido'),
      sortable: true
    },
    {
      id: 'followers',
      numeric: true,
      disablePadding: false,
      label: t('Followers'),
      hide: isBrandCompany,
      icon: <PersonAdd />,
      tooltip: t('Cantidad de seguidores'),
      sortable: true
    },
    {
      id: 'engagementRate',
      numeric: true,
      disablePadding: false,
      label: t('Engagement Rate'),
      icon: <TrendingUp />,
      tooltip: t('Nivel de interaccion'),
      sortable: true
    },
  ];

  interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead
        sx={{
          backgroundColor: '#6E7794',
          borderRadius: '30px',
          height: 56,
          '&:hover': {
            backgroundColor: '#f5f5f5', // Cambia el color de fondo al hacer hover
          },
        }}
      >
        <TableRow
          sx={{
            backgroundColor: '#6E7794',
            borderRadius: '30px',
          }}
        >
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                borderRadius:
                  (headCells[0].hide && index === 1) || index === 0
                    ? '10px 0 0 0'
                    : index === headCells.length - 1
                    ? '0 10px 0 0'
                    : '0',
                display: headCell.hide ? 'none' : 'table-cell',
                color: 'white',
                padding: '8px 0',
                textAlign: 'center',
              }}
            >
              {headCell.sortable ? (

              <TableSortLabel
              
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  color: orderBy === headCell.id ? '#f5e2ae !important' : 'white', // Cambia el color del texto cuando está activo
                  '& .MuiTableSortLabel-icon': {
                    color:
                      orderBy === headCell.id ? 'white !important' : 'white !important', // Cambia el color de las flechas cuando está activo
                    display: orderBy === headCell.id ? 'block' : 'none', // Oculta las flechas
                  },
                  '&:hover .MuiTableSortLabel-icon': {
                    opacity: 1, // Muestra el icono al hacer hover
                    display: 'block', // Oculta las flechas
                  },
                  '&:hover': {
                    color: '#f5f0e2', // Cambia el color del texto y las flechas al hacer hover
                    // display: 'inline-block',
                  },
                }}
              >
                <Tooltip title={headCell.tooltip} placement="top">
                  {headCell.icon && cloneElement(headCell.icon, { sx: { fontSize: 18 } })}
                </Tooltip>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              ):(
<>                <Tooltip title={headCell.tooltip} placement="top">
                  {headCell.icon && cloneElement(headCell.icon, { sx: { fontSize: 18 } })}
                </Tooltip></>
                // {headCell.label}
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openTagModal, setOpenTagModal] = useState(false);
  const [postSelected, setPostSelected] = useState<Data | null>(null);
  const [updateTags] = useMutation(UPDATE_TAGS);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(null);
  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(null);
  const [anchorElOrigin, setAnchorElOrigin] = useState<null | HTMLElement>(null);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const [filters, setFilters] = useState<ContentFilter[]>([]);
  const [originFilters, setOriginFilters] = useState<string[]>([]);

  const toggleFilterOption = (filterClicked: ContentFilter) => {
    if (filters.includes(filterClicked)) {
      setFilters(filters?.filter((filter) => filter !== filterClicked));
    } else {
      setFilters([...filters, filterClicked]);
    }
  };

  const toggleOriginFilter = (origin: string) => {
    if (originFilters.includes(origin)) {
      setOriginFilters([]);
    } else {
      setOriginFilters([origin]);
    }
  };

  const handleClickOrigin = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrigin(event.currentTarget);
  };

  const handleCloseOrigin = () => {
    setAnchorElOrigin(null);
  };

  let rows: Data[] | any = data?.map((item: PerformingContent) => {
    const interactions =
      item.metrics!!.numComments!! +
      item.metrics!!.numLikes!! +
      item.metrics!!.totalShares!!;
    const numViewsCalc =
      item.postType === 'video' && item.metrics!!.numImpressions
        ? item.metrics!!.numImpressions
        : item.metrics!!.numViews || 0;
    const engagementRate = (interactions / numViewsCalc) * 100;
    return {
      comments: item.metrics!!.numComments ?? '-',
      date: item.postedAt,
      followers: item.metrics!!.followers ?? '-',
      interactions: item.metrics!!.numComments!! !== null ? interactions : '-',
      reproductions:
        item.postType === 'video' && item.metrics!!.numViews
          ? item.metrics!!.numViews
          : '-',
      numImpressions: item.metrics!!.numImpressions
        ? item.metrics!!.numImpressions
        : item.metrics!!.numViews || '-',
      link: `https://www.linkedin.com/feed/update/${item.postUrn}`,
      engagementRate:
        engagementRate || engagementRate === 0 ? Number(engagementRate.toFixed(2)) : '-',
      post: item.content!!.slice(0, 80 - 3) + '...',
      profile: item.brandName,
      reactions: item.metrics!!.numLikes ?? '-',
      shares: item.metrics!!.totalShares ?? '-',
      tags: item.tags,
      postId: item.postId,
      postType: item.postType,
      avatar: item.brandAvatar,
    };
  });

  const [filteredRows, setFilteredRows] = useState<Data[]>(rows);
  const [tagsFiltered, setTagsFiltered] = useState<Tag[]>([]);
  const [tagFilterModal, setTagFilterModal] = useState<boolean>(false);

  const handleTagToFiltered = (tag: Tag[]) => {
    setTagsFiltered(tag);
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear().toString().slice(-2); // Extrae los últimos 2 dígitos del año
    return `${day}/${month}/${year}`;
  };

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('interactions');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const allRowsWithFilter = useMemo(() => {
    const filteredByTags =
      tagsFiltered.length > 0
        ? filteredRows?.filter((post) =>
            tagsFiltered.every((filteredTag) =>
              post.tags.some((tag) => filteredTag._id === tag._id),
            ),
          )
        : filteredRows; // Si no hay etiquetas seleccionadas, no aplicar el filtro

    // Luego filtrar los posts según los tipos seleccionados
    const filteredByType =
      filters?.length > 0
        ? filteredByTags.filter(
            (post) =>
              (filters?.includes(ContentFilter.Images) &&
                post?.postType?.includes('image')) ||
              (filters?.includes(ContentFilter.Videos) &&
                post.postType?.includes('video')) ||
              (filters?.includes(ContentFilter.Thumbnail) &&
                post.postType?.includes('thumbnail')) ||
              (filters?.includes(ContentFilter.PDF) && post.postType?.includes('pdf')),
          )
        : filteredByTags; // Si no hay tipos seleccionados, no aplicar el filtro

    // Filtrar los posts según el origen seleccionado
    const filteredByOrigin =
      originFilters.length === 0
        ? filteredByType
        : originFilters.includes('Magnettu')
        ? filteredByType.filter(
            (post) => !post.tags.some((tag) => tag.name === 'FROM_LINKEDIN'),
          )
        : filteredByType.filter((post) =>
            post.tags.some((tag) => tag.name === 'FROM_LINKEDIN'),
          );

    return filteredByOrigin;
  }, [tagsFiltered, filters, originFilters, filteredRows]);

  const visibleRows = useMemo(() => {
    // Luego ordenar y paginar los posts filtrados
    return stableSort(allRowsWithFilter, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [order, orderBy, page, rowsPerPage, allRowsWithFilter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterData = useCallback(
    (searchTerm: string) => {
      const filteredData = rows?.filter(
        (row: Data) =>
          row.profile && row.profile.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredRows(filteredData);
    },
    [rows],
  );

  useEffect(() => {
    filterData(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, data]);

  const handleClose = () => {
    setAnchorElFilter(null);
  };

  const totalStaticsPosts = allRowsWithFilter?.reduce(
    (totals: any, post: any) => {
      totals.totalComments += post.comments !== '-' ? post.comments : 0;
      totals.totalReactions += post.reactions !== '-' ? post.reactions : 0;
      totals.totalShares += post.shares !== '-' ? post.shares : 0;
      totals.totalImpressions += post.numImpressions !== '-' ? post.numImpressions : 0;
      totals.totalReproductions += post.reproductions !== '-' ? post.reproductions : 0;
      return totals;
    },
    {
      totalComments: 0,
      totalReactions: 0,
      totalShares: 0,
      totalImpressions: 0,
      totalReproductions: 0,
    },
  );

  const handleAddTags = (tags: Tag[]) => {
    const oldTags = postSelected?.tags || [];

    const newTags = tags.map((tag) => ({
      _id: tag._id,
      name: tag.name,
      color: tag.color,
      internal: tag.internal || false,
    }));

    setFilteredRows(
      filteredRows.map((row: Data) => {
        if (row.postId === postSelected?.postId) {
          return {
            ...row,
            tags: newTags,
          };
        }
        return row;
      }),
    );

    updateMetricsBrandWithTags(newTags, postSelected?.postId as string);

    updateTags({
      variables: {
        postId: postSelected?.postId,
        tags: newTags, // Usamos newTags directamente
      },
      onCompleted: (data) => {
        if (!data.updateTags.success) {
          setFilteredRows(
            filteredRows.map((row: Data) => {
              if (row.postId === postSelected?.postId) {
                return {
                  ...row,
                  tags: oldTags,
                };
              }
              return row;
            }),
          );
          updateMetricsBrandWithTags(oldTags, postSelected?.postId as string);
          return setErrorMessage('Error adding tags');
        }
        setSuccessMessage('Tag added successfully');
      },
    });
  };

  const handleRemoveTag = (tag: Tag, row: Data) => {
    const oldTags = row?.tags;

    const tags = row?.tags
      ?.filter((t) => t?._id !== tag._id)
      .map((tag) => ({
        _id: tag?._id,
        name: tag?.name,
        color: tag?.color,
        internal: tag?.internal || false,
      }));

    updateMetricsBrandWithTags(tags, row.postId);

    setFilteredRows(
      filteredRows.map((r: Data) => {
        if (r.postId === (postSelected?.postId || row.postId)) {
          return {
            ...r,
            tags: tags,
          };
        }
        return r;
      }),
    );

    updateTags({
      variables: {
        postId: postSelected?.postId || row.postId,
        tags: tags,
      },
      onCompleted: (data) => {
        if (!data.updateTags.success) {
          updateMetricsBrandWithTags(oldTags, row.postId);
          setFilteredRows(
            filteredRows.map((r: Data) => {
              if (r.postId === postSelected?.postId) {
                return {
                  ...r,
                  tags: oldTags,
                };
              }
              return r;
            }),
          );
          return setErrorMessage('Error removing tag');
        }
        setSuccessMessage('Tag removed successfully');
      },
    });

    if (tags.length === 0) {
      setAnchorEl(null);
    }

    return;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          transform: 'translate(25px, 10px)',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
        elevation={10}
        MenuListProps={{
          sx: {
            height: 'auto',
            maxHeight: 410,
            width: 220,
            minHeight: 200,
            paddingLeft: 2,
            // paddingBottom: 1,
          },
        }}
      >
        <Typography
          sx={{
            // paddingLeft: '20px',
            color: 'rgba(194, 11, 99, 1)',
            marginY: '0.5rem',
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          All labels
        </Typography>
        <Stack
          flexDirection={'column'}
          gap={1}
          sx={{
            paddingRight: 1,
            paddingBottom: 1,
          }}
        >
          {filteredRows
            ?.find((row) => row.postId === postSelected?.postId)
            ?.tags?.map((tag, i) => (
              <Box
                key={i}
                className={`tag-chip-${i}`}
                sx={{
                  background: 'transparent',
                  border: `0.5px solid ${tag?.color}`,
                  color: tag?.color,
                  borderRadius: '4px',
                  marginBottom: 0.5,
                  padding: '0 5px',
                  height: '24px',
                  width: 'auto',
                  // maxWidth: '200px',
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    fontSize="13px"
                    sx={{
                      flexGrow: 1,
                      textAlign: 'center',
                    }}
                  >
                    {tag?.name}
                  </Typography>
                  <IconButton
                    sx={{
                      borderRadius: '50%',
                      background: '#bdbdbd',
                      color: '#fff',
                      padding: 0.1,
                    }}
                    onClick={() => {
                      handleRemoveTag(
                        tag,
                        filteredRows.find(
                          (row) => row.postId === postSelected?.postId,
                        ) as Data,
                      );
                    }}
                  >
                    <Close
                      sx={{
                        fontSize: '13px',
                      }}
                    />
                  </IconButton>
                </Stack>
              </Box>
            ))}
        </Stack>
      </Menu>
      {postSelected && (
        <TagModal
          openTagModal={openTagModal}
          setOpenTagModal={(value) => {
            setOpenTagModal(value);
            setPostSelected(null);
          }}
          tags={postSelected?.tags}
          handleAddTags={handleAddTags}
          key={postSelected?.tags.length}
        />
      )}

      <Menu
        anchorEl={anchorElFilter}
        open={Boolean(anchorElFilter)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            overflow: 'visible',
            width: 250,
            boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
            borderRadius: '5px',
            marginTop: '1.5rem',
          },
        }}
      >
        <Typography
          fontWeight="bold"
          sx={{
            paddingLeft: '20px',
            color: 'rgba(194, 11, 99, 1)',
            marginY: '0.5rem',
          }}
        >
          {t('Filter by type')}
        </Typography>
        {Object.values(ContentFilter).map((filter, i) => (
          <MenuItem key={i} onClick={() => toggleFilterOption(filter)}>
            <Checkbox sx={{ marginRight: '5px' }} checked={filters.includes(filter)} />
            <Typography fontWeight="bold">{t(filter)}</Typography>
          </MenuItem>
        ))}
      </Menu>

      <TagModal
        openTagModal={tagFilterModal}
        setOpenTagModal={setTagFilterModal}
        tags={tagsFiltered}
        handleAddTags={handleTagToFiltered}
      />

      <Box sx={{ width: '100%', marginTop: '2rem' }}>
        <Box display="flex" gap={3} justifyContent="end" marginBottom="2rem">
          <Button
            onClick={() => {
              setTagFilterModal(true);
              setOpenTagModal(true);
            }}
            variant="outlined"
            sx={{
              background: !!tagsFiltered.length ? '#db97b7' : 'transparent',
            }}
          >
            {t('Filter by labels')}
          </Button>
          <Button
            onClick={handleClickFilter}
            variant="outlined"
            sx={{
              background: !!filters.length ? '#db97b7' : 'transparent',
            }}
          >
            {t('Filter by type')}
          </Button>
          <Button
            onClick={handleClickOrigin}
            variant="outlined"
            sx={{
              background: originFilters.length !== 0 ? '#db97b7' : 'transparent',
            }}
          >
            {t('Filter by origin')}
          </Button>
          <OriginMenu
            anchorEl={anchorElOrigin}
            open={Boolean(anchorElOrigin)}
            onClose={handleCloseOrigin}
            PaperProps={{
              sx: {
                overflow: 'visible',
                width: 300,
                boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '5px',
                marginTop: '1.5rem',
              },
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{
                paddingLeft: '20px',
                color: 'rgba(194, 11, 99, 1)',
                marginY: '0.5rem',
              }}
            >
              {t('Filter by origin')}
            </Typography>
            <OriginMenuItem onClick={() => toggleOriginFilter('Magnettu')}>
              <Checkbox
                sx={{ marginRight: '5px' }}
                checked={originFilters.includes('Magnettu')}
              />
              <Typography fontWeight="bold">{t('Posted from Magnettu')}</Typography>
            </OriginMenuItem>
            <OriginMenuItem onClick={() => toggleOriginFilter('LinkedIn')}>
              <Checkbox
                sx={{ marginRight: '5px' }}
                checked={originFilters.includes('LinkedIn')}
              />
              <Typography fontWeight="bold">{t('Posted from LinkedIn')}</Typography>
            </OriginMenuItem>
          </OriginMenu>
          {userOrShell === 'shell' && (
            <TextField
              size="small"
              label={t('Search brand')}
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
          )}

          <DatePickerRange
            dateInit={dateInit}
            dateEnd={dateEnd}
            dateFilter={dateFilter}
            loadingButton={loadingButton}
          />
        </Box>
        {loadingButton ? (
          <CircularProgress
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: '10%',
            }}
          />
        ) : (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer
                sx={{
                  borderRadius: '10px',
                }}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  <TableBody>
                    {!loadingButton && !page && (
                      <TableRow
                        sx={{
                          backgroundColor: '#30527C1A',
                          borderRadius: '30px',
                          color: '#E91E63',
                          height: 63,
                        }}
                      >
                        <TableCell component="th" scope="row" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={12}>
                            {t('Total statistics')}
                          </Typography>
                        </TableCell>
                        {userOrShell === 'shell' && (
                          <TableCell sx={styleDivider} align="center">
                            -
                          </TableCell>
                        )}
                        <TableCell align="center" sx={styleDivider}>
                          -
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          -
                        </TableCell>

                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(totalStaticsPosts?.totalImpressions || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(
                              totalStaticsPosts?.totalComments +
                                totalStaticsPosts?.totalReactions +
                                totalStaticsPosts?.totalShares || 0,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(totalStaticsPosts?.totalReactions || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(totalStaticsPosts?.totalReproductions || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(totalStaticsPosts?.totalComments || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(totalStaticsPosts?.totalShares || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {isBrandCompany
                              ? (
                                  ((commentsRange + reactionsRange + sharesRange) /
                                    impressionsRange || 0) * 100
                                ).toFixed(2) + '%'
                              : '-'}
                          </Typography>
                        </TableCell>

                        {!isBrandCompany && <TableCell align="center">-</TableCell>}
                      </TableRow>
                    )}
                    {!loadingButton && !page && isBrandCompany && (
                      <TableRow
                        sx={{
                          backgroundColor: '#30527C1A',
                          borderRadius: '30px',
                          color: '#E91E63',
                          height: 63,
                        }}
                      >
                        <TableCell component="th" scope="row" sx={styleDivider}>
                          <Typography color="#E91E63" fontWeight={700} fontSize={12}>
                            {t(
                              'Statistics of older posts and posts published from outside the application',
                            )}
                          </Typography>
                        </TableCell>
                        {userOrShell === 'shell' && (
                          <TableCell sx={styleDivider} align="center">
                            -
                          </TableCell>
                        )}
                        <TableCell sx={styleDivider} align="center">
                          -
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          -
                        </TableCell>

                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(
                              impressionsRange - totalStaticsPosts?.totalImpressions ||
                                0 ||
                                0,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(
                              commentsRange +
                                reactionsRange +
                                sharesRange -
                                (totalStaticsPosts?.totalComments +
                                  totalStaticsPosts?.totalReactions +
                                  totalStaticsPosts?.totalShares) || 0,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(
                              reactionsRange - totalStaticsPosts?.totalReactions ||
                                0 ||
                                0,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                           {/*{formatNumber(
                              reactionsRange - totalStaticsPosts?.totalReproductions ||
                                0 ||
                                0,
                            )} */}
                            -
                          </Typography>
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {formatNumber(
                              commentsRange - totalStaticsPosts?.totalComments || 0,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={styleDivider} align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {sharesRange - totalStaticsPosts?.totalShares || 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography color="#E91E63" fontWeight={700} fontSize={14}>
                            {(
                              ((commentsRange +
                                reactionsRange +
                                sharesRange -
                                (totalStaticsPosts?.totalComments +
                                  totalStaticsPosts?.totalReactions +
                                  totalStaticsPosts?.totalShares)) /
                                (impressionsRange -
                                  totalStaticsPosts?.totalImpressions) || 0) * 100
                            ).toFixed(2) + '%'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {visibleRows.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {userOrShell === 'shell' && (
                            <TableCell
                              sx={{
                                minWidth: '141px',
                                maxWidth: '155px',
                                fontSize: '12px',
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems={'center'}
                                gap={1}
                                maxWidth={155}
                                sx={{
                                  overflow: 'hidden',
                                }}
                              >
                                <Avatar
                                  name={row.profile}
                                  avatar={row.avatar || ''}
                                  size={30}
                                />
                                <Tooltip title={row.profile}>
                                  <Typography
                                    sx={{
                                      display: 'inline-block',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      fontSize: '12px',
                                      maxWidth: '100%',
                                      cursor: 'default',
                                      
                                    }}
                                  >
                                    {row.profile}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          )}

                          <TableCell
                            scope="row"
                            sx={{
                              minWidth: '140px',
                              maxWidth: '155px',
                            }}
                          >
                            <a
                              href={row.link as string}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#CD0061',
                              }}
                            >
                              <Box
                                dangerouslySetInnerHTML={{ __html: row.post }}
                                sx={{
                                  display: '-webkit-box',
                                  WebkitLineClamp: 1, // Limita a 2 líneas
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontSize: '12px',
                                }}
                              />
                            </a>
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '170px',
                              maxWidth: '170px',
                              padding: '0 8px',
                            }}
                          >
                            <Stack flexDirection="row" gap={0.5}>
                              {/* Botón para agregar etiquetas */}
                              <Chip
                                sx={{
                                  background: 'transparent',
                                  border: `0.5px solid #bdbdbd`,
                                  color: '#bdbdbd',
                                  borderRadius: '4px',
                                  marginBottom: 0.5,
                                  height: '24px',
                                  width: '30px',
                                }}
                                label={
                                  <Add
                                    sx={{
                                      marginTop: '4px',
                                      marginLeft: '-10px',
                                    }}
                                  />
                                }
                                onClick={() => {
                                  setPostSelected(row);
                                  setOpenTagModal(true);
                                }}
                              />

                              {/* Mostrar etiquetas */}
                              {Array.isArray(row?.tags) && row.tags.length > 0 && (
                                <>
                                  {/* Lógica para una sola etiqueta */}
                                  {row.tags.length === 1 && // Solo si hay una etiqueta
                                    row.tags[0]?.name && // Verifica que la etiqueta tenga un nombre
                                    row.tags[0].name.length <= 18 && ( // Y si tiene 18 caracteres o menos
                                      <Chip
                                        label={
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                          >
                                            <Typography
                                              fontSize="13px"
                                              sx={{
                                                flexGrow: 1,
                                                textAlign: 'center',
                                                fontSize: '10px',
                                              }}
                                            >
                                              {row.tags[0].name === 'FROM_LINKEDIN'
                                                ? 'From LinkedIn'
                                                : row.tags[0].name}
                                            </Typography>
                                            {/* Botón para eliminar etiqueta (si no es FROM_LINKEDIN) */}
                                            {row.tags[0].name !== 'FROM_LINKEDIN' && (
                                              <IconButton
                                                sx={{
                                                  borderRadius: '50%',
                                                  background: '#bdbdbd',
                                                  color: '#fff',
                                                  padding: 0.1,
                                                  marginLeft: 1,
                                                  marginRight: -0.8,
                                                }}
                                                onClick={() => {
                                                  handleRemoveTag(row.tags[0], row);
                                                }}
                                              >
                                                <Close
                                                  sx={{
                                                    fontSize: '13px',
                                                  }}
                                                />
                                              </IconButton>
                                            )}
                                          </Stack>
                                        }
                                        sx={{
                                          background: lightenColor(
                                            row.tags[0]?.color || '',
                                            0.7,
                                          ),
                                          border: `0.5px solid ${
                                            row.tags[0]?.color || ''
                                          }`,
                                          color: row.tags[0]?.color || '',
                                          borderRadius: '4px',
                                          marginBottom: 0.5,
                                          padding: '0',
                                          height: '24px',
                                        }}
                                      />
                                    )}

                                  {/* Lógica para más de una etiqueta */}
                                  {row.tags.length > 1 && // Si hay más de una etiqueta
                                    row.tags[0]?.name && // Verifica que la primera etiqueta tenga un nombre
                                    row.tags[0].name.length <= 13 && ( // Solo mostrar si tiene 13 caracteres o menos
                                      <Chip
                                        label={
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                          >
                                            <Typography
                                              fontSize="13px"
                                              sx={{
                                                flexGrow: 1,
                                                textAlign: 'center',
                                                fontSize: '10px',
                                              }}
                                            >
                                              {row.tags[0].name === 'FROM_LINKEDIN'
                                                ? 'From LinkedIn'
                                                : row.tags[0].name}
                                            </Typography>
                                            {/* Botón para eliminar etiqueta (si no es FROM_LINKEDIN) */}
                                            {row.tags[0].name !== 'FROM_LINKEDIN' && (
                                              <IconButton
                                                sx={{
                                                  borderRadius: '50%',
                                                  background: '#bdbdbd',
                                                  color: '#fff',
                                                  padding: 0.1,
                                                  marginLeft: 1,
                                                  marginRight: -0.8,
                                                }}
                                                onClick={() => {
                                                  handleRemoveTag(row.tags[0], row);
                                                }}
                                              >
                                                <Close
                                                  sx={{
                                                    fontSize: '13px',
                                                  }}
                                                />
                                              </IconButton>
                                            )}
                                          </Stack>
                                        }
                                        sx={{
                                          background: lightenColor(
                                            row.tags[0]?.color || '',
                                            0.7,
                                          ),
                                          border: `0.5px solid ${
                                            row.tags[0]?.color || ''
                                          }`,
                                          color: row.tags[0]?.color || '',
                                          borderRadius: '4px',
                                          marginBottom: 0.5,
                                          padding: '0',
                                          height: '24px',
                                        }}
                                      />
                                    )}
                                </>
                              )}

                              {/* Mostrar el ícono "Más" si:
        1. Hay más de una etiqueta, o
        2. Hay una etiqueta con más de 18 caracteres, o
        3. El menú está abierto
    */}
                              {Array.isArray(row?.tags) &&
                                (row.tags.length > 1 ||
                                  (row.tags?.length === 1 &&
                                    (row.tags[0]?.name?.length || 0) > 18) ||
                                  anchorEl?.id === `more-${index}`) && (
                                  <MoreHoriz
                                    id={`more-${index}`}
                                    onClick={(e) => {
                                      setPostSelected(row);
                                      handleClick(e);
                                    }}
                                    sx={{
                                      color: '#bdbdbd',
                                      cursor: 'pointer',
                                      marginLeft: 1,
                                    }}
                                  />
                                )}
                            </Stack>
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              // maxWidth: '90px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {formatDate(row.date as string)}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '120px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {formatNumber(row?.numImpressions || 0)}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '120px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {row.interactions}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '100px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {row.reactions}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '100px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {row.reproductions}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '100px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {row.comments}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '70px',
                              maxWidth: '120px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                            align="center"
                          >
                            {row.shares}
                          </TableCell>
                          {!isBrandCompany && (
                            <TableCell
                              sx={{
                                minWidth: '70px',
                                maxWidth: '120px',
                                fontSize: '12px',
                                padding: '0 8px',
                              }}
                              align="center"
                            >
                              {formatNumber(row.followers)}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            sx={{
                              minWidth: '70px',
                              maxWidth: '120px',
                              fontSize: '12px',
                              padding: '0 8px',
                            }}
                          >
                            {typeof row.engagementRate === 'number' &&
                            isFinite(row.engagementRate)
                              ? `${row.engagementRate.toFixed(2) || 0} %`
                              : row.engagementRate === Infinity
                              ? '-'
                              : row.engagementRate}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allRowsWithFilter?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            <Typography color="textSecondary" variant="body2">
              *
              {t('Totals do not consider brands that are not getting data from LinkedIn')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default PerformingContentTable;
