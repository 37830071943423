import { AutoAwesome, Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import { ChangeEvent, useContext, useState, useEffect } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { Language, PostType } from '../../__generated__/graphql';
import { GENERATE_CONTENT_AI } from '../../graphql/mutations';
import LoaderGif from '../../assets/spinner.gif';
import { useTranslation } from 'react-i18next';
import { GENERATE_POST_FOR_INSPIRATION } from '../../graphql/mutations';
import { Editor, EditorState, ContentState } from 'draft-js';
import { updateEditorStateWithMarkdownFormatting } from '../../utils';

const textareaStyle = {
  width: '100%',
  resize: 'none' as const,
  borderColor: '#BBBBBB',
  fontFamily: 'inherit',
  fontSize: '14px',
  lineHeight: 1.5,
  padding: '10px 10px 10px 10px',
  borderRadius: '4px',
  outline: 'none',
};

interface Props {
  open: boolean;
  onAddText: (text: string) => void;
  onClose: () => void;
  advocacyAIBrandId?: string;
  offerOption?: boolean;
}

const AIAssistantModal = ({
  open,
  onClose,
  onAddText,
  advocacyAIBrandId,
  offerOption = false,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);

  //const contentRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [content, setContent] = useState('');
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(content)),
  );
  const [contentLength, setContentLength] = useState<'short' | 'long' | null>('short');
  const [offerLink, setOfferLink] = useState('');
  const handleOfferLinkChange = (value: string) => {
    setOfferLink(value);
  };

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initialState = EditorState.createWithContent(
      ContentState.createFromText(content),
    );
    const formattedState = updateEditorStateWithMarkdownFormatting(initialState);
    setEditorState(formattedState);
  }, [content]);

  // const isValidUrl = (url: string) => {
  //   try {
  //     new URL(url);
  //     return true;
  //   } catch (_) {
  //     return false;
  //   }
  // };

  const [generateContentAI] = useMutation(GENERATE_CONTENT_AI, {
    onCompleted: (data) => {
      if (data.generateContentAI?.success) {
        setContent(data.generateContentAI.message || '');
        setLoading(false);
      } else {
        const errorMessage =
          data?.generateContentAI?.message ??
          'Error: Failed to create posts for the brand.';
        setError(errorMessage);
        setLoading(false);
      }
    },
  });

  const [generatePostForInspiration] = useMutation(GENERATE_POST_FOR_INSPIRATION);

  let languageState =
    postState?.post?.brand?.contentCreation?.language || Language.English;
  const language = t(languageState);

  const handleGenerateContent = async () => {
    setContent('');
    setLoading(true);
    let brandId = '';
    if (postState?.post?.type === PostType.AdvocacyParent) {
      brandId = advocacyAIBrandId || '';
    } else {
      brandId = postState?.post?.brandId || postState?.post?.brand?._id || '';
    }
    if (postState?.post.brandId || brandId !== '') {
      generateContentAI({
        variables: {
          brandId: brandId,
          contentIdea: userPrompt,
          longContent: contentLength === 'long',
        },
      });
    } else {
      setError('Error: Brand ID is missing.');
      setLoading(false);
    }
  };

  const handleGenerateOffer = async () => {
    setContent('');
    setLoading(true);
    let brandId = '';
    if (postState?.post?.type === PostType.AdvocacyParent) {
      brandId = advocacyAIBrandId || '';
    } else {
      brandId = postState?.post?.brandId || postState?.post?.brand?._id || '';
    }
    const topicContext = `Title: "Oferta de trabajo"\n\nDescription: "${userPrompt}"\n\nLink: "${offerLink}"`;

    if (postState?.post.brandId || brandId !== '') {
      generatePostForInspiration({
        variables: {
          brandId: brandId,
          topicContext,
          linkedInSource: false,
        },
        onCompleted: (data) => {
          if (data.generatePostForInspiration?.success) {
            const postText = data.generatePostForInspiration?.message;
            if (postText) {
              setContent(postText);
              setLoading(false);
            }
          } else {
            const errorMessage =
              data?.generatePostForInspiration?.message ??
              'Error: Failed to create posts for the brand.';
            setError(errorMessage);
            setLoading(false);
          }
        },
      });
    } else {
      setError('Error: Brand ID is missing.');
      setLoading(false);
    }
  };

  const handleContentLengthChange = (length: 'short' | 'long') => {
    setContentLength(length);
  };

  return (
    <Dialog open={open} fullScreen={isMobile} disableEscapeKeyDown maxWidth={'xl'}>
      <DialogContent>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent overlay
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              flexDirection: 'column',
            }}
          >
            <img
              src={LoaderGif}
              alt="Loading"
              style={{
                width: '300px', // Adjust based on your needs
                height: '100px',
                objectFit: 'contain',
              }}
            />
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t('Give us a moment to work our magic.')}
            </Typography>
          </Box>
        )}

        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              {!offerOption
                ? t('Generate Content')
                : t('Creating content from a job posting')}
            </Typography>
            <IconButton onClick={onClose} disabled={loading}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
            {!offerOption
              ? t(
                  'Your content will be generated in {{language}}. You can change this preference in your ',
                  { language },
                )
              : t('Offer description (Job, product, blog, etc.)')}
            {!offerOption && (
              <Link
                href="/settings"
                color="inherit"
                underline="always"
                style={{ textDecoration: 'underline' }}
              >
                {t('Settings')}
              </Link>
            )}
            .
          </Typography>
          <textarea
            rows={isMobile ? 5 : 3}
            style={textareaStyle}
            data-testid="ai-assistant-textarea"
            placeholder={
              !offerOption
                ? t(
                    'Example: Write a post about the climate change. Use a bullet list of 3 items with statistics and a quote from a famous person.',
                  )
                : t(
                    'Describe the offer with the technical details, proposals, etc. that you consider necessary for your post.',
                  )
            }
            value={userPrompt}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setUserPrompt(e.target.value);
            }}
            disabled={loading}
          />

          <Stack direction="column" spacing={2} justifyContent="flex-start">
            {!offerOption ? (
              <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                {t('Choose how you want your post')}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                {t('Link to the offer (job, product, blog, etc.)')}
              </Typography>
            )}
            {!offerOption ? (
              <Stack direction="row" spacing={2} justifyContent="flex-start">
                <Button
                  variant="outlined"
                  onClick={() => handleContentLengthChange('short')}
                  disabled={loading}
                  sx={{
                    bgcolor: contentLength === 'short' ? 'primary.light' : 'transparent',
                    borderColor:
                      contentLength === 'short' ? 'primary.main' : 'rgba(0, 0, 0, 0.3)',
                    color:
                      contentLength === 'short' ? 'primary.main' : 'rgba(0, 0, 0, 0.7)',
                  }}
                >
                  {t('Short')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleContentLengthChange('long')}
                  disabled={loading}
                  sx={{
                    bgcolor: contentLength === 'long' ? 'primary.light' : 'transparent',
                    borderColor:
                      contentLength === 'long' ? 'primary.main' : 'rgba(0, 0, 0, 0.3)',
                    color:
                      contentLength === 'long' ? 'primary.main' : 'rgba(0, 0, 0, 0.7)',
                  }}
                >
                  {t('Long')}
                </Button>
              </Stack>
            ) : (
              <TextField
                placeholder={t('Place here the way the reader can access your offer.')}
                fullWidth
                value={offerLink}
                onChange={(e) => handleOfferLinkChange(e.target.value)}
                //error={offerLink.length > 0 && !isValidUrl(offerLink)}
                // helperText={
                //   offerLink.length > 0 && !isValidUrl(offerLink)
                //     ? t('Please enter a valid URL')
                //     : ' '
                // }
                InputLabelProps={{ style: { fontSize: 14 } }}
                InputProps={{ style: { fontSize: 14 } }}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            )}
          </Stack>

          <Stack direction="row" justifyContent={isMobile ? 'flex-end' : 'space-between'}>
            {!isMobile && (
              <Alert
                severity={error ? 'error' : 'info'}
                style={{ backgroundColor: 'transparent', padding: 0 }}
              >
                {error
                  ? error
                  : t('The more precise you are, the better the result will be')}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<AutoAwesome />}
              variant="outlined"
              onClick={!offerOption ? handleGenerateContent : handleGenerateOffer}
              disabled={
                !offerOption
                  ? userPrompt.length === 0 || contentLength === null
                  : userPrompt.length === 0 || offerLink.length === 0
              }
              data-testid="ai-assistant-generate-button"
            >
              {t('Generate')}
            </LoadingButton>
          </Stack>
          {!!content && (
            <>
              <Divider />
              <Box
                sx={{
                  display: 'block',
                  overflowY: 'auto',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
                height={300}
                width="700px"
              >
                <Editor editorState={editorState} readOnly={true} onChange={() => {}} />
              </Box>

              <Stack direction="row" justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAddText(content || '');
                    setContent('');
                    setUserPrompt('');
                    onClose();
                  }}
                >
                  {t('Add to post')}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AIAssistantModal;
