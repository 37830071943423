import { useLazyQuery, useMutation } from '@apollo/client';
import {
  User as FirebaseUser,
  getAuth,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { client } from '..';
import { User, UserLifecycleState, UserPlan } from '../__generated__/graphql';
import { CHANGE_BRAND_SELECTED_ID, USER_SIGNUP } from '../graphql/mutations';
import { GET_CURRENT_USER } from '../graphql/queries';
import { analytics } from '../index';
import { getCookie, getFullName } from '../utils';
interface AuthContextType {
  user: User | null;
  loading: boolean;
  setUser: (user: User | null) => void;
  error: string | null;
  changeBrand: (brandId: string) => void;
  refetchCurrentUser: () => void;
  userHavePost: boolean;
  setUserHavePost: (value: boolean) => void;
  anonymousId: string;
  setLoading: (value: boolean) => void;
}

const initialState: AuthContextType = {
  user: null,
  loading: false,
  setUser: () => {},
  error: null,
  changeBrand: () => {},
  refetchCurrentUser: () => {},
  userHavePost: false,
  setUserHavePost: () => {},
  anonymousId: '',
  setLoading: () => {},
};

interface extendedUser extends FirebaseUser {
  accessToken: string;
}

export const AuthContext = createContext<AuthContextType>(initialState);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [anonymousId] = useState(uuidv4());
  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ currentUser }) => {

      if (
        window.location.pathname === '/payment' && // Está en /payment
        currentUser?.plan?.includes(UserPlan.Starter) && // Tiene plan Starter
        currentUser?.lifecycleState === UserLifecycleState.Active // Está en estado Active
      ) {
        const today = new Date();
        const trialEnd = new Date(currentUser?.trialEnd);

        if (today <= trialEnd) {
          window.location.href = '/';
          return;
        }
      }

      if (
        currentUser?.activeTrial &&
        currentUser.trialEnd &&
        currentUser.lifecycleState === UserLifecycleState.Active &&
        currentUser.plan?.includes(UserPlan.Starter) &&
        window.location.pathname !== '/payment'
      ) {
        const today = new Date();
        const trialEnd = new Date(currentUser.trialEnd);
        if (today > trialEnd) {
          window.location.href = '/payment';
        }
      }
      if (
        currentUser?.lifecycleState === UserLifecycleState.Inactive &&
        currentUser.plan?.includes(UserPlan.Starter) &&
        window.location.pathname !== '/payment'
      ) {
        window.location.href = '/payment';
      }
    },
  });
  const [userHavePost, setUserHavePost] = useState<boolean>(false);

  const [userSignUp] = useMutation(USER_SIGNUP);
  const [changeBrandSelectedId] = useMutation(CHANGE_BRAND_SELECTED_ID);

  const refetchCurrentUser = async () => {
    await getCurrentUser().then(async ({ data }) => {
      if (data?.currentUser) {
        setUser(data.currentUser);
      }
    });
  };

  const changeBrand = async (brandId: string) => {
    await changeBrandSelectedId({
      variables: {
        brandSelectedId: brandId,
      },
      onCompleted({ changeBrandSelectedId: res }) {
        if (res.success && user) {
          setUser({
            ...user,
            brandSelectedId: res.user?.brandSelectedId,
            brandSelected: res.user?.brandSelected,
          });
        }
      },
    });
  };

  const auth = getAuth();
  const tokenToExtension =
    auth && auth.currentUser && (auth.currentUser as extendedUser).accessToken!!;

  useEffect(() => {
    if (tokenToExtension && getCookie('token') !== tokenToExtension) {
      document.cookie = `token=${tokenToExtension}; path=/;`;
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const urlParams = new URLSearchParams(window.location.search);
      const inviteToken = urlParams.get('token');
      const plan = urlParams.get('plan');
      let userPlan: UserPlan = UserPlan.Undefined;

      if (plan && Object.values(UserPlan).includes((plan as any).toUpperCase())) {
        userPlan = plan as UserPlan;
      }
      if (user!! && user.email) {
        getCurrentUser().then(async ({ data, error }) => {
          if (data?.currentUser) {
            setUser(data.currentUser);
            if (window.location.hostname === 'app.magnettu.com') {
              // @ts-ignore
              window.clarity('identify', data.currentUser.email);
              analytics.identify(data.currentUser._id, {
                username: getFullName(data.currentUser),
                traits: {
                  shellName: data.currentUser.shellName,
                },
                context: {
                  groupId: data.currentUser.shellId,
                },
              });
            }

            await client.resetStore();
            setLoading(false);
            return;
          }

          if (inviteToken) {
            setLoading(true);
            userSignUp({
              variables: {
                input: {
                  uid: user.uid,
                  email: user.email!,
                  firstName: user.displayName || '',
                  lastName: '',
                  inviteToken: inviteToken,
                  plan: [userPlan],
                },
              },
              refetchQueries: [GET_CURRENT_USER],
              onCompleted: async ({ userSignUp }) => {
                if (userSignUp.success && userSignUp.user) {
                  setUser(userSignUp.user);
                  await client.resetStore();
                  setLoading(false);
                }
                if (!userSignUp.success) {
                  setLoading(false);
                  signOut(auth);
                  setError(
                    userSignUp.message ||
                      'There was an error logging in. Please try again or contact us at info@magnettu.com',
                  );
                  return;
                }

                if (userSignUp && userSignUp.user) {
                  const user = userSignUp.user;
                  setUser(user);
                  if (window.location.hostname === 'app.magnettu.com') {
                    // @ts-ignore
                    window.clarity('identify', user.email);
                  }
                  await client.resetStore();
                  setLoading(false);
                  return;
                }
              },
              onError: (error) => {
                setLoading(false);
              },
            });
          }
        });
      } else {
        setLoading(false);
        signOut(auth);
      }
    });

    return unsubscribe;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        error,
        changeBrand,
        refetchCurrentUser,
        userHavePost,
        setUserHavePost,
        anonymousId,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
