import { Grid, Stack, Chip, Typography, Button, IconButton } from '@mui/material';
import { FC } from 'react';
import { Tag, UserRole } from '../../__generated__/graphql';
import { Add, Close } from '@mui/icons-material';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

interface PostTagsProps {
  tags: Tag[];
  isMobile?: boolean;
  openModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleRemoveTag?: (tag: Tag) => void;
}

const PostTags: FC<PostTagsProps> = ({ tags, isMobile, openModal, handleRemoveTag }) => {
  const tagsPublic = tags?.filter((tag) => !tag.internal) || [];
  const { user } = useContext(AuthContext);

  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {isMobile &&
            openModal &&
            (user?.role.includes(UserRole.BrandManager) ||
              user?.role.includes(UserRole.LimitedBrandManager)) && (
              <Button
                startIcon={<Add />}
                variant="outlined"
                onClick={() => openModal(true)}
                sx={{
                  height: !handleRemoveTag ? '18px' : isMobile ? '30px' : '24px',
                  padding: '0 7px',
                  fontSize: '13px',
                  borderRadius: '4px',
                  fontWeight: 700,
                }}
              >
                Add labels
              </Button>
            )}

          {!!tagsPublic?.length &&
            tagsPublic.map((tag, index) => (
              <Chip
                key={index}
                label={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography
                      fontSize="13px"
                      sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                      }}
                    >
                      {tag?.name}
                    </Typography>
                    {handleRemoveTag &&
                      (user?.role.includes(UserRole.BrandManager) ||
                        user?.role.includes(UserRole.LimitedBrandManager)) && (
                        <IconButton
                          sx={{
                            borderRadius: '50%',
                            background: '#bdbdbd',
                            color: '#fff',
                            padding: 0.1,
                            marginLeft: 1,
                            marginRight: -0.8,
                          }}
                          onClick={() => handleRemoveTag(tag)}
                        >
                          <Close
                            sx={{
                              fontSize: '13px',
                            }}
                          />
                        </IconButton>
                      )}
                  </Stack>
                }
                sx={{
                  background: 'transparent',
                  border: `0.5px solid ${tag?.color}`,
                  color: tag?.color,
                  borderRadius: '4px',
                  marginBottom: 0.5,
                  padding: '0',
                  height: !handleRemoveTag ? '18px' : isMobile ? '30px' : '24px',
                }}
              />
            ))}
        </Stack>
      </Grid>
      {/* )} */}
    </>
  );
};

export default PostTags;
