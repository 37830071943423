import { useMutation, useQuery } from '@apollo/client';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  ArticleType,
  FrequencyFilters,
  Insight,
  InsightType,
  Language,
  Sentiment,
  SortOrder,
  UserRole,
} from '../../__generated__/graphql';
import InsightModal from '../../components/InsightComponents/InsightModal';
import {
  InsightsFilters,
  InsightsFiltersData,
} from '../../components/InsightComponents/InsightsFilters';
import MasonryInsights from '../../components/Masonry/MasonryInsights';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostProvider } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { UPSERT_INSIGHT } from '../../graphql/mutations';
import { GET_INSIGHTS } from '../../graphql/queries';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import { useTranslation } from 'react-i18next';

const Insights = () => {
  const { t } = useTranslation();
  // Contexts
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const brandSelected =
    user?.role.includes(UserRole.BrandManager) ||
    user?.role.includes(UserRole.LimitedBrandManager)
      ? user?.brandSelected || user?.brand
      : user?.brand;

  // States
  const [insightSelected, setInsightSelected] = useState<number | null>(null);
  const [disabledButtonBack, setDisabledButtonBack] = useState<boolean>(false);
  const [disabledButtonNext, setDisabledButtonNext] = useState<boolean>(false);
  const [filters, setFilters] = useState<InsightsFiltersData>({
    topics: brandSelected?.insightPreferences?.topics || [],
    frequency: FrequencyFilters.All,
    favorites: false,
    insightTypes: [InsightType.Linkedin],
    authors: [],
    sortOrder: SortOrder.Newest,
    interactions: null,
  });

  // Mutations
  const [upsertInsight] = useMutation(UPSERT_INSIGHT);

  let languages: Language[] = brandSelected?.insightPreferences?.languages || [];

  const {
    data,
    loading: loadingInsights,
    refetch,
  } = useQuery(GET_INSIGHTS, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      languages,
      topics: filters.topics,
      frequency: filters.frequency,
      favorites: filters.favorites,
      insightTypes: filters.insightTypes,
      sortOrder: filters.sortOrder,
      interactions: filters.interactions,
    },
  });

  const insights = (data?.getInsights as Insight[]) || [];

  const handleFiltersChange = (filters: InsightsFiltersData) => {
    setFilters(() => {
      refetch({
        languages,
        topics: filters.topics,
        frequency: filters.frequency,
        favorites: filters.favorites,
        insightTypes: filters.insightTypes,
        sortOrder: filters.sortOrder,
        interactions: filters.interactions,
      });

      return filters;
    });
  };

  // Add to favorites
  const handleCreateOrUpdateInsight = (
    insightInput: Insight,
    sentiment?: Sentiment,
    favorite?: boolean,
  ) => {
    const mediaWithoutTypename = insightInput.media?.map(
      ({ __typename, ...rest }) => rest,
    );

    const baseInput = {
      articleId: insightInput.articleId,
      favorite: favorite,
      sentiment: sentiment || Sentiment.Neutral,
      content: insightInput.content,
      link: insightInput.link,
      pubDate: insightInput.pubDate,
      type: insightInput.type,
    };

    const inputArticle = {
      ...baseInput,
      title: insightInput.title,
      imgUrl: insightInput.imgUrl,
      description: insightInput.description,
      creator: insightInput.creator,
      keywords: insightInput.keywords,
      source: insightInput.source,
      videoUrl: insightInput.videoUrl,
    };

    const inputLinkedin = {
      ...baseInput,
      author: insightInput.author
        ? {
            description: insightInput.author.description,
            fullName: insightInput.author.fullName,
            profilePicture: insightInput.author.profilePicture,
            firstName: insightInput.author.firstName,
            lastName: insightInput.author.lastName,
          }
        : {},
      media:
        insightInput.type === ArticleType.Linkedin &&
        (insightInput.media?.length ?? 0) > 0
          ? mediaWithoutTypename
          : [],
      interactions:
        insightInput.type === ArticleType.Linkedin
          ? {
              comments: insightInput.interactions?.comments,
              numLikes: insightInput.interactions?.numLikes,
            }
          : undefined,
    };

    upsertInsight({
      variables: {
        input: insightInput.type === ArticleType.Article ? inputArticle : inputLinkedin,
      },
      onCompleted: ({ upsertInsight }) => {
        if (!upsertInsight.success) {
          setErrorMessage(t('Something went wrong'));
          return;
        }

        setSuccessMessage(t('Insight updates successfully'));
      },
    });
  };

  const handleNextInsight = () => {
    setInsightSelected((current) => {
      const currentIndex = current ?? 0;
      const totalInsights = insights?.length ?? 1;
      const nextIndex = Math.min(currentIndex + 1, totalInsights - 1);

      setDisabledButtonNext(nextIndex === totalInsights - 1);
      setDisabledButtonBack(false);

      return nextIndex;
    });
  };

  const handlePreviousInsight = () => {
    setInsightSelected((current) => {
      const currentIndex = Math.max(0, (current || 1) - 1);
      setDisabledButtonBack(currentIndex === 0);
      setDisabledButtonNext(false);
      return currentIndex;
    });
  };

  return (
    <>
      {loadingInsights ? <LinearProgress /> : <Box height={4} />}
      <Box width={'100%'}>
        <Stack
          data-testid="content-board"
          direction="column"
          alignItems="center"
          p={isMobile ? '15px 15px' : '40px 25px'}
          rowGap={2}
          width={'100%'}
          maxWidth={'1800px'}
          alignSelf={'center'}
          marginX={'auto'}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            spacing={2}
          >
            <Stack direction="column" alignItems="flex-start">
              <Typography variant={'h4'} fontWeight="bold">
                {t('Get inspired!')}
              </Typography>
              <Typography variant={'body1'}>
                {t('Use the search bar to explore insights.')}
              </Typography>
              <Typography variant={'body2'}>
                ✨{' '}
                {t(
                  'Tip: the more precise you are with your search terms, the better the results',
                )}
              </Typography>
            </Stack>
          </Stack>
          <InsightsFilters
            filters={filters}
            onFiltersChange={handleFiltersChange}
            dateFilters={!filters.favorites}
          />
          {insights && insights.length > 0 && (
            <MasonryInsights
              insights={insights}
              onInsightOpen={(i) => {
                setInsightSelected(i);
              }}
              setDisabledButtonBack={setDisabledButtonBack}
              setDisabledButtonNext={setDisabledButtonNext}
            />
          )}
        </Stack>
        {insightSelected !== null && (
          <WebSocketProvider postId={null}>
            <PostProvider
              postId={null}
              newPostOptions={{
                brandId: brandSelected?._id,
                brand: brandSelected,
                ...(insights[insightSelected].type === ArticleType.AiGenerated && {
                  content: {
                    title: insights[insightSelected].title,
                    body: insights[insightSelected].content,
                    json: insights[insightSelected].content,
                  },
                }),
              }}
              insightContent={
                insights[insightSelected].type === ArticleType.AiGenerated
                  ? insights[insightSelected].content || ''
                  : undefined
              }
            >
              {insights[insightSelected].type === ArticleType.AiGenerated ? (
                <PostEditorModal
                  onClose={() => {
                    setInsightSelected(null);
                  }}
                />
              ) : (
                // <></>
                <InsightModal
                  insight={insights[insightSelected]}
                  onClose={() => setInsightSelected(null)}
                  handleNextInsight={handleNextInsight}
                  handlePreviousInsight={handlePreviousInsight}
                  handleCreateOrUpdateInsight={handleCreateOrUpdateInsight}
                  disabledButtonBack={disabledButtonBack}
                  disabledButtonNext={disabledButtonNext}
                />
              )}
            </PostProvider>
          </WebSocketProvider>
        )}
      </Box>
    </>
  );
};

export default Insights;
