import { useState, MutableRefObject, useEffect } from 'react';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  ContentBlock,
  Modifier,
  convertToRaw,
} from 'draft-js';
import { Post } from '../__generated__/graphql';
import {
  extractInnerText,
  createEditorStateWithMentions,
  parsePostContentForPreview,
  convertEditorStateToHTML,
} from '../utils';
import useUnsavedChangesWarning from './useUnsavedChangesWarning';
import { UsePostReturnType } from './usePost';
import MentionDecorator from '../components/TextEditor/MentionDecorator';
import * as cheerio from 'cheerio';

interface UsePostEditorProps {
  postState: UsePostReturnType;
  insightContent: string;
  postRef: MutableRefObject<Post> | undefined;
  handleThumbnail?: (textContent: string, paste?: boolean) => void;
  insights?: boolean;
}

export const usePostEditor = ({
  postState,
  insightContent,
  postRef,
  handleThumbnail,
  insights = false,
}: UsePostEditorProps) => {
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(MentionDecorator),
  );

  useEffect(() => {
    if (!setEditorState) return;

    let newEditorState;

    if (insightContent) {
      newEditorState = createEditorStateWithMentions(insightContent);
    }

    if (newEditorState) {
      setEditorState(EditorState.moveFocusToEnd(newEditorState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUnsavedChanges =
    (!!postState.post.content?.body ||
      !!postState.post.content?.media ||
      !!postState.post.title) &&
    (extractInnerText(postState.postRef?.current.content?.body as string) !==
      extractInnerText(postState.status?.initialPostBodyRef.current || '') ||
      !!postState.post.title) &&
    !postState.status.errorPostModal;

  useUnsavedChangesWarning(hasUnsavedChanges);

  const handleChange = (newEditorState: EditorState, pastedText?: boolean) => {
    let updatedEditorState = newEditorState;

    //Overide style for now this functions well when selecting and puting bold or italic but when selecting and erasing bold or italic doesnt work
    // updatedEditorState = EditorState.setInlineStyleOverride(
    //   updatedEditorState,
    //   OrderedSet(),
    // );

    const contentState = updatedEditorState.getCurrentContent();
    const selectionState = updatedEditorState.getSelection();

    // Get current cursor position
    const anchorOffset = selectionState.getAnchorOffset();
    const currentBlockKey = selectionState.getAnchorKey();
    const currentBlock = contentState.getBlockForKey(currentBlockKey);
    const blockText = currentBlock.getText();

    // Get the character before the cursor
    const charBeforeCursor = anchorOffset > 0 ? blockText[anchorOffset - 1] : null;

    setEditorState(updatedEditorState);

    const rawContent = convertToRaw(contentState);
    const plainText = contentState.getPlainText();

    let htmlContent = convertEditorStateToHTML(contentState);

    const formattedText = parsePostContentForPreview(plainText, htmlContent, true);

    if (postRef?.current) {
      if (!postRef.current.content) {
        postRef.current.content = {};
      }
      postRef.current.content.body = htmlContent;
      postRef.current.content.json = rawContent;
      postRef.current.content.plainText = formattedText;
    }

    if (charBeforeCursor === '@') {
      return '@';
    }

    if (handleThumbnail) handleThumbnail(plainText, pastedText);
  };

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState,
  ) => {
    if (!html) return 'not-handled';

    const $ = cheerio.load(html);

    const isGoogleDocsPaste = $('[id^="docs-internal-guid"]').length > 0;

    if (isGoogleDocsPaste) {
      // Handle Google Docs paste by replacing <br> elements
      $('br').each((_, elem) => {
        $(elem).replaceWith('<p>\u200B</p>');
      });
    } else {
      // Handle non-Google Docs paste by inserting <p>\u200B</p> between consecutive <p> elements
      $('p').each((_, elem) => {
        const nextElem = $(elem).next();
        if (nextElem.is('p')) {
          $(elem).after('<p>\u200B</p>');
        }
      });
    }

    $('span.visually-hidden').each((_, elem) => {
      if ($(elem).text().trim().toLowerCase() === 'hashtag') {
        $(elem).remove();
      }
    });

    // Unwrap spans that have strikethrough styles but keep their text
    $('span').each((_, elem) => {
      const style = $(elem).attr('style') || '';

      // If span has text-decoration: line-through, unwrap it (remove the span but keep text)
      if (/text-decoration:\s*line-through/.test(style)) {
        const innerHtml = $(elem).html();
        if (innerHtml !== null) {
          $(elem).replaceWith(innerHtml);
        }
      }
    });

    // Convert cleaned HTML to ContentState
    const cleanedHtml = $.html();
    const blocksFromHTML = convertFromHTML(cleanedHtml);
    let contentBlocks = blocksFromHTML.contentBlocks;
    const entityMap = blocksFromHTML.entityMap;

    if (!contentBlocks.length) return 'not-handled';

    // Ensure all pasted blocks are unstyled (removes <h1>, <h2>, etc.)
    contentBlocks = contentBlocks.map(
      (block) => block.set('type', 'unstyled') as ContentBlock,
    );

    // Create ContentState from adjusted blocks
    let pastedContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    // Get current content and selection
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Insert pasted content while keeping inline styles
    const newContentState = Modifier.replaceWithFragment(
      currentContent,
      selection,
      pastedContentState.getBlockMap(),
    );

    // Create new editor state with merged content
    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-fragment',
    );

    // Ensure only BOLD and ITALIC styles are applied
    // const allowedStyles = OrderedSet(['BOLD', 'ITALIC']);
    // newEditorState = EditorState.setInlineStyleOverride(newEditorState, allowedStyles);

    // Update state and move cursor to end

    setEditorState(EditorState.moveFocusToEnd(newEditorState));
    handleChange(newEditorState, true);

    return 'handled';
  };

  return {
    editorState,
    setEditorState,
    handleChange,
    handlePastedText,
  };
};
