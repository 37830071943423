import {
  FormatBoldRounded,
  InsertEmoticon,
  Lock,
  LockOpen,
  Redo,
  Undo,
  FormatItalicRounded,
} from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
  Box,
  Divider,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Editor, EditorState, RichUtils, Modifier, SelectionState } from 'draft-js';
import { useContext, useRef, useState, useEffect } from 'react';
import { PostContext } from '../../context/PostContext';
import { PostHandlers, PostStatus } from '../../hooks/usePost';
import AIAssistantMenu from '../AiAssistant/AIAssistantMenu';
import EmojiComponent from '../SelectorComponents/EmojiSelector';
import TaggerSelector from '../SelectorComponents/TaggerSelector';
import { useLocation } from 'react-router-dom';
import CharCounter from './CharCounter';
import './styles.css';
import { PostType } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import SignIcon from '../../assets/sign.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WebSocketContext } from '../../context/WebSocketContext';
import {
  createEditorStateWithMentions,
  removeInlineStyleFromMentions,
} from '../../utils';
import MentionDecorator from './MentionDecorator';
import LoaderGif from '../../assets/spinner.gif';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: '20px',
  '& .MuiSvgIcon-root': {
    width: '20px',
  },
  '&.MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  postId?: string;
  placeholder?: string;
  status?: PostStatus;
  handlers?: PostHandlers;
  aiAssistant?: boolean;
  resizable?: boolean;
  advocacyEditable?: boolean;
  toggleAdvocacyEditable?: () => void;
  isUndoable?: boolean;
  isLink?: boolean;
  suggestAI?: boolean;
  inputAI?: boolean;
  content?: string;
  onContentChange?: (content: EditorState) => void;
  loadingFromInspiration?: boolean;
}

export const TextEditor = ({
  postId,
  status,
  aiAssistant = false,
  advocacyEditable,
  toggleAdvocacyEditable,
  isUndoable = false,
  isLink = false,
  suggestAI = false,
  inputAI = false,
  content,
  onContentChange,
  loadingFromInspiration = false,
}: Props) => {
  const { t } = useTranslation();
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const [taggerComponentOpen, setTaggerComponentOpen] = useState(false);
  const { socket } = useContext(WebSocketContext);
  const [showNoSignatureModal, setShowNoSignatureModal] = useState(false);
  const editorRef = useRef<Editor | null>(null);
  const { postState, editorState, setEditorState, handleChange, handlePastedText } =
    useContext(PostContext);
  const { postRef } = postState || {};
  const [blockEditor, setBlockEditor] = useState(false);

  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
  const editable =
    !blockEditor &&
    !status?.isEditDisabled &&
    !status?.isSaving &&
    !postState?.status.isEditDisabled &&
    (!isProduction || socket === null || socket.connected) &&
    (postState?.post.type !== PostType.Advocacy || postState?.post.advocacy?.editable);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const location = useLocation();

  //Initialize TextEditor
  useEffect(() => {
    if (!setEditorState) return;

    let newEditorState;

    if (content && !status?.isNew) {
      newEditorState = createEditorStateWithMentions(content);
    } else if (
      postRef?.current?.content?.body &&
      !status?.isNew &&
      location.pathname !== '/content-manager/new-post'
    ) {
      newEditorState = createEditorStateWithMentions(postRef.current.content.body);
    } else {
      newEditorState = EditorState.createEmpty(MentionDecorator);
    }

    setEditorState(EditorState.moveFocusToEnd(newEditorState));

    // Leave content in brackets because in the editor admin when the url is edit-post it takes a while to load the content, ONLY CONTENT beause we dont want to rerender every time postref change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  // INSERT SIGNATURE LOGIC
  const isSignaturePresent = (editorState: EditorState): boolean => {
    const contentState = editorState.getCurrentContent();
    let hasSignature = false;

    contentState.getBlockMap().forEach((block) => {
      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            return entity.getType() === 'SIGNATURE';
          }
          return false;
        },
        () => {
          hasSignature = true;
        },
      );
    });

    return hasSignature;
  };

  const [isSignatureChecked, setIsSignatureChecked] = useState(() =>
    editorState ? isSignaturePresent(editorState) : false,
  );

  const insertSignature = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    if (!postState?.post?.brand?.signature || postState?.post?.brand?.signature === '') {
      setShowNoSignatureModal(true);
      return;
    }

    const contentState = editorState.getCurrentContent();
    const signatureText = postState.post.brand.signature;

    const contentStateWithEntity = contentState.createEntity('SIGNATURE', 'IMMUTABLE', {
      signatureText,
    });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const lastBlockKey = contentState.getLastBlock().getKey();

    // ✅ Create new selection at the end of the last block
    const newSelection = SelectionState.createEmpty(lastBlockKey).merge({
      anchorOffset: contentState.getLastBlock().getLength(),
      focusOffset: contentState.getLastBlock().getLength(),
    });

    // ✅ Insert a break line before the signature
    const contentWithBreak = Modifier.insertText(
      contentStateWithEntity,
      newSelection,
      '\n', // Add newline
    );

    const contentWithSignature = Modifier.insertText(
      contentWithBreak,
      contentWithBreak.getSelectionAfter(),
      `${signatureText}`,
      undefined,
      entityKey,
    );

    let newEditorState = EditorState.push(
      editorState,
      contentWithSignature,
      'insert-characters',
    );

    // ✅ Move cursor to the end after inserting signature
    newEditorState = EditorState.moveSelectionToEnd(newEditorState);

    setEditorState(newEditorState);
    handleChange && handleChange(newEditorState);
  };

  const removeSignature = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    let newContentState = contentState;
    let hasSignature = false;

    blockMap.forEach((block) => {
      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            return entity.getType() === 'SIGNATURE';
          }
          return false;
        },
        (start, end) => {
          const selection = SelectionState.createEmpty(block.getKey()).merge({
            anchorOffset: start,
            focusOffset: end,
          });

          newContentState = Modifier.removeRange(newContentState, selection, 'backward');
          hasSignature = true;
        },
      );
    });

    if (!hasSignature) return;

    const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
    setEditorState(newEditorState);
    handleChange && handleChange(newEditorState);
  };

  const handleSignatureToggle = () => {
    if (!editorState || !setEditorState) return;

    if (isSignatureChecked) {
      removeSignature(editorState, setEditorState);
      setIsSignatureChecked(false);
    } else {
      insertSignature(editorState, setEditorState);
      setIsSignatureChecked(true);
    }
  };

  //END SIGNATURE LOGIC

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleTaggerComponentOpen = () => {
    setTaggerComponentOpen(true);
  };

  const handleTaggerComponentClose = () => {
    setTaggerComponentOpen(false);
  };

  const handleEmojiComponentClose = () => {
    setEmojiComponentOpen(false);
  };

  const handleEmojiInputChange = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
    emoji: string,
  ) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(contentState, selectionState, emoji);

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    const updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: selectionState.getAnchorOffset() + emoji.length,
      focusOffset: selectionState.getAnchorOffset() + emoji.length,
    });

    setEditorState(EditorState.forceSelection(newEditorState, updatedSelection));
  };

  const handleTagger = async (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
    tag: string,
    tagId: string,
  ) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // Adjust selection to include "@" character if needed
    const anchorOffset = Math.max(selectionState.getAnchorOffset() - 1, 0);
    const focusOffset = selectionState.getAnchorOffset();

    const adjustedSelection = selectionState.merge({
      anchorOffset,
      focusOffset,
    });

    // Create mention entity
    const entityKey = contentState
      .createEntity('MENTION', 'IMMUTABLE', { tag, tagId })
      .getLastCreatedEntityKey();

    // Insert mention text
    const contentWithMention = Modifier.replaceText(
      contentState,
      adjustedSelection,
      `@${tag}`,
      undefined,
      entityKey,
    );

    // Insert a space *after* the mention (without linking it to the entity)
    const selectionAfterMention = contentWithMention.getSelectionAfter();
    const contentWithSpace = Modifier.insertText(
      contentWithMention,
      selectionAfterMention,
      ' ', // Inserting a blank space
    );

    let newEditorState = EditorState.push(
      editorState,
      contentWithSpace,
      'insert-characters',
    );

    // Move cursor to end after inserting space
    newEditorState = EditorState.forceSelection(
      newEditorState,
      contentWithSpace.getSelectionAfter(),
    );

    setEditorState(newEditorState);
    handleChange && handleChange(newEditorState);
    handleTaggerComponentClose();
  };

  const handleUndo = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    const newEditorState = EditorState.undo(editorState);
    setEditorState(newEditorState);
  };

  const handleRedo = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    const newEditorState = EditorState.redo(editorState);
    setEditorState(newEditorState);
  };

  const setBold = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    let newEditorState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    // Remove BOLD from mention ranges
    newEditorState = removeInlineStyleFromMentions(newEditorState, 'BOLD');
    setEditorState(newEditorState);
    if (handleChange) handleChange(newEditorState);
  };

  //maybe a solution try to pass to handle change the local state of italic and bold and set it manually
  const setItalic = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) => {
    let newEditorState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
    // Remove ITALIC from mention ranges
    newEditorState = removeInlineStyleFromMentions(newEditorState, 'ITALIC');
    setEditorState(newEditorState);
    if (handleChange) handleChange(newEditorState);
  };

  return (
    <Stack direction="column" height={'100%'} spacing={1} width={'100%'}>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          borderRadius: '8px',
          cursor: 'text',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        {loadingFromInspiration ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent overlay
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              flexDirection: 'column',
            }}
          >
            <img
              src={LoaderGif}
              alt="Loading"
              style={{
                width: '300px', // Adjust based on your needs
                height: '100px',
                objectFit: 'contain',
              }}
            />
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t('Give us a moment to work our magic.')}
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              minHeight={300}
              sx={{
                display: 'block',
                overflowY: 'auto',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
              onClick={() => {
                if (editorRef.current) {
                  editorRef.current.focus(); // Focus the editor when clicking the box
                }
              }}
            >
              <style>
                {`
          .public-DraftEditorPlaceholder-root {
            opacity: 0.5 !important; /* Make placeholder more transparent */
            padding: 10px; /* Ensure placeholder aligns with text */
          }
          .DraftEditor-root {
            padding: 10px; /* Ensure text inside has padding */
          }
        `}
              </style>
              {editorState && handleChange && setEditorState && (
                <Editor
                  ref={editorRef}
                  editorState={editorState}
                  onChange={(newState) => {
                    const result = handleChange(newState);

                    onContentChange && onContentChange(newState);

                    if (result === '@') {
                      handleTaggerComponentOpen();
                    }
                  }}
                  readOnly={!editable}
                  blockStyleFn={() => 'default-font'}
                  handlePastedText={handlePastedText}
                />
              )}
            </Box>
            <Box
              height={'fit-content'}
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              {!status?.isEditDisabled && (
                <Stack
                  direction="row"
                  justifyContent={isLink ? 'flex-end' : 'space-between'}
                  sx={{
                    width: '100%',
                    bottom: 0,
                    p: '10px 20px 10px 10px',
                  }}
                >
                  {editorState && aiAssistant && !isLink && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <AIAssistantMenu
                        postId={postId}
                        //editor={editor}
                        onLoading={(state) => {
                          setBlockEditor(state);
                        }}
                        suggestAI={suggestAI}
                        inputAI={inputAI}
                      />
                    </Box>
                  )}
                  <Stack direction="row" gap={1} alignItems="center">
                    {status?.isAdvocacyParent && (
                      <Tooltip title="Let others edit the content" arrow placement="top">
                        <IconButton onClick={toggleAdvocacyEditable}>
                          {advocacyEditable ? <LockOpen /> : <Lock />}
                        </IconButton>
                      </Tooltip>
                    )}
                    {status?.charsCount !== undefined && <CharCounter />}
                    {editorState && setEditorState && (
                      <Tooltip title={t('Bold')} placement="top">
                        <IconButton onClick={() => setBold(editorState, setEditorState)}>
                          <FormatBoldRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                    {editorState && setEditorState && (
                      <Tooltip title={t('Italic')} placement="top">
                        <IconButton
                          onClick={() => setItalic(editorState, setEditorState)}
                        >
                          <FormatItalicRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={t('Insert mention')} placement="top">
                      <IconButton onClick={handleTaggerComponentOpen} ref={anchorRef}>
                        <AlternateEmailIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Insert emoji')} placement="top">
                      <IconButton
                        data-testid="emoji-button"
                        onClick={handleEmojiComponentOpen}
                        ref={anchorRef}
                      >
                        <InsertEmoticon />
                      </IconButton>
                    </Tooltip>
                    {showNoSignatureModal && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '50px',
                          left: '80%',
                          transform: 'translateX(-40%)',
                          backgroundColor: 'white',
                          border: '1px solid #BBBBBB',
                          borderRadius: '8px',
                          padding: '20px',
                          zIndex: 1000,
                        }}
                      >
                        <Typography variant="body2">
                          {(postState?.post?.brand?.user?._id ||
                            postState?.post?.brand?.userId) === user?._id
                            ? t("You don't have a signature.")
                            : `${`${postState?.post?.brand?.name || 'The brand user'} 
                           `.trim()} ${t("doesn't have a signature.")}`}
                        </Typography>

                        {(postState?.post?.brand?.user?._id ||
                          postState?.post?.brand?.userId) === user?._id ? (
                          <span
                            style={{ color: 'hotpink', cursor: 'pointer' }}
                            onClick={() => {
                              postState?.handlers?.handleSaveAsDraft({});
                              navigate('/settings', {
                                state: { tab: 0, openEssentialInfo: true },
                              });
                            }}
                          >
                            {t('Add one')}
                          </span>
                        ) : (
                          <span
                            style={{ color: 'hotpink', cursor: 'pointer' }}
                            onClick={() => {
                              postState?.handlers?.handleSaveAsDraft({});
                              navigate('/brands', {
                                state: {
                                  brand: postState?.post?.brand,
                                  openEssentialInfoBrands: true,
                                },
                              });
                            }}
                          >
                            {t('Add one')}
                          </span>
                        )}

                        <IconButton onClick={() => setShowNoSignatureModal(false)}>
                          <Undo />
                        </IconButton>
                      </Box>
                    )}
                    {!isLink && postState?.post?.type !== PostType.AdvocacyParent && (
                      <Tooltip title={t('Insert signature')} placement="top">
                        <Box
                          sx={{
                            position: 'relative', // Parent must be relative for Chip's absolute positioning
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px', // Set a width that provides enough space for the icon and Chip
                            height: '40px', // Set a height to ensure room for the Chip
                          }}
                        >
                          <IconButton
                            onClick={handleSignatureToggle}
                            style={{ color: isSignatureChecked ? 'blue' : 'inherit' }}
                          >
                            <img
                              src={SignIcon}
                              alt="signature icon"
                              width="20"
                              style={{
                                filter: isSignatureChecked
                                  ? 'invert(34%) sepia(100%) saturate(7472%) hue-rotate(180deg) brightness(95%) contrast(101%)'
                                  : 'none',
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    )}
                    {editorState && setEditorState && (
                      <EmojiComponent
                        open={emojiComponentOpen}
                        anchorElement={anchorRef.current}
                        onClose={handleEmojiComponentClose}
                        onChange={(emoji) =>
                          handleEmojiInputChange(editorState, setEditorState, emoji)
                        }
                      />
                    )}
                    {editorState && setEditorState && (
                      <TaggerSelector
                        open={taggerComponentOpen}
                        anchorElement={anchorRef.current}
                        onClose={handleTaggerComponentClose}
                        onChange={(tag, tagId) =>
                          handleTagger(editorState, setEditorState, tag, tagId)
                        }
                      />
                    )}
                    <Divider orientation="vertical" variant="middle" flexItem />
                    {isUndoable && editorState && setEditorState && (
                      <>
                        <Tooltip title={t('Undo')} placement="top">
                          <IconButton
                            onClick={() => handleUndo(editorState, setEditorState)}
                            ref={anchorRef}
                          >
                            <Undo />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Redo')} placement="top">
                          <IconButton
                            onClick={() => handleRedo(editorState, setEditorState)}
                            ref={anchorRef}
                          >
                            <Redo />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>
                </Stack>
              )}
              {status?.isEditDisabled && !status.isCurrentUserEditing && (
                <Stack
                  direction={'row'}
                  sx={{
                    p: '8px',
                    backgroundColor: '#f0c0c0',
                    width: '100%',
                    justifyContent: 'space-between',
                    color: 'hsla(0, 0%, 43%, 1)',
                  }}
                >
                  <Typography fontWeight={600} fontSize={14} ml={0.5}>
                    {t(
                      "This post is currently being edited by another user. You'll be able to make edits once it's available.",
                    )}
                  </Typography>
                  <Lock />
                </Stack>
              )}
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );
};
