import {
  Add,
  Delete,
  Edit,
  ErrorOutlineOutlined,
  ExpandMore,
  RemoveRedEyeRounded,
  UploadFile,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getDocument } from 'pdfjs-dist';
import { ChangeEvent, useContext, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {
  Post,
  PostContentMedia,
  PostLifecycleState,
  PostType,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { uploadMedia } from '../../utils';
import CircularProgressDeterminate from '../CircularProgress/CircularProgressDeterminate';
import EditTitleDialog from './EditTitleDialog';
import FullDisplayMediaDialog from './FullDisplayMediaDialog';
import { Message } from './PostEditor';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mediaContainerStyle = {
  display: 'flex',
  overflow: 'auto',
  padding: 1,
};

const mediaStyle = {
  borderRadius: '5px',
  height: '120px',
  width: '120px',
  objectFit: 'cover' as const,
};

const boxInfoStyle = {
  background: '#ECECEC',
  display: 'flex',
  padding: '10px 10px',
  justifyContent: 'space-between',
  maxHeight: '34px',
};

const addMediaButtonStyle = {
  minWidth: '120px',
  minHeight: '120px',
  borderRadius: '5px',
  border: '1px dashed #BBBBBB',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  color: 'text.primary',
  '&:hover': {
    backgroundColor: '#e3e1e1',
  },
};

const accordeonSummaryStyle = {
  background: '#30527C33',
  height: '34px',
  minHeight: '34px !important',
  borderRadius: '8px',
};

interface Props {
  post?: Post | null;
  onUploadedMedia?: (media: PostContentMedia[], postId?: string) => void;
  onDeletedMedia?: (mediaIndex: number) => void;
  saveDraft?: ({
    callback,
    lifecycleState,
    schedule,
  }: {
    callback?: (post: Post) => void;
    lifecycleState?: PostLifecycleState;
    schedule?: Date;
  }) => void;
  media?: PostContentMedia[];
  disabled?: boolean;
  setMessage?: (message: Message | null) => void;
  isLinkInbox?: boolean;
  displayMessage?: boolean;
  loadingFromInspiration?: boolean;
}

const PostMediaDisplay = ({
  post,
  media,
  onUploadedMedia = () => {},
  saveDraft = () => {},
  onDeletedMedia = () => {},
  disabled = false,
  setMessage = () => {},
  isLinkInbox = false,
  displayMessage = false,
  loadingFromInspiration = false,
}: Props) => {
  const { t } = useTranslation();
  const maxImages = 20;
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState<number[]>([]);
  const postMedia = media || post?.content?.media;
  const mediaPdf = postMedia?.filter((m) => m.type?.includes('pdf'));
  const hasMedia = postMedia?.length || 0;
  const [expanded, setExpanded] = useState<boolean | null>(displayMessage ? false : true);
  const [selectedMedia, setSelectedMedia] = useState<number[]>([]);
  const [showIconsMedia, setShowIconsMedia] = useState<number[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [dragging, setDragging] = useState(false);
  const [openModalFullScreen, setOpenModalFullScreen] = useState(false);
  const [openModalTitle, setOpenModalTitle] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<PostContentMedia | null>(null);
  const [isAddMediaButtonLoading, setIsAddMediaButtonLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isPdf = postMedia?.some((media) => media.type === 'application/pdf');
  const isVideo = postMedia?.some((media) => media.type?.startsWith('video'));
  const isImage = postMedia?.some((media) => media.type?.startsWith('image'));

  const updateFileProgress = (index: number, progress: number) => {
    setFileProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[index] = progress;
      return newProgress;
    });
  };

  const calculateOverallProgress = () => {
    if (fileProgress.length === 0) return 0;
    const totalProgress = fileProgress.reduce((acc, curr) => acc + curr, 0);
    return totalProgress / fileProgress.length;
  };

  useEffect(() => {
    if (mediaPdf?.length && selectedMedia.length === 0) {
      setSelectedMedia([0]); // Automatically select the first PDF
    }
  }, [mediaPdf, selectedMedia]);

  const progress = calculateOverallProgress();

  const fileChangedHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    // Get an array of all the files that were selected
    const files = Array.from(event.target.files || []);
    setMessage(null);

    if (files.length === 0) return;
    event.target.value = '';

    const pptFiles = files.filter((file) => 
      file.type === 'application/vnd.ms-powerpoint' || // PPT
      file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' // PPTX
  );

  if (pptFiles.length > 0) {
      setMessage({
          text: 'PowerPoint files (PPT/PPTX) are not allowed.',
          type: 'error'
      });
      return;
  }


    const images = files.filter((media) => media.type.startsWith('image'));
    const videos = files.filter((media) => media.type.startsWith('video'));
    const pdfs = files.filter((media) => media.type.startsWith('application/pdf'));

    const postMediaImages =
      postMedia?.filter((m) => m.type?.includes('image')).length || 0;
    const postMediaVideos =
      postMedia?.filter((m) => m.type?.includes('video')).length || 0;

    const isValidImage = async (file: File): Promise<boolean> => {
      const isGifFile = file.type === 'image/gif'; // Detect if the file is a GIF
      const isImageFile =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        isGifFile; // Include GIF in the set of valid image types

      const isValidSize = isGifFile ? file.size <= 16152320 : file.size <= 36152320;
      // If it's a GIF, the size must be <= 15.4 MB. Otherwise, the size must be <= 36.15 MB

      // Check resolution asynchronously
      const isValidResolution = await new Promise<boolean>((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve(img.width <= 6012 && img.height <= 6012);
        };
        img.onerror = () => resolve(false);
        img.src = URL.createObjectURL(file);
      });

      return isImageFile && isValidSize && isValidResolution;
    };

    const isValidLength = (file: File) => {
      return new Promise<boolean>((resolve) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          resolve(duration >= 3 && duration <= 1800); // 3 seconds to 30 minutes
        };

        video.onerror = () => {
          resolve(false);
        };

        video.src = URL.createObjectURL(file);
      });
    };

    const isValidVideo = async (file: File) => {
      // File size limit is 5GB
      const isValidSize = file.size >= 75 * 1e3 && file.size <= 5 * 1e9;

      const validLength = await isValidLength(file);
      return isValidSize && validLength;
    };

    const isValidPdf = async (file: File) => {
      const isValidSize = file.size <= 100 * 1e6;

      if (isValidSize) {
        const fileReader = new FileReader();
        const promise = new Promise<ArrayBuffer>((resolve, reject) => {
          fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);
          fileReader.onerror = reject;
          fileReader.readAsArrayBuffer(file);
        });

        const arrayBuffer = await promise;
        const pdf = await getDocument(arrayBuffer).promise;
        const isValidPages = pdf.numPages <= 300;

        return isValidPages;
      }

      return false;
    };

    const imagesCount = images.length + postMediaImages;
    const videosCount = videos.length + postMediaVideos;

    if (
      imagesCount > maxImages ||
      videosCount > 1 ||
      (imagesCount > 0 && videosCount > 0)
    ) {
      setMessage({
        text: `You can only upload a maximum of ${maxImages} images or 1 video.`,
        type: 'error',
      });
      return;
    }

    const validImages = await Promise.all(images.map(isValidImage));
    if (!validImages.every(Boolean)) {
      setMessage({
        text: 'Invalid file(s) selected. Images or GIF are two large or exceed the resolution limit of 6012x6012 pixels.',
        type: 'error',
      });
      return;
    }

    const validVideos = await Promise.all(videos.map(isValidVideo));
    if (!validVideos.every(Boolean)) {
      setMessage({
        text: 'Invalid file(s) selected. Videos should be between 3 seconds and 30 minutes in length, between 75KB and 5GB in size.',
        type: 'error',
      });
      return;
    }

    if ((imagesCount > 0 || videosCount > 0) && pdfs.length > 0) {
      setMessage({
        text: 'You cannot upload PDFs when images or videos are selected.',
        type: 'error',
      });
      return;
    }

    if (pdfs.length > 1) {
      setMessage({
        text: 'You can only upload one PDF file.',
        type: 'error',
      });
      return;
    }

    if (pdfs.length > 0 && !(await isValidPdf(pdfs[0]))) {
      setMessage({
        text: 'Invalid file(s) selected. PDFs should not exceed 100MB and 300 pages.',
        type: 'error',
      });

      return;
    }

    let postId: string | undefined = undefined;

    setLoading(true);
    setSelectedMedia([]);
    if (
      validVideos.every(Boolean) &&
      !post?._id &&
      !post?.advocacy &&
      post?.type !== PostType.AdvocacyParent
    ) {
      saveDraft({
        callback: (post) => {
          postId = post._id;
        },
      });
    }

    Promise.all(
      files.map((file, index) =>
        uploadMedia(
          file,
          'media',
          user?._id,
          (progress) => updateFileProgress(index, progress),
          isLinkInbox,
        ),
      ),
    ).then((media) => {
      const newMedia = [...(postMedia || []), ...media];
      onUploadedMedia(newMedia, postId);
      setLoading(false);
    });
    if (pdfs.length > 0) {
      setSelectedMedia([0]);
    }
  };

  const handleDelete = () => {
    if (selectedMedia.length === 0) return; // No media selected to delete

    setIsAddMediaButtonLoading(true);

    // Filter out the selected media from the postMedia array
    const newMedia = postMedia!!.filter((_, i) => !selectedMedia.includes(i));

    // Trigger the update with the remaining media
    onUploadedMedia(newMedia);
    setMessage(null);
    // Clear the selected media after deletion
    setSelectedMedia([]);

    // Stop loading animation after 1.5 seconds
    setTimeout(() => {
      setIsAddMediaButtonLoading(false);
    }, 1500);
  };

  const handleSelectMedia = (index: number) => {
    if (selectedMedia.includes(index)) {
      setSelectedMedia(selectedMedia.filter((i) => i !== index));
    } else {
      setSelectedMedia([...selectedMedia, index]);
    }
  };

  function handleDragStart(e: React.DragEvent<HTMLDivElement>, index: number) {
    e.dataTransfer.setData('application/x-postmediadisplay', index.toString());
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>, index: number) {
    e.preventDefault();
    const newIndex = parseInt(e.dataTransfer.getData('application/x-postmediadisplay'));
    const newMedia = [...postMedia!!];

    if (index !== newIndex) {
      [newMedia[index], newMedia[newIndex]] = [newMedia[newIndex], newMedia[index]];
      onUploadedMedia(newMedia);
    }
  }

  const dragOverHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(true);
  };

  const dragLeaveHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(false);
  };

  const dropHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(false);

    if (e.dataTransfer.items) {
      const files = Array.from(e.dataTransfer.items)
        .filter((item) => item.kind === 'file')
        .map((item) => item.getAsFile());

      fileChangedHandler({ target: { files } } as any);
    }
  };

  const editTitleButton = (images: boolean, index?: number) => (
    <Button
      onClick={() => {
        setOpenModalTitle(true);
        if (images && index !== undefined) setSelectedMedia([index]);
      }}
      startIcon={
        <Edit
          sx={{
            width: 12,
          }}
        />
      }
      sx={{
        position: 'absolute',
        bottom: '5%',
        left: '5%',
        color: 'rgba(0, 0, 0, 0.87)',
        zIndex: 500,
        borderRadius: '100px',
        fontSize: '12px',
        height: '23px',
        backgroundColor: '#C2C2C2',
        '&:hover': {
          backgroundColor: '#B0B0B0',
        },
      }}
    >
      Edit title
    </Button>
  );

  const AddMediaButton = () => {
    return (
      <Button
        data-name="AddMediaButton"
        disabled={disabled || isAddMediaButtonLoading || loadingFromInspiration}
        disableRipple
        component="label"
        sx={{ ...addMediaButtonStyle, ...(dragging && { backgroundColor: '#e3e1e1' }) }}
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
        onDragLeave={dragLeaveHandler}
      >
        {isAddMediaButtonLoading || loadingFromInspiration ? (
          <CircularProgress size={24} sx={{ color: '#0057FF' }} />
        ) : dragging && !disabled ? (
          <UploadFile
            data-name="UploadFileIcon"
            sx={{
              width: '100px',
              fontSize: '30px',
              color: '#0057FF',
            }}
          />
        ) : (
          <Add
            data-name="AddIcon"
            sx={{
              width: '100px',
              fontSize: '30px',
              color: disabled ? '#BDBDBD' : '#0057FF',
            }}
            data-testid="add-media-button"
          />
        )}
        <Typography
          data-name="AddMediaText"
          sx={{ color: disabled ? '#BDBDBD' : '#0057FF' }}
        >
          Add or Drop Media
        </Typography>
        <input
          id="file-upload-input"
          hidden
          accept="image/jpeg,image/png,image/gif,image/jpg,video/mp4,video/quicktime,application/pdf"
          type="file"
          onChange={fileChangedHandler}
          multiple
        />
      </Button>
    );
  };

  const handleCloseModalFullScreen = () => {
    setOpenModalFullScreen(false);
  };

  return (
    <>
      <Accordion
        data-name="MediaAccordion"
        expanded={expanded ?? !!hasMedia}
        elevation={0}
        onChange={() => setExpanded(expanded === null ? !!!hasMedia : !expanded)}
        sx={{
          border: '1px solid #BBBBBB',
          borderRadius: '8px !important',
          overflow: 'hidden',
        }}
      >
        <AccordionSummary
          data-name="AccordionSummary"
          expandIcon={<ExpandMore sx={{ fontSize: '40px' }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={accordeonSummaryStyle}
        >
          <Typography data-name="AccordionSummaryText">{t('Attached media')}</Typography>
        </AccordionSummary>
        <AccordionDetails data-name="AccordionDetails" sx={{ p: 0 }}>
          <Stack
            data-name="MediaContainer"
            data-testid="container-img"
            direction={'row'}
            sx={mediaContainerStyle}
            spacing={2}
          >
            {((!!postMedia?.length && !loading && isPdf) || !!mediaPdf?.length) && (
              <Stack data-name="PdfMediaStack" direction={'column'} gap={1}>
                <EditTitleDialog
                  data-name="EditTitleDialog"
                  open={openModalTitle}
                  index={0}
                  media={mediaPdf![0] as PostContentMedia}
                  mediaType="PDF"
                  onClose={() => setOpenModalTitle(false)}
                  onUploadedMedia={onUploadedMedia}
                  postMedia={postMedia as PostContentMedia[]}
                />

                <Document
                  data-name="PdfDocument"
                  file={!!mediaPdf ? mediaPdf[0]?.url : postMedia!![0]?.url}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  loading={<CircularProgress />}
                >
                  <Stack
                    data-name="PdfPageStack"
                    direction={'row'}
                    onClick={() => {
                      if (isMobile) {
                        setSelectedImage(mediaPdf![0]);
                        setOpenModalFullScreen(true);
                      }
                    }}
                    gap={1}
                    sx={{
                      border: '1px solid #8b8789',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      width: '200px !important',

                      position: 'relative',
                      '&:hover': {
                        backgroundColor: '#F9E7F0',
                      },
                    }}
                  >
                    <Page
                      data-name="PdfPage"
                      pageNumber={1}
                      scale={1}
                      width={200}
                      height={200}
                    />
                    {!isMobile && post?.lifecycleState !== PostLifecycleState.Posted && (
                      <>
                        <IconButton
                          data-name="DeleteMediaButton"
                          data-testid="delete-media-button"
                          onClick={handleDelete}
                          sx={{
                            position: 'absolute',
                            top: '5%',
                            right: '5%',
                            color: 'white',
                            zIndex: 999,
                            backgroundColor: '#EF5350',
                            '&:hover': {
                              backgroundColor: '#D32F2F',
                            },
                          }}
                        >
                          <Delete
                            data-name="DeleteIcon"
                            sx={{
                              fontSize: '20px',
                            }}
                          />
                        </IconButton>
                        <Box
                          data-name="PdfPageCountBox"
                          sx={{
                            position: 'absolute',
                            right: '5%',
                            bottom: '5%',
                            width: 25,
                            height: 25,
                            borderRadius: '50%',
                            backgroundColor: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                          }}
                        >
                          <Typography
                            data-name="PdfPageCountText"
                            variant="body1"
                            sx={{
                              color: 'white',
                              fontWeight: 'bold',
                              fontSize: '12px',
                              height: 20,
                            }}
                          >
                            {numPages}
                          </Typography>
                        </Box>

                        {editTitleButton(false)}
                      </>
                    )}

                    <IconButton
                      data-name="ViewMediaButton"
                      onClick={() => {
                        setOpenModalFullScreen(true);
                      }}
                      sx={{
                        position: 'absolute',
                        left: '5%',
                        top: '5%',
                        color: 'white',
                        zIndex: 500,
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#eeeeee',
                        },
                      }}
                    >
                      <RemoveRedEyeRounded
                        data-name="ViewIcon"
                        sx={{
                          fontSize: '20px',
                          color: '#6F6F6F',
                        }}
                      />
                    </IconButton>
                  </Stack>
                </Document>
              </Stack>
            )}

            {loading ? (
              <Stack
                data-name="LoadingStack"
                direction={'row'}
                gap={1}
                alignItems="center"
              >
                <CircularProgressDeterminate
                  data-name="LoadingProgress"
                  progress={progress}
                  withLabel
                  size={60}
                />
                {!post?._id && <Typography>{t('Video post new')}</Typography>}
              </Stack>
            ) : !loading && hasMedia && !isPdf && !mediaPdf?.length ? (
              <>
                {postMedia!!.map((media, index) => (
                  <Stack
                    data-name="MediaStack"
                    gap={1}
                    key={`media-${index}`}
                    style={{ position: 'relative' }}
                  >
                    <EditTitleDialog
                      data-name="EditTitleDialog"
                      open={openModalTitle && selectedMedia.includes(index)}
                      index={index}
                      media={media}
                      mediaType={media.type?.startsWith('image') ? 'Image' : 'Video'}
                      onClose={() => setOpenModalTitle(false)}
                      onUploadedMedia={onUploadedMedia}
                      postMedia={postMedia as PostContentMedia[]}
                    />
                    <Box
                      data-name="MediaBox"
                      onMouseEnter={() => {
                        setShowIconsMedia([...showIconsMedia, index]);
                      }}
                      onMouseLeave={() => {
                        setShowIconsMedia(showIconsMedia.filter((i) => i !== index));
                      }}
                    >
                      <CardMedia
                        data-name="MediaCard"
                        component={isVideo ? 'video' : 'img'}
                        image={media?.url || undefined}
                        controls={isMobile ? true : undefined} // Show controls only on mobile
                        autoPlay
                        playsInline
                        muted
                        loop
                        sx={{
                          ...mediaStyle,
                          cursor: isVideo ? 'default' : 'grab',
                          border: selectedMedia.includes(index)
                            ? '3px solid #FF007A'
                            : 'none',
                        }}
                        data-testid="media"
                        onLoadedData={() => {
                          if (isImage || isVideo) {
                            setSelectedMedia([index]);
                          }
                        }}
                        onClick={() => {
                          if ((isImage || isVideo) && !isMobile) handleSelectMedia(index);
                          if (isMobile) {
                            setSelectedMedia([index]);
                            setSelectedImage(media);
                            setOpenModalFullScreen(true);
                          }
                        }}
                        draggable
                        onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
                          handleDragStart(e, index);
                        }}
                        onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                          e.preventDefault();
                        }}
                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                          handleDrop(e, index)
                        }
                      />
                      {!isMobile &&
                        selectedMedia.includes(index) &&
                        (isImage || isVideo) &&
                        post?.lifecycleState !== PostLifecycleState.Posted && (
                          <>
                            <IconButton
                              data-name="DeleteMediaButton"
                              data-testid="delete-media-button"
                              onClick={handleDelete}
                              sx={{
                                position: 'absolute',
                                top: '5%',
                                right: '5%',
                                color: 'white',
                                backgroundColor: '#EF5350',
                                '&:hover': {
                                  backgroundColor: '#D32F2F',
                                },
                              }}
                            >
                              <Delete
                                data-name="DeleteIcon"
                                sx={{
                                  fontSize: '20px',
                                }}
                              />
                            </IconButton>

                            <IconButton
                              data-name="ViewMediaButton"
                              onClick={() => {
                                setOpenModalFullScreen(true);
                                setSelectedImage(media);
                              }}
                              sx={{
                                position: 'absolute',
                                left: '5%',
                                top: '5%',
                                color: 'white',
                                backgroundColor: 'white',
                                '&:hover': {
                                  backgroundColor: '#eeeeee',
                                },
                              }}
                            >
                              <RemoveRedEyeRounded
                                data-name="ViewIcon"
                                sx={{
                                  fontSize: '20px',
                                  color: '#6F6F6F',
                                }}
                              />
                            </IconButton>
                            {isVideo
                              ? editTitleButton(false)
                              : editTitleButton(true, index)}
                          </>
                        )}
                    </Box>
                    {isMobile && (
                      <>
                        <IconButton
                          data-name="ViewMediaButtonMobile"
                          onClick={() => {
                            setSelectedMedia([index]); // Automatically select the image on mobile
                            setSelectedImage(media); // Set the selected image
                            setOpenModalFullScreen(true);
                          }}
                          sx={{
                            position: 'absolute',
                            left: '5%',
                            top: '5%',
                            color: 'white',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#eeeeee',
                            },
                          }}
                        >
                          <RemoveRedEyeRounded
                            data-name="ViewIconMobile"
                            sx={{
                              fontSize: '20px',
                              color: '#6F6F6F',
                            }}
                          />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                ))}

                {hasMedia &&
                  (postMedia?.length || 0) < maxImages &&
                  !postMedia?.some((media) => media.type?.includes('video')) && (
                    <AddMediaButton />
                  )}
              </>
            ) : !isPdf && !mediaPdf?.length ? (
              <AddMediaButton />
            ) : (
              <></>
            )}
          </Stack>
        </AccordionDetails>

        <Box data-name="BoxInfo" sx={boxInfoStyle}>
          <Box
            data-name="BoxInfoInner"
            sx={{
              display: 'flex',
              gap: '10px',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isPdf && hasMedia !== 0 && (
              <>
                <ErrorOutlineOutlined data-name="ErrorIcon" sx={{ color: '#6F6F6F' }} />
                <Typography
                  data-name="DragInfoText"
                  variant="body2"
                  color="text.secondary"
                >
                  Drag the images to reorder
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Accordion>

      <FullDisplayMediaDialog
        data-name="FullDisplayMediaDialog"
        openModal={openModalFullScreen}
        handleCloseModalFullScreen={handleCloseModalFullScreen}
        postMedia={postMedia}
        isImage={isImage}
        isPdf={isPdf}
        isVideo={isVideo}
        isMobile={isMobile}
        handleDelete={handleDelete}
        selectedImage={selectedImage}
        mediaPdf={mediaPdf}
        numPages={numPages}
        setNumPages={setNumPages}
        setOpenModalTitle={setOpenModalTitle}
      />
    </>
  );
};

export default PostMediaDisplay;
