import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Chip,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  Grid,
} from '@mui/material';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ThumbUpAltOutlined from '@mui/icons-material/ThumbUpAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineOutlined from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { Document, Page } from 'react-pdf';
import { Brand } from '../../__generated__/graphql';
import { PostContext } from '../../context/PostContext';
import {
  PostLifecycleState,
  PostHelperType,
  Language,
} from '../../__generated__/graphql';
import { GET_POST_HELPERS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import ThumbnailCard from '../Cards/ThumbnailCard';
import { parsePostContentForPreview } from '../../utils';
import { Modifier, EditorState, SelectionState } from 'draft-js';
import { DeviceContext } from '../../context/DeviceContext';

interface Props {
  brand?: Brand | null;
  indexTab?: number;
}

interface PostHelper {
  _id: string;
  type: PostHelperType;
  text: string;
  tags: string[];
  language: Language | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

const PAGE_SIZE = 60;

export const PostOptions: React.FC<Props> = ({ brand, indexTab }) => {
  const { t } = useTranslation();
  const { postState, editorState, setEditorState } = useContext(PostContext);
  const { postRef, post, status } = postState ?? {};
  const [selectedTab, setSelectedTab] = useState<number>(indexTab || 0);
  const { isMobile } = useContext(DeviceContext);
  const [page, setPage] = useState(0);
  const [postHelpersData, setPostHelpersData] = useState<PostHelper[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedHelperId, setSelectedHelperId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagsDropdownOpen, setTagsDropdownOpen] = useState(false);
  const [previewMode, setPreviewMode] = useState<'desktop' | 'mobile'>('desktop');
  const [postBody, setPostBody] = useState<string>(
    editorState?.getCurrentContent().getPlainText() || '',
  );
  const [readMore, setReadMore] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pdfTitle, setPdfTitle] = React.useState<string>('Untitled PDF');

  const getHelperType = (tabIndex: number): PostHelperType | null => {
    switch (tabIndex) {
      case 1:
        return PostHelperType.Hooks;
      case 2:
        return PostHelperType.Endings;
      case 3:
        return PostHelperType.Quotes;
      default:
        return null;
    }
  };

  const helperType = getHelperType(selectedTab);

  const { fetchMore, loading: queryLoading } = useQuery(GET_POST_HELPERS, {
    variables: helperType
      ? {
          userId: postState?.post?.brand?.userId || postState?.post?.brand?.user?._id,
          type: helperType,
          page: 0,
          limit: PAGE_SIZE,
        }
      : undefined,
    skip: !helperType,
    notifyOnNetworkStatusChange: true,
    onCompleted: (fetchedData) => {
      const validPostHelpers = (fetchedData?.getPostHelpers?.postHelpers || []).filter(
        Boolean,
      ) as PostHelper[];
      setPostHelpersData(validPostHelpers);
      setTotal(fetchedData?.getPostHelpers?.total || 0);
      if (validPostHelpers.length > 0) {
        setSelectedHelperId(validPostHelpers[0]._id);
      }
    },
  });

  useEffect(() => {
    if (editorState && postRef?.current?.content?.body) {
      const plainText = editorState.getCurrentContent().getPlainText();
      const htmlContent = postRef.current.content.body;
      const formattedText = parsePostContentForPreview(plainText, htmlContent, false);
      setPostBody(formattedText);
    }
  }, [editorState, postRef]);

  // Utility function to truncate text based on charLimit
  const truncateText = (text: string, charLimit: number) => {
    return text.length > charLimit ? `${text.slice(0, charLimit)}...` : text;
  };

  // Determine character limit based on preview mode
  const charLimit = previewMode === 'desktop' ? 200 : 120;

  // Truncated version of postBody
  const truncatedBody = truncateText(postBody, charLimit);

  const loadMore = useCallback(async () => {
    if (loading || postHelpersData.length >= total) return;

    setLoading(true);
    await fetchMore({
      variables: { page: page + 1, limit: PAGE_SIZE },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.getPostHelpers) return prev;

        const newHelpers =
          (fetchMoreResult.getPostHelpers.postHelpers?.filter(Boolean) as PostHelper[]) ||
          [];
        setPage((prevPage) => prevPage + 1);
        setPostHelpersData((prevData) => [...prevData, ...newHelpers]);
        setLoading(false);

        return {
          ...prev,
          getPostHelpers: {
            ...prev.getPostHelpers,
            postHelpers: [...(prev.getPostHelpers?.postHelpers || []), ...newHelpers],
          },
        };
      },
    });
  }, [loading, postHelpersData.length, total, fetchMore, page]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLDivElement;
      if (scrollHeight - scrollTop <= clientHeight + 50) {
        loadMore();
      }
    },
    [loadMore],
  );

  const handlePreviewModeChange = (mode: 'desktop' | 'mobile') => {
    setPreviewMode(mode);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchQuery('');
    setSelectedTags([]);
    setPage(0);
    setPostHelpersData([]); // Clear data when tab changes
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleTagChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedTags(event.target.value as string[]); // Cast value to an array of strings
  };

  const filteredHelpers = postHelpersData.filter(
    (helper) =>
      helper.text.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedTags.length === 0 ||
        selectedTags.some((tag) => helper.tags.includes(tag))),
  );

  const handleSelectHelper = (helperId: string) => {
    setSelectedHelperId((prevId) => (prevId === helperId ? null : helperId)); // Toggle selection
  };

  // Function for adding helpers, now using handleAddHelperToPost
  const handleAddToPost = (helperText: string, type: PostHelperType) => {
    handleAddHelperToPost(helperText, type);
  };

  // Function to handle adding helpers to the post
  const handleAddHelperToPost = (helperText: string, type: PostHelperType) => {
    if (!editorState) return;

    const contentState = editorState.getCurrentContent();
    let newContentState = contentState;

    if (type === PostHelperType.Hooks) {
      // Insert at the **beginning** with a newline after
      const firstBlockKey = contentState.getFirstBlock().getKey();
      const firstBlockSelection = SelectionState.createEmpty(firstBlockKey).merge({
        anchorOffset: 0,
        focusOffset: 0,
      });

      newContentState = Modifier.insertText(
        newContentState,
        firstBlockSelection,
        `${helperText}\n`,
      );
    } else {
      // Insert at the **end** with a newline before
      const lastBlock = contentState.getLastBlock();
      const lastBlockKey = lastBlock.getKey();
      const lastBlockLength = lastBlock.getLength();

      const lastBlockSelection = SelectionState.createEmpty(lastBlockKey).merge({
        anchorOffset: lastBlockLength,
        focusOffset: lastBlockLength,
      });

      newContentState = Modifier.insertText(
        newContentState,
        lastBlockSelection,
        `\n${helperText}`,
      );
    }

    // Create a new EditorState with updated content
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );

    // Set the updated editor state
    if (setEditorState) setEditorState(EditorState.moveFocusToEnd(newEditorState));
  };

  const handleTagDropdownOpen = () => {
    setTagsDropdownOpen(true);
  };

  const handleTagDropdownClose = () => {
    setTagsDropdownOpen(false);
  };

  const renderMediaPreview = () => {
    const media = postState?.post?.content?.media;
    if (!media || media.length === 0) return null;

    const pdfs = media.filter((item) => item.type === 'application/pdf');
    const images = media.filter((item) => item.type?.startsWith('image'));
    const otherMedia = media.filter(
      (item) => !item.type?.startsWith('image') && item.type !== 'application/pdf',
    );

    return (
      <Box
        data-name="MediaPreviewContainer"
        display="flex"
        flexDirection="column"
        gap={2}
        mt={2}
        width="100%"
      >
        {/* Render the first page of PDFs */}
        {pdfs.map((item, index) => (
          <Box
            data-name="PdfPreviewBox"
            key={index}
            sx={{
              position: 'relative',
              width: '100%',
              maxWidth: '750px',
              border: '1px solid #ddd',
              borderRadius: 1,
              backgroundColor: '#fff',
              overflow: 'hidden',
            }}
          >
            <Box
              data-name="PdfPreviewHeader"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.72)', // Black with 72% opacity
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'left',
                height: '37px',
                color: 'white',
                typography: 'body1',
                px: '26px', // Left and right padding
                py: '9px', // Top and bottom padding
              }}
            >
              {/* Header */}
              <Typography
                data-name="PdfPreviewTitle"
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  whiteSpace: 'nowrap', // Prevent wrapping to new lines
                  overflow: 'hidden', // Hide overflow content
                  textOverflow: 'ellipsis', // Add ellipsis if the text is too long
                  width: '100%', // Ensure truncation works properly
                  maxWidth: '100%', // Ensure it respects parent container
                }}
                title={item.alt || pdfTitle || 'Untitled PDF'} // Tooltip with full title
              >
                {item.alt || pdfTitle || 'Untitled PDF'}
              </Typography>
            </Box>

            {/* PDF Preview */}
            <Document
              data-name="PdfDocument"
              file={item.url}
              onLoadSuccess={(pdf) => {
                setNumPages(pdf.numPages);
                pdf.getMetadata().then((metadata) => {
                  setPdfTitle((metadata?.info as any)?.Title || 'Untitled PDF');
                });
              }}
              loading={<CircularProgress size={24} />}
            >
              <Page
                data-name="PdfPage"
                pageNumber={1}
                width={750}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>

            {/* Footer */}
            <Box
              data-name="PdfPreviewFooter"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.72)', // Black with 72% opacity
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                height: '37px',
                color: 'white',
                typography: 'body1',
                px: '26px', // Left and right padding
                py: '9px', // Top and bottom padding
              }}
            >
              <Typography
                data-name="PdfPageCount"
                sx={{
                  fontSize: '16px',
                  fontWeight: 'medium',
                  textAlign: 'center',
                }}
              >
                {numPages || 'N/A'} pages
              </Typography>
            </Box>
          </Box>
        ))}

        {/* Render images */}
        {images.length > 0 && (
          <>
            {images.length === 1 ? (
              <Box
                data-name="SingleImagePreview"
                component="img"
                src={images[0]?.url || ''}
                alt="single-image"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  borderRadius: 1,
                }}
              />
            ) : (
              <>
                <Box
                  data-name="MainImagePreview"
                  component="img"
                  src={images[0]?.url || ''}
                  alt="main-image"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: 1,
                  }}
                />
                <Box
                  data-name="AdditionalImagesGrid"
                  display="grid"
                  gridTemplateColumns="repeat(3, 1fr)"
                  gap={1}
                  mt={1}
                >
                  {images.slice(1, 4).map((item, index) => (
                    <Box
                      data-name={`AdditionalImagePreview-${index}`}
                      key={index}
                      position="relative"
                      component="img"
                      src={item.url || ''}
                      alt={`additional-image-${index}`}
                      sx={{
                        width: '100%',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: 1,
                      }}
                    />
                  ))}
                  {images.length > 4 && (
                    <Box
                      data-name="MoreImagesOverlay"
                      position="relative"
                      sx={{
                        width: '100%',
                        height: '100px',
                      }}
                    >
                      <Box
                        data-name="FourthImagePreview"
                        component="img"
                        src={images[3]?.url || ''}
                        alt="fourth-image"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 1,
                        }}
                      />
                      <Box
                        data-name="MoreImagesCount"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: 24,
                          fontWeight: 'bold',
                          borderRadius: 1,
                        }}
                      >
                        +{images.length - 4}
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </>
        )}

        {/* Render other media */}
        {otherMedia.map((item, index) => {
          if (item.type?.startsWith('video')) {
            return (
              <Box
                data-name="VideoPreviewBox"
                key={index}
                sx={{ width: '100%', height: 'auto', mt: 2 }}
              >
                <video
                  data-name="VideoPreview"
                  src={item.url || ''}
                  controls
                  width="100%"
                  style={{
                    borderRadius: '4px 4px 0 0', // Rounded corners on the top only
                  }}
                />
                <Box
                  data-name="VideoPreviewFooter"
                  sx={{
                    backgroundColor: '#2E3B55', // Adjust this color to match your background
                    borderRadius: '0 0 4px 4px', // Rounded corners on the bottom only
                    px: 2, // Padding for left and right
                    py: 1, // Padding for top and bottom
                    mt: '-6px', // No margin between the video and title
                  }}
                >
                  <Typography
                    data-name="VideoTitle"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#FFFFFF', // White text
                      textAlign: 'left',
                    }}
                  >
                    {item.alt || 'Untitled Video'}
                  </Typography>
                </Box>
              </Box>
            );
          }
          return null;
        })}
      </Box>
    );
  };

  return (
    <Box
      data-name="PostOptionsContainer"
      sx={{
        width: '100%',
        maxHeight: '800px',
        overflowY: 'auto',
        maxWidth: '800px',
        backgroundColor: '#f5f5f5',
        height: '100%',
      }}
    >
      {!isMobile && (
        <Box
          data-name="TabsContainer"
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10, // Ensure it stays above scrolling content
            backgroundColor: 'white', // Prevent content from showing behind
            borderBottom: '1px solid #ddd', // Optional: Add a bottom border for better separation
          }}
        >
          <Tabs
            data-name="PostOptionsTabs"
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="post options tabs"
          >
            <Tab
              data-name="PreviewTab"
              label={
                <Box data-name="PreviewTabLabel" display="flex" alignItems="center">
                  <VisibilityOutlined fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography
                    sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '14px' }}
                  >
                    {t('Preview')}
                  </Typography>{' '}
                  {/* Prevent uppercase */}
                </Box>
              }
              sx={{ textTransform: 'none' }} // Ensure the Tab label itself is not transformed
            />
            {postState?.post.lifecycleState !== PostLifecycleState.Posted && (
              <Tab
                data-name="HooksTab"
                label={
                  <Box data-name="HooksTabLabel" display="flex" alignItems="center">
                    <Box component="img" src="/images/icons/hooks.svg" alt="hooks-icon" />
                    <Typography
                      sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '14px' }}
                    >
                      {t('Hooks')}
                    </Typography>{' '}
                    {/* Prevent uppercase */}
                  </Box>
                }
                sx={{ textTransform: 'none' }} // Prevent Tab uppercase
              />
            )}
            {postState?.post.lifecycleState !== PostLifecycleState.Posted && (
              <Tab
                data-name="EndingsTab"
                label={
                  <Box data-name="EndingsTabLabel" display="flex" alignItems="center">
                    <Box
                      component="img"
                      src="/images/icons/endings.svg"
                      alt="endings-icon"
                      sx={{ width: '18px', height: '18px' }}
                    />
                    <Typography
                      sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '14px' }}
                    >
                      {t('Endings')}
                    </Typography>{' '}
                    {/* Prevent uppercase */}
                  </Box>
                }
                sx={{ textTransform: 'none' }} // Prevent Tab uppercase
              />
            )}
          </Tabs>
        </Box>
      )}

      <TabPanel data-name="PreviewTabPanel" value={selectedTab} index={0}>
        <Box
          data-name="PreviewModeButtons"
          sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}
        >
          <Button
            data-name="DesktopPreviewButton"
            onClick={() => handlePreviewModeChange('desktop')}
            sx={{
              borderRadius: '100px',
              backgroundColor: previewMode === 'desktop' ? '#30527CEB' : 'transparent',
              color: previewMode === 'desktop' ? '#fff' : '#30527CEB',
              border: 'none',
              boxShadow:
                previewMode === 'desktop' ? '0px 2px 5px rgba(0,0,0,0.2)' : 'none',
              '&:hover': {
                backgroundColor:
                  previewMode === 'desktop' ? '#254066EB' : 'rgba(48, 82, 124, 0.08)',
              },
            }}
          >
            <DesktopMacIcon />
          </Button>
          <Button
            data-name="MobilePreviewButton"
            onClick={() => handlePreviewModeChange('mobile')}
            sx={{
              borderRadius: '100px',
              backgroundColor: previewMode === 'mobile' ? '#30527CEB' : 'transparent',
              color: previewMode === 'mobile' ? '#fff' : '#30527CEB',
              border: 'none',
              boxShadow:
                previewMode === 'mobile' ? '0px 2px 5px rgba(0,0,0,0.2)' : 'none',
              '&:hover': {
                backgroundColor:
                  previewMode === 'mobile' ? '#254066EB' : 'rgba(48, 82, 124, 0.08)',
              },
            }}
          >
            <SmartphoneIcon />
          </Button>
        </Box>
        <Box
          data-name="PreviewContainer"
          sx={{
            border: '1px solid #ddd',
            borderRadius: 2,
            p: 2,
            width: previewMode === 'desktop' ? '100%' : '375px', // Adjust width for mobile preview
            margin: '0 auto', // Center the preview for mobile
            backgroundColor: 'white',
          }}
        >
          <Box data-name="PreviewHeader" display="flex" alignItems="center" mb={1}>
            <Avatar
              data-name="PreviewAvatar"
              sx={{ mr: 2 }}
              src={brand?.account?.avatar || '/images/user_default.jpg'}
              alt={brand?.account?.name || 'Brand'}
            />
            <Box data-name="PreviewBrandInfo">
              <Typography
                data-name="PreviewBrandName"
                variant="subtitle1"
                fontWeight="bold"
              >
                {brand?.account?.name || 'Linkedin user'}
              </Typography>
            </Box>
          </Box>
          <Typography
            data-name="PreviewPostBody"
            variant="body2"
            color="textSecondary"
            mb={2}
            sx={{
              wordWrap: 'break-word', // Break long words
              whiteSpace: 'pre-wrap', // Preserve spaces and line breaks
              overflowWrap: 'break-word', // Handle overflows gracefully
              fontSize: previewMode === 'desktop' ? '14px' : '13px', // Set font size
              lineHeight: 1.5, // Approximate LinkedIn line spacing
              fontFamily: 'Arial, sans-serif', // Set font family
              color: '#333333', // Set text color
              padding: '8px', // Optional: Add padding for better readability
              borderRadius: '4px', // Optional: Add some border radius for aesthetics
            }}
          >
            {readMore ? (
              <>
                <span dangerouslySetInnerHTML={{ __html: postBody }} />
                <span
                  data-name="ReadLessButton"
                  onClick={() => setReadMore(false)}
                  style={{
                    color: 'black',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    marginLeft: '4px',
                  }}
                >
                  {t('less')}
                </span>
              </>
            ) : (
              <>
                <span dangerouslySetInnerHTML={{ __html: truncatedBody }} />
                {postBody.length > charLimit && (
                  <span
                    data-name="ReadMoreButton"
                    onClick={() => setReadMore(true)}
                    style={{
                      color: 'black',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      marginLeft: '4px',
                    }}
                  >
                    {t('more')}
                  </span>
                )}
              </>
            )}
          </Typography>
          {renderMediaPreview()}

          {post?.content?.thumbnail?.title &&
            !post?.content?.media?.length &&
            postState?.status.showThumbnail && (
              <Grid item xs={12} data-name="ThumbnailCardGrid">
                <ThumbnailCard
                  data-name="ThumbnailCard"
                  thumbnail={post.content.thumbnail}
                  status={status}
                  preview={true}
                />
              </Grid>
            )}

          <Box
            data-name="PreviewFooter"
            display="flex"
            justifyContent="space-between"
            mt={2}
            pt={1}
            borderTop="1px solid #ddd"
          >
            <Box data-name="LikeButton" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <ThumbUpAltOutlined fontSize="small" />
              </Box>
              <Typography variant="body2" ml={1}>
                Like
              </Typography>
            </Box>
            <Box data-name="CommentButton" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <ChatBubbleOutlineOutlined fontSize="small" />
              </Box>
              <Typography variant="body2" ml={1}>
                Comment
              </Typography>
            </Box>
            <Box data-name="RepostButton" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <ShareOutlined fontSize="small" />
              </Box>
              <Typography variant="body2" ml={1}>
                Reposts
              </Typography>
            </Box>
            <Box data-name="SendButton" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <SendOutlined fontSize="small" />
              </Box>
              <Typography variant="body2" ml={1}>
                Send
              </Typography>
            </Box>
          </Box>
        </Box>
      </TabPanel>

      {[1, 2, 3].map((index) => (
        <TabPanel
          data-name={`TabPanel-${index}`}
          key={index}
          value={selectedTab}
          index={index}
          onScroll={handleScroll}
        >
          <Box data-name="SearchAndTagsContainer" display="flex" gap={2} mb={2}>
            <TextField
              data-name="SearchField"
              label={t('Search by keywords')}
              variant="outlined"
              fullWidth
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* Multi-Tag Filter Dropdown */}
            <FormControl
              data-name="TagsDropdown"
              fullWidth
              size="small"
              sx={{
                width: '300px', // Fixed width for consistency
                '& .MuiOutlinedInput-root': {
                  height: '40px', // Match height with TextField
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <InputLabel data-name="TagsDropdownLabel" id="select-multiple-tags-label">
                {t('Select tags')}
              </InputLabel>
              <Select
                data-name="TagsSelect"
                labelId="select-multiple-tags-label"
                multiple
                value={selectedTags}
                onChange={handleTagChange}
                input={<OutlinedInput label={t('Select tags')} />}
                renderValue={(selected) =>
                  selected.length > 0
                    ? selected.join(', ') // Show selected tags as comma-separated values
                    : t('No tags selected')
                }
                size="small"
                open={tagsDropdownOpen}
                onOpen={handleTagDropdownOpen}
                onClose={handleTagDropdownClose}
              >
                {Array.from(
                  new Set(postHelpersData.flatMap((helper) => helper.tags)),
                ).map((tag, index) => (
                  <MenuItem key={index} value={tag}>
                    {/* Checkbox for multiple selection */}
                    <Checkbox checked={selectedTags.includes(tag)} />
                    <Typography variant="body2">{tag}</Typography>
                  </MenuItem>
                ))}

                {/* Action Buttons at the Bottom */}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gap={2}
                  mb={2}
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white', // Ensure background covers underlying content
                    zIndex: 1,
                    borderTop: '1px solid #ddd',
                    padding: '3px',
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setSelectedTags([])} // Clear selected tags
                  >
                    {t('Clear')}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleTagDropdownClose} // Close dropdown
                  >
                    {t('Done')}
                  </Button>
                </Box>
              </Select>
            </FormControl>
          </Box>

          {filteredHelpers.map((helper) => (
            <Box
              key={helper._id}
              mb={0.5}
              p={2}
              sx={{
                border:
                  helper._id === selectedHelperId
                    ? '1px solid #30527C' // Selected helper border
                    : '1px solid transparent', // Default border
                borderRadius: 2,
                backgroundColor:
                  helper._id === selectedHelperId
                    ? '#f0f0f0' // Selected helper background
                    : 'transparent', // Default background
                position: 'relative',
                cursor: 'pointer', // Change cursor on hover
                transition: 'background-color 0.3s, border 0.3s', // Smooth transitions
                '&:hover': {
                  border:
                    helper._id !== selectedHelperId ? '1px solid #30527C' : undefined,
                  backgroundColor:
                    helper._id !== selectedHelperId ? '#e6e6e6' : undefined,
                },
              }}
              onClick={() => handleSelectHelper(helper._id)}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {helper.text}
              </Typography>

              {/* Conditionally render tags only if the helper is selected */}
              {helper._id === selectedHelperId && (
                <Box display="flex" gap={1} flexWrap="wrap" mt={1}>
                  {helper.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      size="small"
                      sx={{
                        backgroundColor: '#bdbdbd',
                        color: '#00000099',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        padding: '2px 8px',
                      }}
                    />
                  ))}
                </Box>
              )}

              {helper._id === selectedHelperId && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => handleAddToPost(helper.text, helper.type)}
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    width: '112px',
                    height: '30px',
                    padding: '4px 10px',
                    borderRadius: '8px',
                    border: '1px solid #30527C80',
                    opacity: 1,
                    fontWeight: 'bold',
                    color: '#30527C',
                    backgroundColor: 'transparent',
                  }}
                >
                  {t('Add to post')}
                </Button>
              )}
            </Box>
          ))}

          {(loading || queryLoading) && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
      ))}
    </Box>
  );
};

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  onScroll,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`post-options-tabpanel-${index}`}
      aria-labelledby={`post-options-tab-${index}`}
      onScroll={onScroll}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            height: {
              xs: '550px', // Default for small screens
              md: '700px',
              lg: '1000px', // Larger height for large screens
            },
            //height: 'auto',
            width: {
              md: '540px',
              lg: '600px',
            },
            // maxWidth: {
            //   md: '540px',
            //   lg: '600px',
            // },
            overflowY: index > 0 ? 'auto' : 'visible', // Enable scrolling only on those tabs
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
