import { useMutation, useQuery } from '@apollo/client';
import {
  AutoAwesome,
  EditOutlined,
  PlaylistAdd,
  Search,
  ReadMore,
  Translate as TranslateIcon,
  Spellcheck as SpellcheckIcon,
} from '@mui/icons-material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Stack,
} from '@mui/material';
import { EditorState, Modifier, convertToRaw, SelectionState } from 'draft-js';
import { useContext, useRef, useState, useEffect } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import AIAssistantModal from './AIAssistantModal';
import { AuthContext } from '../../context/AuthContext';
import { PostType, Tag, TypeBrand, UserRole } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GENERATE_IDEA_FOR_POST } from '../../graphql/mutations';
import LoaderGif from '../../assets/spinner.gif';
import Avatar from '../Avatar/Avatar';
import { GET_BRANDS } from '../../graphql/queries';
import {
  editorStateToMarkdown,
  insertAIStreamingContent,
  updateEditorStateWithMarkdownFormatting,
} from '../../utils';

export const AIAssistantActions: Tag[] = [
  {
    name: 'GENERATE_IDEA',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'GENERATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'GET_INSPIRATION',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'COMPLETE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'REWRITE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'ASK_MAG',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'TRANSLATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'CORRECT_GRAMMAR_SPELL',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'CREATE_POST_FROM_OFFER',
    color: '#FFFFFF',
    internal: true,
  },
];

interface Props {
  postId?: string;
  onLoading: (loading: boolean) => void;
  onChange?: (content: string, json: any, action?: Tag) => void;
  suggestAI?: boolean;
  inputAI?: boolean;
}

const AIAssistantMenu = ({
  postId,
  onChange,
  onLoading = () => {},
  suggestAI = false,
  inputAI = false,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { postState, editorState, setEditorState, handleChange } =
    useContext(PostContext);
  const { user } = useContext(AuthContext);
  const accumulatedTextRef = useRef('');
  const newEditorStateRef = useRef<EditorState | undefined>();

  const [aiAssistOpen, setAiAssistOpen] = useState<boolean>(false);
  const [offerOption, setOfferOption] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIdeaPost, setLoadingGenerateIdeaPost] = useState<boolean>(false);
  const [anchorElAiMenu, setAnchorElAiMenu] = useState<HTMLButtonElement | null>(null);
  const [translateMenuAnchorEl, setTranslateMenuAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [brandDialogOpen, setBrandDialogOpen] = useState(false);
  const [advocacyAIBrandId, setAdvocacyAIBrandId] = useState<string | undefined>();
  const [generateContentAdvocacyAI, setGenerateContentAdvocacyAI] =
    useState<boolean>(false);

  const menuOpen = Boolean(anchorElAiMenu);
  const translateMenuOpen = Boolean(translateMenuAnchorEl);
  const content = editorState ? editorState.getCurrentContent().getPlainText() : '';

  const [generateIdeaForPost] = useMutation(GENERATE_IDEA_FOR_POST);
  const { data: brandsData } = useQuery(GET_BRANDS);
  const brandsAdvocacyAI =
    brandsData?.getBrands.filter((brand) => brand.typeBrand === TypeBrand.Corporate) ||
    [];
  const [searchTerm, setSearchTerm] = useState('');
  // Filter brands based on search input
  const filteredBrands = brandsAdvocacyAI.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleGenerateIdea = async (brandId?: string) => {
    try {
      setLoadingGenerateIdeaPost(true);
      onLoading(true);

      const brandIdToUse =
        brandId ||
        postState?.post.brandId ||
        postState?.postRef.current.brand?._id ||
        postState?.postRef.current.brandId ||
        '';

      const { data } = await generateIdeaForPost({
        variables: { brandId: brandIdToUse },
      });

      if (
        data?.generateIdeaForPost?.success &&
        editorState &&
        setEditorState &&
        handleChange
      ) {
        const postIdea = data.generateIdeaForPost.postIdea;
        if (postIdea) {
          // ✅ Insert AI-generated idea into the editor
          const insertEditorState = insertAIContent(
            editorState,
            setEditorState,
            postIdea,
            handleChange,
            onChange,
            AIAssistantActions[0],
          );
          if (insertEditorState) {
            const finalEditorState =
              updateEditorStateWithMarkdownFormatting(insertEditorState);
            setEditorState(finalEditorState);
            handleChange(finalEditorState);
          }
        }
      } else {
        setErrorMessage(
          data?.generateIdeaForPost?.message || t('Failed to generate idea'),
        );
      }
    } catch (error) {
      setErrorMessage(t('An error occurred while generating the idea'));
    } finally {
      setLoadingGenerateIdeaPost(false);
      onLoading(false);
    }
  };

  const insertAIContent = (
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
    aiText: string,
    handleChange: (editorState: EditorState) => void,
    onChange?: (html: string, json: any, action?: Tag) => void,
    action?: Tag,
  ) => {
    if (!editorState) return;

    let contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();

    if (action === AIAssistantActions[0]) {
      const firstBlockKey = contentState.getBlockMap().first().getKey();
      selectionState = SelectionState.createEmpty(firstBlockKey).merge({
        anchorOffset: 0,
        focusOffset: 0,
      });
      contentState = Modifier.splitBlock(contentState, selectionState);
    }

    // ✅ Insert AI-generated text at cursor position
    const newContentState = Modifier.insertText(contentState, selectionState, aiText);

    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );

    // ✅ Move cursor to the end
    newEditorState = EditorState.moveSelectionToEnd(newEditorState);

    // ✅ Update editor state
    setEditorState(newEditorState);

    // ✅ Call handleChange to notify content change
    handleChange(newEditorState);

    // ✅ Convert to raw JSON and notify parent
    const rawContent = convertToRaw(newContentState);
    if (onChange) {
      onChange(
        newContentState.getPlainText(),
        rawContent,
        action || { name: 'AI_INSERT', color: '#FFFFFF', internal: true },
      );
    }
    return newEditorState;
  };

  useEffect(() => {
    if (suggestAI) {
      handleGenerateIdea(); // Trigger AI suggestion
    }
    if (inputAI) {
      setAiAssistOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchDataCompleteContent] = useStreamedCompletion(
    (content) => {
      if (typeof content === 'string' && editorState && setEditorState && handleChange) {
        newEditorStateRef.current = insertAIStreamingContent(
          editorState,
          setEditorState,
          content,
          accumulatedTextRef,
          handleChange,
          onChange,
          AIAssistantActions[3],
        );
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      if (newEditorStateRef.current && setEditorState && handleChange && editorState) {
        // Get the current (previous) content state from the existing editorState.
        const previousContent = editorState.getCurrentContent();
        // Move the selection to the end of the previous content.
        const endSelection = EditorState.moveFocusToEnd(editorState).getSelection();
    
        // Insert a newline into the previous content.
        const contentWithBreak = Modifier.insertText(
          previousContent,
          endSelection,
          "\n"
        );
    
        // Now, update only the new part (accumulated content) with markdown formatting.
        const formattedNewState = updateEditorStateWithMarkdownFormatting(
          newEditorStateRef.current
        );
        // Extract the block fragment (formatted new content) from the new state.
        const newFragment = formattedNewState.getCurrentContent().getBlockMap();
    
        // Create a temporary editor state from the content with the break to get an updated selection.
        const tempState = EditorState.createWithContent(contentWithBreak);
        const selectionAfterBreak = EditorState.moveFocusToEnd(tempState).getSelection();
    
        // Insert the formatted new fragment at the end of the contentWithBreak.
        const finalContentState = Modifier.replaceWithFragment(
          contentWithBreak,
          selectionAfterBreak,
          newFragment
        );
    
        // Push the combined content (previous content + newline + formatted new content) into a new editor state.
        let finalEditorState = EditorState.push(
          editorState,
          finalContentState,
          "insert-fragment"
        );
        finalEditorState = EditorState.moveSelectionToEnd(finalEditorState);
    
        setEditorState(finalEditorState);
        handleChange(finalEditorState);
      }
      onLoading(false);
      setLoading(false);
      accumulatedTextRef.current = "";
      newEditorStateRef.current = undefined;
    }
  );

  const [fetchDataRewriteContent] = useStreamedCompletion(
    (content) => {
      if (typeof content === 'string' && editorState && setEditorState && handleChange) {
        newEditorStateRef.current = insertAIStreamingContent(
          editorState,
          setEditorState,
          content,
          accumulatedTextRef,
          handleChange,
          onChange,
          AIAssistantActions[4],
        );
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      if (newEditorStateRef.current && setEditorState && handleChange) {
        const finalEditorState = updateEditorStateWithMarkdownFormatting(
          newEditorStateRef.current,
        );
        setEditorState(finalEditorState);
        handleChange(finalEditorState);
      }
      onLoading(false);
      setLoading(false);
      accumulatedTextRef.current = '';
      newEditorStateRef.current = undefined;
    },
  );

  const [fetchDataTranslateContent] = useStreamedCompletion(
    (content) => {
      if (typeof content === 'string' && editorState && setEditorState && handleChange) {
        newEditorStateRef.current = insertAIStreamingContent(
          editorState,
          setEditorState,
          content,
          accumulatedTextRef,
          handleChange,
          onChange,
          AIAssistantActions[6],
        );
      } else {
        console.error('Error: AI-generated translation is empty or undefined.');
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      if (newEditorStateRef.current && setEditorState && handleChange) {
        const finalEditorState = updateEditorStateWithMarkdownFormatting(
          newEditorStateRef.current,
        );
        setEditorState(finalEditorState);
        handleChange(finalEditorState);
      }
      onLoading(false);
      setLoading(false);
      accumulatedTextRef.current = '';
      newEditorStateRef.current = undefined;
    },
  );

  const [fetchDataCorrectGrammarSpell] = useStreamedCompletion(
    (content) => {
      if (typeof content === 'string' && editorState && setEditorState && handleChange) {
        newEditorStateRef.current = insertAIStreamingContent(
          editorState,
          setEditorState,
          content,
          accumulatedTextRef,
          handleChange,
          onChange,
          AIAssistantActions[7],
        );
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      if (newEditorStateRef.current && setEditorState && handleChange) {
        const finalEditorState = updateEditorStateWithMarkdownFormatting(
          newEditorStateRef.current,
        );
        setEditorState(finalEditorState);
        handleChange(finalEditorState);
      }
      onLoading(false);
      setLoading(false);
      accumulatedTextRef.current = '';
      newEditorStateRef.current = undefined;
    },
  );

  const handleCompleteContent = async () => {
    onLoading(true);
    setLoading(true);

    if (!editorState || !setEditorState) return;

    // ✅ Trigger AI completion (it will call insertAIContent inside the callback)
    fetchDataCompleteContent({
      input: editorStateToMarkdown(postState?.post?.content?.body || ''),
      chainId: '66310b7bf1de1c64651db3a1',
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
    });
  };

  const handleRewriteContent = async () => {
    setLoading(true);
    onLoading(true);

    if (!editorState || !setEditorState) return;

    fetchDataRewriteContent({
      input: editorStateToMarkdown(postState?.post?.content?.body || ''),
      brandId: postState?.post.brandId || undefined,
      chainId: '66310b98f1de1c64651db3a3',
      userId: user?._id || undefined,
    });
  };

  const handleTranslateContent = async (language: string) => {
    setLoading(true);
    onLoading(true);
    if (!editorState || !setEditorState) return;
    let chainId = '';
    switch (language) {
      case 'en':
        chainId = '66fa5e131dc63fe14d6b8e74';
        break;
      case 'es':
        chainId = '66fa872c1dc63fe14d6b8e76';
        break;
      case 'pt-br':
        chainId = '6718cd72089cdf1d432bfc73';
        break;
      case 'pt-pt':
        chainId = '6718ce04089cdf1d432bfc74';
        break;
      case 'fr':
        chainId = '6718ce5c089cdf1d432bfc75';
        break;
      case 'de':
        chainId = '6718ceb0089cdf1d432bfc76';
        break;
      case 'it':
        chainId = '6718cf05089cdf1d432bfc77';
        break;
      case 'es-latam':
        chainId = '6718cf5a089cdf1d432bfc78';
        break;
      case 'pl':
        chainId = '6718e61a089cdf1d432c046c';
        break;
      case 'cat':
        chainId = '67d03c4066661425af3062c3';
        break;
      default:
        chainId = '';
        break;
    }

    fetchDataTranslateContent({
      input: editorStateToMarkdown(postState?.post?.content?.body || ''),
      brandId: postState?.post.brandId || undefined,
      chainId,
      userId: user?._id || undefined,
    });
  };

  const handleCorrectGrammarSpell = async () => {
    setLoading(true);
    onLoading(true);

    if (!editorState || !setEditorState) return;
    fetchDataCorrectGrammarSpell({
      input: editorStateToMarkdown(postState?.post?.content?.body || ''),
      brandId: postState?.post.brandId || undefined,
      chainId: '66fa72dc1dc63fe14d6b8e75',
      userId: user?._id || undefined,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAiMenu(event.currentTarget);

    // ✅ Set editor to read-only mode
    if (setEditorState && editorState) {
      const newEditorState = EditorState.set(editorState, { allowUndo: false });
      setEditorState(newEditorState);
    }
  };

  const handleClose = () => {
    setAnchorElAiMenu(null);

    // ✅ Enable editing again
    if (setEditorState && editorState) {
      const newEditorState = EditorState.set(editorState, { allowUndo: true });
      setEditorState(newEditorState);
    }
  };

  const handleTranslateMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTranslateMenuAnchorEl(event.currentTarget);
  };

  const handleTranslateMenuClose = () => {
    setTranslateMenuAnchorEl(null);
  };

  const text = {
    fontSize: '14px',
  };

  return (
    <>
      <Dialog
        open={brandDialogOpen}
        onClose={() => setBrandDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          {t('Select a Coorporate Brand')}
          <Typography>
            {t('The AI will generate a personalized post based on this brand’s context.')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={t('Search coorporate brands...')}
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: '#BBBBBB' }} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredBrands.length > 0 ? (
              filteredBrands.map((brand) => (
                <ListItem key={brand._id} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setBrandDialogOpen(false);
                      handleClose();
                      if (generateContentAdvocacyAI) {
                        // If coming from "Generate content", only set the brand ID
                        setAdvocacyAIBrandId(brand._id);
                        setAiAssistOpen(true);
                        setGenerateContentAdvocacyAI(false);
                      } else if (offerOption) {
                        setAdvocacyAIBrandId(brand._id);
                        setAiAssistOpen(true);
                      } else {
                        // If coming from "Recieve a post idea", execute idea generation
                        handleGenerateIdea(brand._id);
                      }
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar name={brand.name} avatar={brand.account?.avatar || ''} />
                      <ListItemText primary={brand.name} />
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Typography textAlign="center" color="textSecondary" sx={{ py: 2 }}>
                {t('No brands found')}
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setBrandDialogOpen(false);
              setGenerateContentAdvocacyAI(false);
            }}
          >
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {loadingIdeaPost && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent overlay
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            flexDirection: 'column',
          }}
        >
          <img
            src={LoaderGif}
            alt="Loading"
            style={{
              width: '300px', // Adjust based on your needs
              height: '100px',
              objectFit: 'contain',
            }}
          />
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('Give us a moment to work our magic.')}
          </Typography>
        </Box>
      )}

      <AIAssistantModal
        open={aiAssistOpen}
        onClose={() => {
          setAiAssistOpen(false);
          setGenerateContentAdvocacyAI(false);
          setOfferOption(false);
        }}
        onAddText={(content) => {
          if (editorState && setEditorState && handleChange) {
            const insertEditorState = insertAIContent(
              editorState,
              setEditorState,
              content,
              handleChange,
              onChange,
              AIAssistantActions[1],
            );
            if (insertEditorState) {
              const finalEditorState =
                updateEditorStateWithMarkdownFormatting(insertEditorState);
              setEditorState(finalEditorState);
              handleChange(finalEditorState);
            }
          }
          setOfferOption(false);
        }}
        advocacyAIBrandId={advocacyAIBrandId}
        offerOption={offerOption}
      />

      {isMobile ? (
        <IconButton
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <AutoAwesome />
        </IconButton>
      ) : (
        <LoadingButton
          variant="outlined"
          sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
          startIcon={<AutoAwesome />}
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
          loadingPosition="start"
          loading={loading}
        >
          {t('Use AI')}
        </LoadingButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorElAiMenu}
        open={menuOpen}
        onClose={handleClose}
        elevation={1}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {user?.role?.includes(UserRole.Creator) && (
          <MenuItem
            disabled={
              brandsAdvocacyAI.length === 0 &&
              postState?.post?.type === PostType.AdvocacyParent
            }
            onClick={() => {
              if (postState?.post?.type === PostType.AdvocacyParent) {
                setBrandDialogOpen(true);
              } else {
                handleClose();
                handleGenerateIdea();
              }
            }}
            disableRipple
            sx={{
              position: 'relative', // Required for positioning the link
            }}
          >
            <ListItemIcon>
              <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Recieve a post idea')}
              {brandsAdvocacyAI.length === 0 &&
                postState?.post?.type === PostType.AdvocacyParent && (
                  <Typography
                    variant="caption"
                    fontSize={'13px'}
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering MenuItem's disabled behavior
                      navigate('/brands');
                    }}
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      mt: 1,
                      display: 'block',
                      fontWeight: 'bold',
                      pointerEvents: 'auto', // Override to make clickable
                    }}
                  >
                    {t('Create a brand first')}
                  </Typography>
                )}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          disabled={
            brandsAdvocacyAI.length === 0 &&
            postState?.post?.type === PostType.AdvocacyParent
          }
          onClick={() => {
            if (postState?.post?.type === PostType.AdvocacyParent) {
              setGenerateContentAdvocacyAI(true);
              setBrandDialogOpen(true);
            } else {
              setAiAssistOpen(true);
            }
            handleClose();
          }}
          disableRipple
          sx={{
            position: 'relative', // Required for positioning the link
          }}
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Generate content')}
            {brandsAdvocacyAI.length === 0 &&
              postState?.post?.type === PostType.AdvocacyParent && (
                <Typography
                  variant="caption"
                  fontSize={'13px'}
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering MenuItem's disabled behavior
                    navigate('/brands');
                  }}
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    mt: 1,
                    display: 'block',
                    fontWeight: 'bold',
                    pointerEvents: 'auto', // Override to make clickable
                  }}
                >
                  {t('Create a brand first')}
                </Typography>
              )}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            postState?.handlers?.handleSaveAsDraft({});
            navigate('/insights');
          }}
          disableRipple
          sx={{
            position: 'relative', // Required for positioning the link
          }}
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Get inspiration from viral posts')}
          </ListItemText>
        </MenuItem>

        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCompleteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <ReadMore fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Complete content')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleRewriteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <EditOutlined fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Rewrite content')}
          </ListItemText>
        </MenuItem>

        <MenuItem
          disabled={content.length === 0}
          onClick={handleTranslateMenuOpen} // Open the submenu when "Translate content" is clicked
          disableRipple
        >
          <ListItemIcon>
            <TranslateIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Translate content')}
          </ListItemText>
        </MenuItem>

        <Popover
          id="translate-menu"
          open={translateMenuOpen}
          anchorEl={translateMenuAnchorEl}
          onClose={handleTranslateMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disablePortal
        >
          <MenuItem
            onClick={() => {
              handleTranslateContent('en');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('English')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('es');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Spanish')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('es-latam');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Spanish (Latam)')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pt-br');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Portuguese (Brazil)')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pt-pt');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Portuguese (Portugal)')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('fr');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {' '}
              {t('French')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('de');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {' '}
              {t('German')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('it');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Italian')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pl');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Polish')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('cat');
              handleTranslateMenuClose();
              handleClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Catalan')}
            </ListItemText>
          </MenuItem>
        </Popover>

        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCorrectGrammarSpell();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <SpellcheckIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Correct grammar and spell')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={
            brandsAdvocacyAI.length === 0 &&
            postState?.post?.type === PostType.AdvocacyParent
          }
          onClick={() => {
            if (postState?.post?.type === PostType.AdvocacyParent) {
              setOfferOption(true);
              setBrandDialogOpen(true);
            } else {
              setOfferOption(true);
              setAiAssistOpen(true);
              handleClose();
            }
          }}
          disableRipple
        >
          <ListItemIcon>
            <HomeRepairServiceIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Create a Post from an offer')}
            {brandsAdvocacyAI.length === 0 &&
              postState?.post?.type === PostType.AdvocacyParent && (
                <Typography
                  variant="caption"
                  fontSize={'13px'}
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering MenuItem's disabled behavior
                    navigate('/brands');
                  }}
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    mt: 1,
                    display: 'block',
                    fontWeight: 'bold',
                    pointerEvents: 'auto', // Override to make clickable
                  }}
                >
                  {t('Create a brand first')}
                </Typography>
              )}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AIAssistantMenu;
