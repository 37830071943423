import { Dialog, DialogContent } from '@mui/material';
import { useContext, useRef, useState, useEffect } from 'react';
import {
  ArticleType,
  Insight,
  PostContentMedia,
  Sentiment,
} from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { getMimeType } from '../../utils';
import PostEditorModal from '../PostComponents/PostEditorModal';
import AddPerspectiveInsightModal from './AddPerspectiveInsightModal';
import InsightView from './InsightView';
import { ContentState, EditorState } from 'draft-js';
import { GENERATE_POST_FOR_INSPIRATION } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';

interface Props {
  insight: Insight | null;
  onClose: () => void;
  handleNextInsight: () => void;
  handlePreviousInsight: () => void;
  handleCreateOrUpdateInsight: (
    insightInput: Insight,
    sentiment?: Sentiment,
    favorite?: boolean,
  ) => void;
  disabledButtonBack: boolean;
  disabledButtonNext: boolean;
}

const InsightModal = ({
  insight,
  onClose,
  handleNextInsight,
  handlePreviousInsight,
  handleCreateOrUpdateInsight,
  disabledButtonBack,
  disabledButtonNext,
}: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const [showModalAddPerspective, setShowModalAddPerspective] = useState<Insight | null>(
    null,
  );
  const [openPostEditor, setOpenPostEditor] = useState<boolean>(false);
  const { postState, setEditorState, handleChange } = useContext(PostContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const [loadingPost, setLoadingPost] = useState<boolean>(false);
  const [generatePostForInspiration] = useMutation(GENERATE_POST_FOR_INSPIRATION);

  const cursorPositionRef = useRef<number>();

  let defaultMedia: PostContentMedia[] = insight?.media
    ? insight.media
    : insight?.imgUrl || insight?.videoUrl
    ? [
        {
          url: insight?.imgUrl || insight?.videoUrl,
          type: getMimeType(insight?.imgUrl || insight?.videoUrl || ''),
        },
      ]
    : [];

  useEffect(() => {
    if (insight?.media && insight.media.length > 0) {
      const mediaUrl = insight.media[0].url;
      // Use a HEAD request to check if the media resource is accessible
      if (mediaUrl) {
        fetch(mediaUrl, { method: 'HEAD' })
          .then((response) => {
            if (!response.ok) {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              defaultMedia = [];
            }
          })
          .catch((error) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultMedia = [];
          });
      }
    }
  }, [insight?.media]);

  const insightInput = `Title: "${insight?.title}"\n\nDescription: "${insight?.description}"\n\nContent: "${insight?.content}"\n\nLink: "${insight?.link}"`;

  //THIS FOR NOW WE COMMENT BECAUSE NOW IS GOING TO WORK WITH A MUTATION AND NOT BY STREAMING
  // const [fetchDataCreateDraft] = useStreamedCompletion(
  //   (content) => {
  //     if (
  //       typeof content === 'string' &&
  //       content.trim() !== '' &&
  //       editorState &&
  //       setEditorState &&
  //       handleChange
  //     ) {
  //       insertAIStreamingContent(
  //         editorState,
  //         setEditorState,
  //         content,
  //         accumulatedTextRef,
  //         handleChange,
  //       );
  //     }

  //     postState?.handlers.handleAddTags([
  //       {
  //         name: 'FROM_INSIGHT',
  //         color: '#FFFFFF',
  //         internal: true,
  //       },
  //     ]);
  //   },
  //   (error) => {
  //     setErrorMessage(error);
  //   },
  //   () => {
  //     accumulatedTextRef.current = '';
  //   },
  // );

  const handleCreatePostDraft = async (
    insightType: ArticleType,
    topicContext: string,
    media: PostContentMedia[],
  ) => {
    setOpenPostEditor(true);

    cursorPositionRef.current = 0;
    setLoadingPost(true);
    if (setEditorState && handleChange) {
      await setEditorState(EditorState.createEmpty());
      await handleChange(EditorState.createEmpty());
    }
    await generatePostForInspiration({
      variables: {
        brandId: postState?.post.brandId || '',
        topicContext,
        linkedInSource: insightType === ArticleType.Linkedin ? true : false,
      },
      onCompleted: (data) => {
        if (data.generatePostForInspiration?.success) {
          const postText = data.generatePostForInspiration?.message;
          if (postText && setEditorState && handleChange) {
            const contentState = ContentState.createFromText(postText);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
            postState?.handlers.handleAddTags([
              {
                name: 'FROM_INSIGHT',
                color: '#FFFFFF',
                internal: true,
              },
            ]);
            handleChange(newEditorState);
            postState?.handlers.handleUploadMedia(media);
          }
          setLoadingPost(false);
        } else {
          setErrorMessage(
            data?.generatePostForInspiration?.message ??
              'Error: Failed to create posts for the brand.',
          );
          setLoadingPost(false);
        }
      },
    });
    // await fetchDataCreateDraft({
    //   chainId,
    //   brandId: postState?.post.brandId || undefined,
    //   userId: user?._id || undefined,
    //   input:
    //     'Create a social media post for Linkedin based on this article: ' + topicContext,
    // });
  };

  if (openPostEditor) {
    return (
      <PostEditorModal
        onClose={() => {
          setOpenPostEditor(false);
          if (setEditorState) setEditorState(EditorState.createEmpty());
          onClose();
        }}
        isInsight
        loadingFromInspiration={loadingPost}
      />
    );
  }

  if (showModalAddPerspective) {
    return (
      <AddPerspectiveInsightModal
        insight={showModalAddPerspective}
        onClose={() => setShowModalAddPerspective(null)}
        handleCreatePostDraft={handleCreatePostDraft}
      />
    );
  }

  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      disableEscapeKeyDown
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ margin: 'none' }}
      onClose={onClose}
      data-testid="insight-modal"
      scroll="body"
      maxWidth="md"
      PaperProps={{
        style: {
          maxHeight: isMobile ? '100vh' : 'calc(100vh - 64px)',
        },
      }}
    >
      <DialogContent>
        <InsightView
          insight={insight}
          onClose={onClose}
          handleNextInsight={handleNextInsight}
          handlePreviousInsight={handlePreviousInsight}
          handleCreateOrUpdateInsight={handleCreateOrUpdateInsight}
          handleCreatePostDraft={(insight, addPerspective) => {
            if (addPerspective) {
              setShowModalAddPerspective(insight);
              return;
            }

            handleCreatePostDraft(
              insight.type || ArticleType.Article,
              insightInput,
              defaultMedia,
            );
          }}
          disabledButtonBack={disabledButtonBack}
          disabledButtonNext={disabledButtonNext}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InsightModal;
